import {
  CSSProperties,
  Dispatch,
  FC,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  SetStateAction,
} from "react";
import { LucideProps } from "lucide-react";
import { EnumCSSProp } from "../constants/css_properties";
import { NestedEntries, NestedObjects } from "./common";
import { EnumElement, EnumElementGroup } from "../constants/elements";
import { Text } from "../hooks/use-text";
import { FlexStyle } from "src/pages/constructor/page_constructor/components/tools/flex/flex-ghost";
import { GridStyle } from "src/pages/constructor/page_constructor/components/tools/grid/grid-ghost";

export const new_website_param = "new_website";

export type _Page = {
  page_title: string;
  page_path: string;
  page_enabled: boolean;
}

export type _Element = {
  code: EnumElement;
  group: EnumElementGroup;
  style: Partial<CSSProperties>;
  element: FC<{
    style: Partial<CSSProperties>;
    children?: ReactNode;
  }>;
};

export type _ElementStyle = {
  style: Partial<CSSProperties>;
}

export enum Breakpoints {
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  XXL = "xxl",
}

export const breakpoints_options = {
  sm: {
    width: 436,
    maxWidth: "100%",
    height: 856,
  },
  lg: {
    width: 1024,
    maxWidth: "100%",
    height: 600,
  },
};

export enum CSSTools {
  COPY = "copy",
  EM = "em",
  FOUR_PX = "four_px",
  FOUR_EM = "four_em",
  CURRENT_FOUR_PX = "current_four_px",
  TOGGLER = "toggler",
  COLOR = "color",
  GRADIENT = "gradient",
}

export type _CSSProperty = {
  property: EnumCSSProp;
  type: "number" | "string";
  tools: Array<CSSTools>;
  options?: string[];
};

// page_constructor

export enum E_ElementTool {
  ADD = "add",
  SECTION = "section",
  TEXT = "text",
  DISPLAY = "display",
  STYLE = "style",
  INSERT_STYLE = "insert-style",
  REPLACE = "replace",
  DELETE = "delete",
}

export type _ElementTool = {
  code: E_ElementTool;
  icon: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>>;
  availableElements: EnumElement[];
  content?: ({
    text,
    elementStyle,
    value,
    setValue,
    prop,
    setProp,
    displayStyle,
    setDisplayStyle,
    disabled_nesting,
    onSelectElement,
    onSubmitText,
    onSubmitInsertStyle,
  }: {
    text: Text;
    elementStyle: Partial<CSSProperties>;
    value?: string;
    setValue?: Dispatch<SetStateAction<string>>;
    prop?: NestedEntries<_CSSProperty> | null;
    setProp?: Dispatch<SetStateAction<NestedEntries<_CSSProperty> | null>>;
    displayStyle?: FlexStyle | GridStyle;
    setDisplayStyle?: Dispatch<SetStateAction<FlexStyle | GridStyle>>;
    disabled_nesting?: EnumElement[];
    onSelectElement?: (element: EnumElement) => void;
    onSubmitText?: (value: string) => void;
    onSubmitInsertStyle?: (value: string) => void;
  }) => ReactNode;
};

export enum PageTool {
  LAYOUT = "layout",
}

export enum Display {
  GRID = "grid",
  FLEX = "flex",
}
export type E_ConstructorTool = Display | PageTool;

export enum E_FlexDirection {
  ROW = "row",
  COL = "column",
  ROW_REVERSE = "row-reverse",
  COL_REVERSE = "column-reverse",
}

export enum Alignment {
  NORMAL = "normal",
  CENTER = "center",
  FLEX_START = "flex-start",
  FLEX_END = "flex-end",
  START = "start",
  END = "end",
  BETWEEN = "space-between",
  AROUND = "space-around",
  EVENLY = "space-evenly",
  BASELINE = "baseline",
  STRETCH = "stretch",
}

export type _ConstructorTool<T> = {
  code: T;
  icon: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>> | string;
};

// presets

export enum E_Preset {
  BASE = "base",
  GLASS_MORPHISM = "glass-morphism",
  FUTURISM = "futurism",
}

export type _Preset = {
  code: E_Preset;
  elements: NestedObjects<{ style: CSSProperties }>;
};

// assets

export enum ContentType {
  IMAGE = "image",
  VIDEO = "video",
}

export interface Asset {
  id?: number | string;
  path: string;
  content_type: ContentType;
  name?: string;
  url?: string;
}

export interface AssetClient extends Asset {
  file?: File;
  base64: string;
}