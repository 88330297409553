import { NestedObjects } from "../types/common";
import { CSSTools, _CSSProperty } from "../types/constructor";

export enum EnumCSSProp {
  POSITION = "position",
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
  HEIGHT = "height",
  WIDTH = "width",
  PADDING = "padding",
  FONT_SIZE = "fontSize",
  FONT_WEIGHT = "fontWeight",
  COLOR = "color",
  BACKGROUND = "background",
}

export const position_options = ["static", "relative", "absolute", "fixed", "sticky"];
export const weight_options = ["300", "400", "500", "600", "700", "800", "900"];

export const css_properties: NestedObjects<_CSSProperty> = {
  [EnumCSSProp.POSITION]: {
    property: EnumCSSProp.POSITION,
    type: "string",
    tools: [CSSTools.TOGGLER, CSSTools.CURRENT_FOUR_PX],
    options: position_options,
  },
  [EnumCSSProp.HEIGHT]: {
    property: EnumCSSProp.HEIGHT,
    type: "string",
    tools: [CSSTools.EM],
  },
  [EnumCSSProp.WIDTH]: {
    property: EnumCSSProp.WIDTH,
    type: "string",
    tools: [CSSTools.EM],
  },
  [EnumCSSProp.PADDING]: {
    property: EnumCSSProp.PADDING,
    type: "string",
    tools: [CSSTools.FOUR_PX, CSSTools.FOUR_EM],
  },
  [EnumCSSProp.FONT_SIZE]: {
    property: EnumCSSProp.FONT_SIZE,
    type: "string",
    tools: [CSSTools.EM],
  },
  [EnumCSSProp.FONT_WEIGHT]: {
    property: EnumCSSProp.FONT_WEIGHT,
    type: "string",
    tools: [CSSTools.TOGGLER],
    options: weight_options,
  },
  [EnumCSSProp.COLOR]: {
    property: EnumCSSProp.COLOR,
    type: "string",
    tools: [CSSTools.COPY, CSSTools.COLOR],
  },
  [EnumCSSProp.BACKGROUND]: {
    property: EnumCSSProp.BACKGROUND,
    type: "string",
    tools: [CSSTools.COPY, CSSTools.COLOR, CSSTools.GRADIENT],
  },
};
