import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { EnumCSSProp } from "src/shared/constants/css_properties";

type Props = {
  attr: EnumCSSProp;
  defaultValue: string;
  options: string[];
  updateStyleAttr: (attr: EnumCSSProp, value: string) => void;
};

export const SingleToggleTool = ({ attr, defaultValue, options, updateStyleAttr }: Props) => {
  return (
    <ToggleGroup
      type="single"
      variant="outline"
      defaultValue={defaultValue}
      className="justify-start flex-wrap"
      onValueChange={(e) => {
        updateStyleAttr(attr, e);
      }}>
      {options?.map((el) => {
        return (
          <ToggleGroupItem key={el} value={el}>
            {el}
          </ToggleGroupItem>
        );
      })}
    </ToggleGroup>
  );
};
