import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Alignment, E_FlexDirection, _ConstructorTool } from "src/shared/types/constructor";

export const GhostToggleGroup = ({
  value,
  setValue,
  options,
}: {
  value: E_FlexDirection | Alignment;
  setValue: (val: E_FlexDirection | Alignment) => void;
  options: Array<_ConstructorTool<E_FlexDirection> | _ConstructorTool<Alignment>>;
}) => {
  return (
    <ToggleGroup
      type="single"
      variant="outline"
      className="flex gap-1"
      defaultValue={value}
      onValueChange={(e) => {
        const val =
          options.find((el) => el.code === e) || options[0];
        setValue(val.code);
      }}>
      {options?.map((el) => {
        const Icon = el.icon;
        return (
          <ToggleGroupItem
            key={el.code}
            value={el.code}
            className="text-[10px] p-2 h-fit h-8 w-8 text-muted-foreground">
            {typeof el.icon === "string"
              ? el.icon
              : <Icon />
            }
          </ToggleGroupItem>
        );
      })}
    </ToggleGroup>
  )
}