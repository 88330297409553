import { Dispatch, SetStateAction } from "react";
import { cn } from "@/lib/utils";
import {
  align_content_options,
  align_items_options,
  justify_content_options,
  justify_items_options,
} from "src/shared/constants/page_constructor_tools";
import useText from "src/shared/hooks/use-text";
import { Alignment, Display } from "src/shared/types/constructor";
import { GhostToggleGroup } from "../ghost-toggle-group";
import { FlexStyle } from "./flex-ghost";

export const FlexToolAlignment = ({ style, setStyle, wide = false }: {
  style: FlexStyle,
  setStyle: Dispatch<SetStateAction<FlexStyle>>;
  wide?: boolean;
}) => {
  const text = useText().consturctor.page_tools[Display.FLEX];

  return (
    <div className="flex flex-col gap-1">
      <div>
        <div className="py-1 font-bold">{text.content}</div>
        <div className={wide ? "grid lg:grid-cols-2 gap-3 items-center" : ""}>
          <div className={cn(
            `flex flex-col items-start justify-between gap-1`,
            wide && "sm:flex-row sm:items-center lg:justify-start sm:gap-3"
          )}>
            {text.justify}
            <GhostToggleGroup
              value={style.justifyContent || Alignment.FLEX_START}
              setValue={(val) => setStyle({
                ...style,
                display: Display.FLEX,
                justifyContent: val as Alignment,
              })}
              options={justify_content_options}
            />
          </div>
          <div className={cn(
            `flex flex-col items-start justify-between gap-1`,
            wide && "sm:flex-row sm:items-center lg:justify-start sm:gap-3"
          )}>
            {text.align}
            <GhostToggleGroup
              value={style.alignContent || Alignment.STRETCH}
              setValue={(val) => setStyle({
                ...style,
                display: Display.FLEX,
                alignContent: val as Alignment,
              })}
              options={align_content_options}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="py-1 font-bold">{text.items}</div>
        <div className={wide ? "grid lg:grid-cols-2 gap-3 items-center" : ""}>
          <div className={cn(
            `flex flex-col items-start justify-between gap-1`,
            wide && "sm:flex-row sm:items-center lg:justify-start sm:gap-3"
          )}>
            {text.justify}
            <GhostToggleGroup
              value={style.justifyItems || Alignment.START}
              setValue={(val) => setStyle({
                ...style,
                display: Display.FLEX,
                justifyItems: val as Alignment,
              })}
              options={justify_items_options}
            />
          </div>
          <div className={cn(
            `flex flex-col items-start justify-between gap-1`,
            wide && "sm:flex-row sm:items-center lg:justify-start sm:gap-3"
          )}>
            {text.align}
            <GhostToggleGroup
              value={style.alignItems || Alignment.STRETCH}
              setValue={(val) => setStyle({
                ...style,
                display: Display.FLEX,
                alignItems: val as Alignment,
              })}
              options={align_items_options}
            />
          </div>
        </div>
      </div>
    </div>
  )
}