import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES, navigation } from "./routes";
import { Auth } from "src/pages/auth";
import { Constructor } from "src/pages/constructor";

const Routing = () => {
  return (
    <Routes>
      <Route path={ROUTES.AUTH} element={<Auth />} />
      <Route path={ROUTES.DEFAULT} element={<Constructor />} />
      {navigation?.map((route) => (
        <Fragment key={route.path}>
          <Route path={route.path} element={route.element} />
          {!!route.children &&
            route.children.map((child_route) => (
              <Route key={child_route.path} path={child_route.path} element={child_route.element} />
            ))}
        </Fragment>
      ))}
    </Routes>
  );
};

export default Routing;
