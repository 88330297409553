import {
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignEndHorizontal,
  AlignEndVertical,
  AlignHorizontalDistributeCenter,
  AlignHorizontalSpaceAround,
  AlignHorizontalSpaceBetween,
  AlignStartHorizontal,
  AlignStartVertical,
  AlignVerticalDistributeCenter,
  AlignVerticalSpaceAround,
  AlignVerticalSpaceBetween,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Baseline,
  Dock,
  LayoutGrid,
  StretchHorizontal,
  StretchVertical,
} from "lucide-react";
import { Alignment, Display, E_ConstructorTool, E_FlexDirection, PageTool, _ConstructorTool } from "../types/constructor";

export const page_constuctor_tools: _ConstructorTool<E_ConstructorTool>[] = [
  {
    code: PageTool.LAYOUT,
    icon: Dock,
  },
  {
    code: Display.GRID,
    icon: LayoutGrid,
  },
  {
    code: Display.FLEX,
    icon: "FLEX",
  },
];

export const flex_directions_options: _ConstructorTool<E_FlexDirection>[] = [
  {
    code: E_FlexDirection.ROW,
    icon: ArrowRight,
  },
  {
    code: E_FlexDirection.COL,
    icon: ArrowDown,
  },
  {
    code: E_FlexDirection.ROW_REVERSE,
    icon: ArrowLeft,
  },
  {
    code: E_FlexDirection.COL_REVERSE,
    icon: ArrowUp,
  },
];

export const justify_content_options: _ConstructorTool<Alignment>[] = [
  {
    code: Alignment.FLEX_START,
    icon: AlignStartVertical,
  },
  {
    code: Alignment.FLEX_END,
    icon: AlignEndVertical,
  },
  {
    code: Alignment.CENTER,
    icon: AlignCenterVertical,
  },
  {
    code: Alignment.BETWEEN,
    icon: AlignHorizontalSpaceBetween,
  },
  {
    code: Alignment.AROUND,
    icon: AlignHorizontalSpaceAround,
  },
  {
    code: Alignment.EVENLY,
    icon: AlignHorizontalDistributeCenter,
  },
  {
    code: Alignment.STRETCH,
    icon: StretchHorizontal,
  },
];

export const justify_items_options: _ConstructorTool<Alignment>[] = [
  {
    code: Alignment.START,
    icon: AlignStartVertical,
  },
  {
    code: Alignment.END,
    icon: AlignEndVertical,
  },
  {
    code: Alignment.CENTER,
    icon: AlignCenterVertical,
  },
  {
    code: Alignment.STRETCH,
    icon: StretchHorizontal,
  },
];

export const align_content_options: _ConstructorTool<Alignment>[] = [
  {
    code: Alignment.FLEX_START,
    icon: AlignStartHorizontal,
  },
  {
    code: Alignment.FLEX_END,
    icon: AlignEndHorizontal,
  },
  {
    code: Alignment.CENTER,
    icon: AlignCenterHorizontal,
  },
  {
    code: Alignment.BETWEEN,
    icon: AlignVerticalSpaceBetween,
  },
  {
    code: Alignment.AROUND,
    icon: AlignVerticalSpaceAround,
  },
  {
    code: Alignment.EVENLY,
    icon: AlignVerticalDistributeCenter,
  },
  {
    code: Alignment.BASELINE,
    icon: Baseline,
  },
  {
    code: Alignment.STRETCH,
    icon: StretchVertical,
  },
];

export const align_items_options: _ConstructorTool<Alignment>[] = [
  {
    code: Alignment.FLEX_START,
    icon: AlignStartHorizontal,
  },
  {
    code: Alignment.FLEX_END,
    icon: AlignEndHorizontal,
  },
  {
    code: Alignment.CENTER,
    icon: AlignCenterHorizontal,
  },
  {
    code: Alignment.BASELINE,
    icon: Baseline,
  },
  {
    code: Alignment.STRETCH,
    icon: StretchVertical,
  },
];

