import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MousePointer2, Trash2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import useText from "src/shared/hooks/use-text";
import { NestedEntries, NestedObjects } from "src/shared/types/common";
import { _CSSProperty, _Element } from "src/shared/types/constructor";
import { EnumCSSProp, css_properties } from "src/shared/constants/css_properties";
import { CssTools } from "src/widgets/css_tools/css_tools";
import { EnumElement, default_elements } from "src/shared/constants/elements";
import { presets } from "src/shared/constants/presets";
import { LS, SP } from "src/shared/constants/constants";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

export const BasicStyles = () => {
  const text = useText().consturctor;
  const [searchParams] = useSearchParams();
  const [elementKey, setElementKey] = useState<EnumElement | null>(null);
  const [elements, setElements] = useState<NestedObjects<_Element>>(default_elements);
  const [prop, setProp] = useState<NestedEntries<_CSSProperty> | null>(null);
  const element = !!elementKey ? elements[elementKey] : null;

  const selectedPresetCode = searchParams?.get(SP.PRESET) || localStorage.getItem(LS.SELECTED_PRESET);

  const ls_custom_styles = localStorage.getItem(LS.CUSTOM_STYLES);
  const { customStyles, setCustomStyles, selectedPreset, setSelectedPreset } = useConstructorStore();

  const css_options = Object.entries(css_properties).map((property) => ({
    key: property[0],
    value: property[1],
  }));

  const updateStyleAttr = (attr: EnumCSSProp, value: string) => {
    if (!elementKey || !element) {
      return;
    }
    const elementStyleCopy = structuredClone(element.style);
    if (
      attr !== EnumCSSProp.POSITION &&
      attr !== EnumCSSProp.TOP &&
      attr !== EnumCSSProp.RIGHT &&
      attr !== EnumCSSProp.BOTTOM &&
      attr !== EnumCSSProp.LEFT
    ) {
      elementStyleCopy[attr as string] = value;
    }

    setCustomStyles({
      ...customStyles,
      [elementKey]: {
        style: elementStyleCopy,
      }
    })
  };

  useEffect(() => {
    if (!!selectedPresetCode) {
      const preset = presets.find((el) => el.code === selectedPresetCode) || presets[0];
      setSelectedPreset(preset);
      setElements(Object.keys(default_elements).reduce((acc, key) => {
        return {
          ...acc,
          [key]: {
            ...default_elements[key],
            style: preset.elements[key].style || default_elements[key].style,
          }
        }
      }, {}));
    }
    if (!!ls_custom_styles) {
      setCustomStyles(JSON.parse(ls_custom_styles));
    }
  }, [])

  useEffect(() => {
    if (!!Object.keys(customStyles)?.length) {
      localStorage.setItem(LS.CUSTOM_STYLES, JSON.stringify(customStyles));
      setElements(Object.keys(default_elements).reduce((acc, key) => {
        return {
          ...acc,
          [key]: {
            ...elements[key],
            style: {
              ...elements[key]?.style,
              ...customStyles[key]?.style,
            }
          }
        }
      }, {}));
    }
  }, [customStyles])

  return (
    <div className={"h-full grow grid grid-cols-[1fr_310px]"}>
      <div className="relative p-4" style={elements[EnumElement.BODY].style}>
        <Popover>
          <PopoverTrigger asChild className="absolute top-4 right-4">
            <Button
              type="button"
              size="icon"
              variant="destructive"
              className={cn("min-w-10 p-2.5")}>
              <Trash2 />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            align="start"
            className="p-4 w-fit relative -left-1 top-1 cursor-pointer"
            onClick={() => {
              localStorage.removeItem(LS.CUSTOM_STYLES);
              setCustomStyles({});
              setElements(Object.keys(default_elements).reduce((acc, key) => {
                return {
                  ...acc,
                  [key]: {
                    ...default_elements[key],
                    style: selectedPreset.elements[key].style || default_elements[key].style,
                  }
                }
              }, {}));
            }}
          >
            {text.page_tools.common.erase_all_custom_styles}
          </PopoverContent>
        </Popover>
        {Object.entries(elements).map((el) => {
          const Element = el[1].element;
          return (
            <div
              key={el[0]}
              className={cn(
                "flex gap-2 w-fit border-2 rounded-md p-1",
                elementKey === el[0] ? "border-input" : "border-transparent"
              )}>
              <Element
                style={
                  el[0] !== EnumElement.BODY ? el[1].style : elements[EnumElement.BUTTON].style
                }>
                {text.elements[el[1].code]}
              </Element>
              <Button
                type="button"
                variant={elementKey === el[0] ? "default" : "ghost"}
                size="icon"
                className={`p-1 min-w-10 ${elementKey === el[0] ? "" : "text-foreground"}`}
                onClick={() => setElementKey(el[0] as EnumElement)}>
                <MousePointer2 />
              </Button>
            </div>
          );
        })}
      </div>
      <div className="h-full bg-secondary flex flex-col gap-4 py-4 px-2">
        <div className="flex flex-col">
          {css_options
            .filter((property) => property.key !== EnumCSSProp.POSITION)
            .map((property) => (
              <Button
                key={property.key}
                className={cn(
                  "w-fit p-2 h-7 text-xs font-extralight",
                  property.key === prop?.key ? "bg-foreground text-background" : ""
                )}
                variant="link"
                onClick={() => setProp(property)}>
                {text.css_properties[property.value.property]}
              </Button>
            ))}
        </div>
        <p>{!!prop ? text.css_properties[prop.value.property] : ""}</p>
        <CssTools
          elementKey={elementKey}
          elementStyle={element?.style || null}
          prop={prop}
          updateStyleAttr={updateStyleAttr}
        />
      </div>
    </div>
  );
};
