import { ReactNode } from "react";
import { Dialog, DialogContent, DialogTrigger } from "./dialog";

export function ModalButton({
  triggerButton,
  disabled,
  children,
}: {
  triggerButton: ReactNode;
  disabled?: boolean;
  children: ReactNode;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild disabled={disabled}>
        {triggerButton}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">{children}</DialogContent>
    </Dialog>
  );
}
