import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { EnumElement, default_elements } from "src/shared/constants/elements";
import { LS, SP } from "src/shared/constants/constants";
import { presets } from "src/shared/constants/presets";
import PresetCard from "../ui/card";
import PresetButton from "../ui/button";

export const Presets = () => {
  const text = useText().consturctor.presets;
  const H1 = default_elements[EnumElement.H1].element;
  const H2 = default_elements[EnumElement.H2].element;
  const P = default_elements[EnumElement.P].element;
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPresetCode = searchParams?.get(SP.PRESET) || localStorage.getItem(LS.SELECTED_PRESET);
  const { selectedPreset, setSelectedPreset } = useConstructorStore();

  useEffect(() => {
    if (!!selectedPresetCode) {
      setSelectedPreset(presets.find((el) => el.code === selectedPresetCode) || presets[0]);
    }
  }, [])

  return (
    <div className="bg-secondary" style={selectedPreset.elements[EnumElement.BODY].style}>
      <div className="p-4">
        <H2
          style={{
            ...selectedPreset.elements[EnumElement.H1].style,
            fontSize: "2em",
          }}>
          {text.presets}: {text.preset_title[selectedPreset.code]}
        </H2>
      </div>
      <div className="flex flex-col md:grid grid-cols-2 xl:grid-cols-3">
        {presets?.map((el) => {
          return (
            <div
              key={el.code}
              className="cursor-pointer duration-300 hover:scale-[102%]"
              style={{
                ...el.elements.body?.style,
                minHeight: "fit-content",
                padding: "20px",
              }}
              onClick={() => {
                if (selectedPresetCode !== el.code) {
                  searchParams.set(SP.PRESET, el.code);
                  localStorage.setItem(LS.SELECTED_PRESET, el.code);
                  setSearchParams(searchParams);
                  setSelectedPreset(el);
                }
              }}>
              <PresetCard style={el.elements.card?.style || {}}>
                <H1 style={el.elements.h1?.style || {}}>{text.preset_title[el.code]}</H1>
                <P style={el.elements.p?.style || {}}>{text.example_p}</P>
                <PresetButton style={el.elements.button?.style || {}}>
                  {text.example_button}
                </PresetButton>
              </PresetCard>
            </div>
          );
        })}
      </div>
    </div>
  );
};
