import ru from "src/shared/locales/ru";
// import { useCommonStore } from "../store/common-store";
// import { Language } from "../types/common";

function useText() {
  //const { language } = useCommonStore();
  const text = ru; //language === Language.RU ? ru : en;
  return text;
}

export default useText;

export type Text = typeof ru;
