import { AxiosResponse } from "axios";
import { Commit, InitGitModule, PushCommit } from "src/shared/types/gitlab";
import api from "../api-instance";

const BASE_GITLAB_API = "https://git.bwg-io.site/api/v4";
const PRIVATE_ACCESS_TOKEN = import.meta.env.VITE_PRIVATE_ACCESS_TOKEN;


export default class gitlabService {
  static async getCommitsGitlab(project_id: number): Promise<AxiosResponse<Commit[]>> {
    return api.get(`${BASE_GITLAB_API}/projects/${project_id}/repository/commits`, 
    {
      headers: {
        "PRIVATE-TOKEN": PRIVATE_ACCESS_TOKEN,
      }
    }
    );
  }
  static async pushGitlab(project_id: number, payload: PushCommit): Promise<AxiosResponse<Commit>> {
    return api.post(`${BASE_GITLAB_API}/projects/${project_id}/repository/commits`, payload, 
    {
      headers: {
        "PRIVATE-TOKEN": PRIVATE_ACCESS_TOKEN,
      }
    }
    );
  }
  static async initCalculatorSubmodule(project_id: number, payload: InitGitModule): Promise<AxiosResponse<Commit>> {
    return api.put(`${BASE_GITLAB_API}/projects/${project_id}/repository/submodules/${encodeURI(`calculator-module`)}`, payload, 
    {
      headers: {
        "PRIVATE-TOKEN": PRIVATE_ACCESS_TOKEN,
      }
    }
    );
  }
}
