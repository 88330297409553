import App from "@/../calculator-module/src/App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { EnumElement } from "src/shared/constants/elements";
import { CALCULATOR_ID, SubmoduleId } from "src/shared/constants/submodules";
import { _Preset } from "src/shared/types/constructor";
import { styleReplacer } from "src/shared/utils/constructor/style-utils";

export const initCalculatorSubmodule = async (
  selectedElementId: string,
  selectedPreset: _Preset,
) => {
  const container = document.getElementById(selectedElementId);

  if (!container) {
    return;
  }

  container.setAttribute("id", SubmoduleId.CALCULATOR);

  const root = createRoot(container, { identifierPrefix: SubmoduleId.CALCULATOR });

  await root.render(<BrowserRouter><App /></BrowserRouter>);

  const calculator_card = document.getElementById(CALCULATOR_ID.CARD);
  const calculator_title = document.getElementById(CALCULATOR_ID.TITLE);
  const calculator_labels = document.getElementsByClassName(CALCULATOR_ID.LABEL);
  const calculator_inputs = document.getElementsByClassName(CALCULATOR_ID.INPUT);
  // const calculator_selects = document.getElementsByClassName(CALCULATOR_ID.SELECT);
  // const calculator_errors = document.getElementsByClassName(CALCULATOR_ID.ERROR);
  const calculator_submit_button = document.getElementById(CALCULATOR_ID.SUBMIT_BUTTON);
  const calculator_swop_button = document.getElementById(CALCULATOR_ID.SWOP_BUTTON);

  if (!!calculator_card) {
    calculator_card.setAttribute(
      "style",
      styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.CARD]?.style))
    );
  }

  if (!!calculator_title) {
    calculator_title.setAttribute(
      "style",
      styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.H2]?.style))
    );
  }

  if (!!calculator_labels) {
    Array.from(calculator_labels)?.forEach((el) => {
      el.setAttribute(
        "style",
        styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.LABEL]?.style))
      )
    })
  }

  if (!!calculator_inputs) {
    Array.from(calculator_inputs)?.forEach((el) => {
      el.setAttribute(
        "style",
        styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.INPUT]?.style))
      )
    })
  }

  if (!!calculator_submit_button) {
    calculator_submit_button.setAttribute(
      "style",
      styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.BUTTON]?.style))
    );
  }

  if (!!calculator_swop_button) {
    calculator_swop_button.setAttribute(
      "style",
      styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.BUTTON]?.style))
    );
  }
}