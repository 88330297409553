import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { InputWithLabel } from "@/components/ui/input-with-label";
import useText from "src/shared/hooks/use-text";
import { useLogin, useLoginWithOtp } from "src/shared/api/query/use-auth/use-auth";
import { useAuthStore } from "src/shared/store/auth-store";
import {
  user_password_schema,
  user_email_schema,
  verify_code_schema,
} from "src/shared/validation/field-schemas";
import { ROUTES } from "src/app/routing/routes";
import { UserRole } from "src/shared/types/common";

export const Auth = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const text = useText();
  const textAuth = text.auth;
  const [isOtp, setIsOtp] = useState(false);

  const FormSchema = z.object({
    user_email: user_email_schema(text.validation),
    user_password: user_password_schema(text.validation),
  });

  const FormSchemaWithOtp = z.object({
    user_email: user_email_schema(text.validation),
    user_password: user_password_schema(text.validation),
    verify_code: verify_code_schema(text.validation),
  });

  const form = useForm<z.infer<typeof FormSchema | typeof FormSchemaWithOtp>>({
    resolver: isOtp ? zodResolver(FormSchemaWithOtp) : zodResolver(FormSchema),
  });

  const { me, setMe } = useAuthStore();
  const { mutateAsync: login, isPending } = useLogin();
  const { mutateAsync: loginWithOtp } = useLoginWithOtp();

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const data = isOtp
      ? await loginWithOtp(e)
      : await login({
        user_email: e.user_email,
        user_password: e.user_password,
      });
    if (!data) {
      return;
    }  
    if (isOtp) {
       // Удалить когда будет USER_SERVESE
       setMe({
        user_email: UserRole.ADMIN,
        user_role_name: UserRole.ADMIN,
      });
    } else if ("is_2fa_enabled" in data && data.is_2fa_enabled) {
      setIsOtp(true);
    } else {
    // Удалить когда будет USER_SERVESE
      setMe({
        user_email: UserRole.ADMIN,
        user_role_name: UserRole.ADMIN,
      });
    }
  };

  useEffect(() => {
    if (!!me && pathname === ROUTES.AUTH) {
      navigate(ROUTES.DEFAULT);
    }
  }, [me]);

  return (
    <div className="flex w-full h-full min-h-[80vh] items-center justify-center">
      <Card className="p-4 min-w-60">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
            <div className="w-full space-y-2">
              {!isOtp ? (
                <>
                  <InputWithLabel label={textAuth.email_field} name="user_email" />
                  <InputWithLabel label={textAuth.password_field} name="user_password" />
                </>
              ) : (
                <FormField
                  control={form.control}
                  name="verify_code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>OTP</FormLabel>
                      <FormControl>
                        <InputOTP maxLength={6} {...field}>
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
            <Button type="submit" disabled={isPending}>
              {textAuth.sign_in}
            </Button>
          </form>
        </Form>
      </Card>
    </div>
  );
};
