import { useEffect } from "react";
import { DoorOpen, Moon, Sun } from "lucide-react";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { ModalButton } from "@/components/ui/modal-button";
import { ROUTES } from "src/app/routing/routes";
import { useAuth, useLogout } from "src/shared/api/query/use-auth/use-auth";
import { useAuthStore } from "src/shared/store/auth-store";
import { Box } from "src/shared/image/box";
import { Logo } from "src/shared/image/logo";
import { Menu } from "./components/menu";
import { useLocation, useNavigate } from "react-router-dom";

export const Header = ({ theme, switchTheme }: { theme: "dark" | ""; switchTheme: () => void }) => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const { data } = useAuth();
  const { mutateAsync: logout, isPending: isLogoutPending } = useLogout();
  const { me, setMe } = useAuthStore();

  useEffect(() => {
    if (!!data) {
      data && setMe(data);
    }
  }, [data]);

  const onLogout = async () => {
    const response = await logout();
    if (response) {
      setMe(null);
      navigate(ROUTES.AUTH);
    }
  };
  // вернуть когда будет USER_SERVICE
  // useEffect(() => { 
  //   if (!!error && pathname !== ROUTES.AUTH) {
  //     setMe(null);
  //     navigate(ROUTES.AUTH);
  //   }
  // }, [error]);

  return (
    <header className="flex justify-between items-center bg-background px-4 border-b">
      <button type="button" className="shrink-0 flex items-center w-[110px] p-2.5">
        <Logo className="h-9 w-9 min-w-9" />
        <Box className="relative -left-1.5 h-9 w-9 min-w-9" />
        <Box className="relative -left-3 h-9 w-9 min-w-9 origin-center rotate-[120deg]" />
      </button>
      <div className="flex flex-nowrap items-center gap-4 w-full">
        <div className="hidden md:flex w-full justify-center">
          <Menu />
        </div>
      </div>
      <div className="flex gap-2">
        {!!me ? (
          <>
            <div className="rounded-md ring-1 ring-neutral-600 py-1 px-2 text-xs">
              <p>{me?.user_email}</p>
              <p className="text-neutral-500">{me?.user_role_name}</p>
            </div>
            <ModalButton triggerButton={
              <Button
                size="icon"
                variant="ghost"
                className="min-w-10">
                <DoorOpen />
              </Button>
            }>
              <p>
                Вы уверены, что хотите выйти?
              </p>
              <DialogClose asChild>
                <Button
                  type="button"
                  className="justify-self-end"
                  disabled={isLogoutPending}
                  onClick={onLogout}>
                  Выйти
                </Button>
              </DialogClose>
            </ModalButton>
          </>
        ) : pathname !== ROUTES.AUTH && (
          <Button
            variant="secondary"
            className="min-w-10"
            onClick={() => {
              navigate(ROUTES.AUTH);
            }}>
            Войти
          </Button>
        )}
        <Button
          size="icon"
          variant="ghost"
          className="min-w-10"
          onClick={switchTheme}>
          {theme === "dark" ? <Moon /> : <Sun />}
        </Button>
      </div>
    </header>
  );
};
