import { ReactNode } from "react";
import { CircleHelp, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { ModalButton } from "@/components/ui/modal-button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import useText from "src/shared/hooks/use-text";

export const BaseGhost = ({
  triggerBlock,
  toolsBlock,
  infoBlock,
  submitBlock,
  resetTool,
}: {
  triggerBlock: ReactNode;
  toolsBlock: ReactNode;
  infoBlock: ReactNode;
  submitBlock: ReactNode;
  resetTool: () => void;
}) => {
  const text = useText().consturctor.page_tools.common;

  return (
    <Popover open>
      <PopoverTrigger asChild>{triggerBlock}</PopoverTrigger>
      <PopoverContent className="max-w-[90vw] flex flex-col gap-2 text-xs p-2 w-fit">
        <div className="grid grid-cols-[1fr_64px] gap-2">
          {toolsBlock}
          <div className="flex gap-2">
            <ModalButton
              triggerButton={
                <Button
                  size="icon"
                  variant="outline"
                  className="p-1 w-7 h-7 min-w-7 text-muted-foreground">
                  <CircleHelp />
                </Button>
              }>
              <div className="flex flex-col gap-2 text-muted-foreground text-sm pt-4 whitespace-pre-line max-h-[60vh] overflow-y-auto">
                {infoBlock}
              </div>
              <DialogClose asChild>
                <Button type="button" variant="outline" className="justify-self-center">
                  {text.close_info}
                </Button>
              </DialogClose>
            </ModalButton>
            <Button
              size="icon"
              variant="outline"
              className="p-1 w-7 h-7 min-w-7 text-muted-foreground"
              onClick={resetTool}>
              <X />
            </Button>
          </div>
        </div>
        <div className="flex gap-2 justify-center items-center">{submitBlock}</div>
      </PopoverContent>
    </Popover>
  );
};
