import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { useLocalizationsStore } from "src/shared/store/localizations-store";
import { LangEnum } from "src/shared/store/common-store";
import { defineTag } from "src/shared/utils/constructor/style-utils";
import { _ElementTool } from "src/shared/types/constructor";
import { EnumElement, default_elements } from "src/shared/constants/elements";
import { LS } from "src/shared/constants/constants";
import { initLocalizationSubmodule } from "../../submodules/localization/localization-submodule";
import { DocumentNavigation } from "./document-navigation";
import { ElementTools } from "./element-tools";

export const ElementPanel = () => {
  const text = useText().consturctor;
  const { selectedPreset, selectedElementId } = useConstructorStore();
  const {
    websiteLanguages,
    setWebsiteLanguages,
    localizations,
  } = useLocalizationsStore();
  const tag_entities = selectedElementId?.split("~") || null;

  const element_code = defineTag(selectedElementId);
  const [currentTool, setCurrentTool] = useState<_ElementTool | null>(null);

  const ls_website_languages = localStorage.getItem(LS.WEBSITE_LANGUAGES);

  useEffect(() => {
    if (!!ls_website_languages && typeof ls_website_languages === "string") {
      setWebsiteLanguages(ls_website_languages.split(",") as LangEnum[]);
    }
  }, [])

  useEffect(() => {
    if (!!selectedElementId) {
      const selected_element = document.getElementById(selectedElementId);
      const container = document.getElementById("element_container");
      if (!!container) {
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
        if (!selected_element) {
          return;
        }
        selected_element.className += " selected-highlight ";
        const element = selected_element.cloneNode(true);

        if (!!element) {
          container.append(element);
        }
      }
      return () => {
        if (!!selected_element) {
          selected_element.className = selected_element.className?.replace(" selected-highlight ", "");
        }
      }
    }
  }, [selectedElementId]);

  return (
    <>
      <ResizablePanelGroup
        direction="vertical"
        className="h-[calc(100vh-154px)] bg-secondary flex flex-col gap-2 py-4 px-2"
      >
        <ResizablePanel defaultSize={10}>
          <div className="text-muted-foreground text-xs">
            <p>{text.page_tools.common.default_settings}</p>
            <ul className="list-disc list-inside">
              <li>{text.page_tools.common.preset}: {text.presets.preset_title[selectedPreset.code]}</li>
              {!!localStorage.getItem(LS.CUSTOM_STYLES) && (
                <li>{text.page_tools.common.custom_styles}</li>
              )}
              {!!localizations && (
                <li>{text.page_tools.common.localization_submodule}</li>
              )}
            </ul>
          </div>
          {(!!websiteLanguages && !!localizations) && (
            <Button
              type="button"
              onClick={() => initLocalizationSubmodule(websiteLanguages, localizations, {})} // selectedPreset.elements[EnumElement.].style
            >
              🐝Подключить сабмодуль языка
            </Button>
          )}
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={20} className="!overflow-y-auto">
          <DocumentNavigation />
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={70}>
          {!!selectedElementId && (
            <>
              <div>
                <h4 style={default_elements[EnumElement.H4].style}>
                  {text.elements[element_code]}
                </h4>
                {!!tag_entities && tag_entities?.length > 1 && (
                  <small>{tag_entities[1]}</small>
                )}
              </div>
              <ElementTools
                currentTool={currentTool}
                setCurrentTool={setCurrentTool}
              />
              {element_code !== EnumElement.BODY && (
                <div
                  id="element_container"
                  className={!!selectedElementId ? "p-2 rounded-md w-fit" : ""}
                />
              )}
            </>
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </>
  );
};
