export const tg_script = `
<script src="https://telegram.org/js/telegram-web-app.js" onload={function(){
const tg = window?.Telegram?.WebApp;
    if (!tg) return;
function isTelegramApp(){
  if (!window) return false;
  const tg = window?.Telegram?.WebApp;
  if (!tg) return false;
  return tg.platform != "unknown"
};

    if (!isTelegramApp()) return;

    tg.ready();
    tg.isClosingConfirmationEnabled = true;
    tg.expand();
    document.body.style.overflowY = "hidden"  
}()}></script>
`