import { Dispatch, SetStateAction } from "react";
import { Input } from "@/components/ui/input";
import useText from "src/shared/hooks/use-text";
import { Display } from "src/shared/types/constructor";
import { GridStyle } from "./grid-ghost";

export const GridToolValues = ({
  style,
  setStyle,
}: {
  style: GridStyle;
  setStyle: Dispatch<SetStateAction<GridStyle>>;
}) => {
  const text = useText().consturctor.page_tools[Display.GRID];

  return (
    <>
      {text.cols}
      <Input
        className="h-7"
        value={style.gridTemplateColumns}
        onChange={(e) => setStyle({
          ...style,
          display: Display.GRID,
          gridTemplateColumns: e.target.value,
        })}
      />
      {text.rows}
      <Input
        className="h-7"
        value={style.gridTemplateRows}
        onChange={(e) => setStyle({
          ...style,
          display: Display.GRID,
          gridTemplateRows: e.target.value,
        })}
      />
      {text.gap}
      <Input
        className="h-7 w-16"
        value={style.gap}
        onChange={(e) => setStyle({
          ...style,
          display: Display.GRID,
          gap: e.target.value,
        })}
      />
    </>
  )
}
