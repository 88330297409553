import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { Form } from "@/components/ui/form";
import useText from "src/shared/hooks/use-text";
import { useLocalizationsStore } from "src/shared/store/localizations-store";
import { ChooseWebsite } from "src/entities/choose_website";
import { Breakpoints, new_website_param } from "src/shared/types/constructor";
import { presets } from "src/shared/constants/presets";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { LS, SP } from "src/shared/constants/constants";
import { Mockup } from "../constructor/page_constructor/components/mockup";
import { LocalizationRow } from "./components/localization-row";
import { LanguageAdder } from "./components/language-adder";
import { WebsiteLanguages } from "./components/website-languages";
import { InitLocalization } from "./components/init-localization";
import { Hint } from "@/components/ui/hint";
import Button from "../constructor/ui/button";
import { LangEnum } from "src/shared/store/common-store";
import { cn } from "@/lib/utils";
import { NODE_ID } from "src/shared/constants/elements";

export const Localization = () => {
  const form = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedWebsite = searchParams?.get(SP.WEBSITE);
  const selectedPresetCode = searchParams?.get(SP.PRESET) || localStorage.getItem(LS.SELECTED_PRESET);
  const text = useText().localizations;

  const { customStyles, setCustomStyles, selectedPreset, setSelectedPreset } = useConstructorStore();
  const {
    selectedLanguage,
    websiteLanguages,
    localizations,
    setLocalizations,
  } = useLocalizationsStore();

  const [breakpoints] = useState(Breakpoints.SM);
  const [zoom] = useState(1);
  const [isSaveButtonAnimation, setIsSaveButtonAnimation] = useState(false);

  const new_document = (
    <section style={selectedPreset.elements.body.style}>
      <div id={NODE_ID.DOC} className="container grow h-full mx-auto p-0" />
    </section>
  );

  const submitImport = (copy_lang: LangEnum) => {
    if (!localizations || !selectedLanguage) {
      return;
    }
    const updatedLocalizations = {
      ...localizations,
      [selectedLanguage]: localizations[copy_lang],
    }
    setLocalizations(updatedLocalizations);
    localStorage.setItem(LS.LOCALIZATIONS, JSON.stringify(updatedLocalizations));
  }

  const onSubmit = async (e: FieldValues) => {
    if (!selectedLanguage) {
      return;
    }
    setIsSaveButtonAnimation(true);
    const text_entiites = Object.entries(e)?.map((el) => ({
      id: el[0],
      textContent: el[1],
    }));
    const updatedLocalizations = {
      ...localizations,
      [selectedLanguage]: text_entiites,
    }
    setLocalizations(updatedLocalizations);
    localStorage.setItem(LS.LOCALIZATIONS, JSON.stringify(updatedLocalizations));
  };

  useLayoutEffect(() => {
    const ls_custom_styles = localStorage.getItem(LS.CUSTOM_STYLES);
    if (!!ls_custom_styles) {
      setCustomStyles(JSON.parse(ls_custom_styles));
    }
    const ls_localizations = localStorage.getItem(LS.LOCALIZATIONS);
    if (!!ls_localizations) {
      setLocalizations(JSON.parse(ls_localizations));
    }
    const doc = document.getElementById(NODE_ID.DOC);
    if (!doc) {
      return;
    }
    if (!!localStorage.getItem(LS.PROGRESS_HTML) && !doc?.childElementCount) {
      doc.innerHTML += localStorage.getItem(LS.PROGRESS_HTML);
    }
  }, [])

  useEffect(() => {
    if (!selectedLanguage || !localizations && !localizations?.[selectedLanguage]) {
      return;
    }
    const doc = document.getElementById(NODE_ID.DOC);
    if (!doc) {
      return;
    }
    localizations[selectedLanguage].forEach((el) => {
      const element = document.getElementById(el.id);

      if (!!element && element.textContent !== el.textContent) {
        element.textContent = el.textContent;
      }
    })
  }, [selectedLanguage])

  useEffect(() => {
    if (isSaveButtonAnimation) {
      setTimeout(() => setIsSaveButtonAnimation(false), 1000);
    }
  }, [isSaveButtonAnimation]);

  useEffect(() => {
    if (!!Object.keys(customStyles)?.length) {
      const preset = presets.find((el) => el.code === selectedPresetCode) || presets[0];
      localStorage.setItem(LS.CUSTOM_STYLES, JSON.stringify(customStyles));
      setSelectedPreset({
        ...preset,
        elements: Object.keys(preset.elements).reduce((acc, key) => {
          return {
            ...acc,
            [key]: {
              ...preset.elements[key],
              style: {
                ...preset.elements[key]?.style,
                ...customStyles[key]?.style,
              }
            }
          }
        }, {}),
      });
    }
  }, [selectedPresetCode, customStyles])

  useEffect(() => {
    document.querySelectorAll('textarea').forEach(el => {
      el.style.height = el.scrollHeight + 'px';
      el.classList.add('auto');
      el.addEventListener('input', () => {
        el.style.height = 'auto';
        el.style.height = (el.scrollHeight) + 'px';
      });
    });
  }, [localizations])

  return (
    <div className="flex flex-col gap-8 pb-20 h-full w-full grow justify-center">
      {!selectedWebsite ? (
        <ChooseWebsite onChoose={() => {
          searchParams.set(SP.WEBSITE, new_website_param);
          setSearchParams(searchParams);
        }} />
      ) : (
        <div className="flex gap-5">
          <div className="w-fit">
            <Mockup
              breakpoints={breakpoints}
              zoom={zoom}
              bodyColor={selectedPreset.elements.body.style.backgroundColor || ""}>
              {new_document}
            </Mockup>
          </div>
          <div className="flex flex-col gap-2 p-4 w-full">
            <LanguageAdder />
            <WebsiteLanguages />
            {(!!new_document && !!websiteLanguages?.length && !localizations) && (
              <InitLocalization />
            )}
            {(!!selectedLanguage && !!localizations) && (
              !!localizations[selectedLanguage]?.length ? (
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
                    <div className="bg-muted rounded-md flex flex-col divide-y px-4 py-2">
                      {localizations[selectedLanguage]?.map((el) => {
                        return (
                          <LocalizationRow key={`text~${selectedLanguage}~${el.id}`} item={el} language={selectedLanguage} />
                        )
                      })}
                      <Button
                        type="submit"
                        className="w-fit"
                      >
                        {text.save}
                      </Button>
                    </div>
                  </form>
                </Form>
              ) : (
                <div className="flex flex-col items-center gap-4 justify-center text-muted-foreground w-full p-4 min-h-20 whitespace-pre-line text-center">
                  {text.choose_copy_language}
                  <div className="flex flex-wrap justyfy-center gap-1">
                    {Object.keys(localizations)?.map((lang) => (
                      <Button
                        key={lang}
                        type="button"
                        variant="outline"
                        className={cn("px-3", isSaveButtonAnimation ? "submit-button-animation" : "")}
                        onClick={() => submitImport(lang as LangEnum)}
                      >
                        {lang}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
            <Hint>{text.disclaimer}</Hint>
          </div>
        </div>
      )}
    </div>
  );
};
