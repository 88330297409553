import { AssetClient, ContentType } from "src/shared/types/constructor";
import { v4 } from "uuid";

export const downloadFile = (asset: { path: string; name: string }) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = asset.path;
  downloadLink.download = asset.name;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};

const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export const convertBase64ToBlob = (base64Data: string, contentType: ContentType) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const getAssetFromFile = async (file: File): Promise<AssetClient> => {
  let content_type: ContentType = ContentType.IMAGE;
  let path: string | undefined;

  const blob = new Blob([file]);
  const base64 = await convertBlobToBase64(blob);

  if (file.type.includes("image")) {
    content_type = ContentType.IMAGE;
    if (file.type.includes("svg")) {
      path = URL.createObjectURL(file);
    } else {
      path = URL.createObjectURL(blob);
    }
  } else if (file.type.includes("video")) {
    path = URL.createObjectURL(blob);
    content_type = ContentType.VIDEO;
  } else {
    path = URL.createObjectURL(blob);
  }

  return {
    id: v4(),
    content_type,
    name: file.name?.replaceAll(" ", ""),
    file,
    path,
    base64,
  };
};
