import { Dispatch, SetStateAction } from "react";
import { Card } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { E_ConstructorTool, PageTool, _ConstructorTool } from "src/shared/types/constructor";
import { page_constuctor_tools } from "src/shared/constants/page_constructor_tools";
import { LayoutTool } from "./tools/layout-tool";
import { PagesNavigation } from "./pages-navigation";
import { BreakpointsToggle } from "./breakpoints-toggle";

export const PageConstructorTools = ({
  currentTool,
  setCurrentTool,
}: {
  currentTool: _ConstructorTool<E_ConstructorTool> | null;
  setCurrentTool: Dispatch<SetStateAction<_ConstructorTool<E_ConstructorTool> | null>>;
}) => {
  return (
    <Card className="relative flex items-center w-full px-1 h-10 text-border">
      <BreakpointsToggle />
      <div className="px-3">|</div>
      <ToggleGroup
        type="single"
        variant="outline"
        className="flex gap-1"
        onValueChange={(e) => {
          if (currentTool?.code === e) {
            setCurrentTool(null);
          } else {
            const tool =
              page_constuctor_tools.find((el) => el.code === e) || page_constuctor_tools[0];
            setCurrentTool(tool);
          }
        }}>
        {page_constuctor_tools?.map((el) => {
          const Icon = el.icon;
          return (
            <ToggleGroupItem
              key={el.code}
              value={el.code}
              className="data-[state=on]:bg-background text-[10px] p-1 h-fit h-8 w-8 text-muted-foreground">
              {typeof el.icon === "string"
                ? el.icon
                : <Icon />
              }
            </ToggleGroupItem>
          );
        })}
      </ToggleGroup>
      {currentTool?.code === PageTool.LAYOUT && (
        <LayoutTool resetTool={() => setCurrentTool(null)} />
      )}
      <div className="px-3">|</div>
      <PagesNavigation />
    </Card>
  );
};
