import { useState } from "react";
import { Plus, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { PageCard } from "./components/page-card";
import { PageForm } from "./components/page-form";

export const Pages = () => {
  const text = useText().consturctor.pages;
  const { pages } = useConstructorStore();
  const [isCreatePage, setIsCreatePage] = useState(false);

  return (
    <div className="w-full h-[calc(100vh-120px)] flex flex-col items-center justify-center gap-3 p-4">
      <Card
        className={cn(
          "relative flex flex-col items-center justify-center gap-3 px-4 py-8 w-full min-h-[200px] text-muted-foreground duration-300",
          isCreatePage ? "" : "cursor-poiner hover:bg-secondary/30 hover:scale-[1.01]",
        )}
        onClick={() => !isCreatePage && setIsCreatePage(true)}
      >
        {isCreatePage ? (
          <>
            <Button
              type="button"
              size="icon"

              variant="ghost"
              className="absolute right-1 top-1"
              onClick={() => setIsCreatePage(false)}
            >
              <X />
            </Button>
            <PageForm />
          </>
        ) : (
          <>
            <Plus className="h-10 w-10" strokeWidth={1} />
            {text.create_page}
          </>
        )}
      </Card>
      <div className="w-full flex justify-center flex-wrap gap-3">
        {pages?.map((page, index) => {
          return (
            <PageCard key={index} item={page} />
          )
        })}
      </div>
    </div>
  )
}