export type _CeoMeta = {
  name: string;
  content: string;
}

export enum ECeoLinkRel {
  PRELOAD = "preload",
  ICON = "icon",
}

export type _CeoLink = {
  name: string;
  rel: ECeoLinkRel;
  href: string;
  type?: "font/ttf";
}

export type _CeoScript = { 
  name: string;
  script_tag: string;
}

export type _CeoNoScript = {
  name: string;
  src: string;
}

export type _CEO = {
  head?: {
    favicon?: string;
    title?: string;
    description?: string;
    links?: _CeoLink[];
    meta?: _CeoMeta[];
  };
  body?: {
    scripts?: _CeoScript[];
    noscripts?: _CeoNoScript[];
  };
}