import { useSearchParams } from "react-router-dom";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cn } from "@/lib/utils";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { SP } from "src/shared/constants/constants";

export const PagesNavigation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    pages,
    currentPage,
    setCurrentPage,
  } = useConstructorStore();

  return (
    <ToggleGroup
      type="single"
      variant="outline"
      className="flex gap-1"
      value={currentPage?.page_path}
      onValueChange={(e) => {
        const page = pages?.find((el) => el.page_path === e) || pages?.[0];
        if (!page) {
          return;
        }
        searchParams.set(SP.PAGE, page.page_path.replaceAll("/", "_"));
        setSearchParams(searchParams);
        setCurrentPage(page);
      }}>
      {pages?.map((page) => {
        const isSelected = currentPage?.page_path === page.page_path;
        return (
          <ToggleGroupItem
            key={page.page_path}
            value={page.page_path}
            className={cn(
              "data-[state=on]:bg-green-400/50 text-sm py-1 px-3 h-fit h-8 text-muted-foreground",
              isSelected ? "" : ""
            )}>
            {page.page_title}
          </ToggleGroupItem>
        );
      })}
    </ToggleGroup>
  )
}