import { ChangeEvent, ForwardRefExoticComponent, RefAttributes, useEffect, useState } from "react";
import { Axis3D, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, ChevronsDownUp, ChevronsRightLeft, Frame, LucideProps } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cn } from "@/lib/utils";
import { EnumCSSProp } from "src/shared/constants/css_properties";

type Props = {
  attr: EnumCSSProp;
  defaultValue: string;
  measure: string;
  updateStyleAttr: (attr: EnumCSSProp, value: string) => void;
};

export const FourValueTool = ({ attr, defaultValue, measure, updateStyleAttr }: Props) => {
  const [mode, setMode] = useState<"2" | "4">("2");

  const isActive = defaultValue?.includes(measure);

  const defaultValues = defaultValue?.replaceAll(measure, "")?.split(" ")?.filter((el) => !!el?.length);
  const four_values_names = ["top", "right", "bottom", "left"];
  const four_values_icons = [
    ChevronUp,
    ChevronRight,
    ChevronDown,
    ChevronLeft,
  ];

  const parsedValues = !!defaultValues?.length && isActive
    ? defaultValues
    : ["", "", "", ""];

  const [values, setValues] = useState(
    parsedValues?.length === 1
      ? [parsedValues[0], parsedValues[0], parsedValues[0], parsedValues[0]]
      : parsedValues?.length === 2
        ? [parsedValues[0], parsedValues[1], parsedValues[0], parsedValues[1]]
        : parsedValues
  );

  useEffect(() => {
    if (values?.filter((el) => !!el.length)?.length === 4) {
      updateStyleAttr(attr, values.map((el) => `${el}${measure}`).join(" "));
    }
  }, [values])

  return (
    <div className={cn(
      "flex gap-2 items-start rounded-md py-1",
      !isActive && "bg-secondary"
    )}>
      <ToggleGroup
        type="single"
        variant="outline"
        className="flex items-center justify-start gap-1"
        onValueChange={(e: "2" | "4") => {
          setMode(e);
        }}>
        <ToggleGroupItem
          value={"2"}
          className={cn(
            "p-1.5 h-fit h-7 w-7",
            mode === "2"
              ? "bg-foreground text-background data-[state=on]:bg-foreground data-[state=on]:text-background"
              : "bg-background text-muted-foreground data-[state=on]:bg-background data-[state=on]:text-muted-foreground"
          )}>
          <Axis3D />
        </ToggleGroupItem>
        <ToggleGroupItem
          value={"4"}
          className={cn(
            "p-1.5 h-fit h-7 w-7",
            mode === "4"
              ? "bg-foreground text-background data-[state=on]:bg-foreground data-[state=on]:text-background"
              : "bg-background text-muted-foreground data-[state=on]:bg-background data-[state=on]:text-muted-foreground"
          )}>
          <Frame />
        </ToggleGroupItem>
      </ToggleGroup>
      <div className="flex items-baseline gap-2">
        <div className="flex flex-col gap-1">
          {mode === "2" ? (
            <>
              <FourValueInput
                name="Y"
                icon={ChevronsDownUp}
                defaultValue={values[0] || ""}
                onChange={(e) => {
                  setValues(values.map((el, index) => index % 2 === 0 ? e.target.value : el));
                }}
              />
              <FourValueInput
                name="X"
                icon={ChevronsRightLeft}
                defaultValue={values[1] || ""}
                onChange={(e) => {
                  setValues(values.map((el, index) => index % 2 === 1 ? e.target.value : el));
                }}
              />
            </>
          ) : (
            <>
              {values?.map((el, el_index) => (
                <FourValueInput
                  key={`${measure}_${el_index}`}
                  icon={four_values_icons[el_index]}
                  name={four_values_names[el_index]}
                  defaultValue={el}
                  onChange={(e) => {
                    setValues(values.map((el, index) => index === el_index ? e.target.value : el));
                  }}
                />
              ))}
            </>
          )}
        </div>
        <span className="text-neutral-500 text-xs relative bottom-1">{measure}</span>
      </div>
    </div>
  );
};

export const FourValueInput = ({ name, icon, defaultValue, onChange }: {
  name: string;
  icon: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>>;
  defaultValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const Icon = icon;
  return (
    <div className="flex gap-1 items-center">
      <Label htmlFor={name}>
        <Icon className="h-5 w-5 text-muted-foreground" />
      </Label>
      <Input
        className="h-7 w-20 text-center"
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  )
}