import { E_Preset, _Preset } from "../types/constructor";
import { EnumElement, default_elements } from "./elements";

export const presets: _Preset[] = [
  {
    code: E_Preset.BASE,
    elements: {
      ...default_elements,
    },
  },
  {
    code: E_Preset.GLASS_MORPHISM,
    elements: {
      ...default_elements,
      [EnumElement.BODY]: {
        style: {
          ...default_elements[EnumElement.BODY].style,
          color: "#000000b3",
          backgroundColor: "#bbc7dc55",
          background: "linear-gradient(90deg, #6d90b933 0%, #bbc7dc55 100%)",
        },
      },
      [EnumElement.CARD]: {
        style: {
          ...default_elements[EnumElement.CARD].style,
          color: "#000000b3",
          background: "#ffffff40",
          boxShadow: "0 8px 32px 0 #1f26875e",
          backdropFilter: "blur( 4px )",
          WebkitBackdropFilter: "blur( 4px )",
          borderRadius: "10px",
          border: "1px solid #ffffff2e",
        },
      },
      [EnumElement.BUTTON]: {
        style: {
          ...default_elements[EnumElement.BUTTON].style,
          color: "#000000b3",
          background: "#ffffff1a",
          boxShadow: "0 8px 32px 0 #1f26875e",
          backdropFilter: "blur( 4px )",
          WebkitBackdropFilter: "blur( 4px )",
          borderRadius: "10px",
          border: "1px solid #ffffff2e",
        },
      },
    },
  },
  {
    code: E_Preset.FUTURISM,
    elements: {
      ...default_elements,
      [EnumElement.BODY]: {
        style: {
          ...default_elements[EnumElement.BODY].style,
          color: "#fff",
          backgroundColor: "#000",
          background: "linear-gradient(180deg, #000 0%, #3b474c 100%)",
        },
      },
      [EnumElement.CARD]: {
        style: {
          ...default_elements[EnumElement.CARD].style,
          color: "#fff",
          background:
            "linear-gradient(-30deg, #aefb2a 0%, #aefb2a77 3%, #aefb2a11 7%,  transparent 10%, transparent 90%, #aefb2a11 93%, #aefb2a77 97%, #aefb2a 100%)",
          boxShadow: "0 8px 32px 0 #1f26875e",
          backdropFilter: "blur( 4px )",
          WebkitBackdropFilter: "blur( 4px )",
          borderRadius: "10px",
          border: "none",
          outline: "1px solid #ffffff1a",
        },
      },
      [EnumElement.H1]: {
        style: {
          ...default_elements[EnumElement.H1].style,
          color: "#aefb2a",
        },
      },
      [EnumElement.BUTTON]: {
        style: {
          ...default_elements[EnumElement.BUTTON].style,
          color: "#fff",
          background: "#ffffff1a",
          boxShadow: "0 8px 32px 0 #1f26875e",
          backdropFilter: "blur( 4px )",
          WebkitBackdropFilter: "blur( 4px )",
          borderRadius: "10px",
          border: "1px solid #ffffff2e",
        },
      },
    },
  },
];
