import { Ceo } from "src/pages/ceo";
import { Constructor } from "src/pages/constructor";
import { BasicStyles } from "src/pages/constructor/basic_styles/basic_styles";
import { PageConstructor } from "src/pages/constructor/page_constructor/page_constructor";
import { Pages } from "src/pages/constructor/pages/pages";
import { Presets } from "src/pages/constructor/presets/presets";
import { Guide } from "src/pages/guide";
import { Localization } from "src/pages/localization";
import { _NavItem } from "src/shared/types/common";

export enum ROUTES {
  DEFAULT = "/",
  AUTH = "/auth",
  CONSTRUCTOR = "/constructor",
  CONSTRUCTOR_NAVIGATUION = "/constructor/navigation",
  CONSTRUCTOR_PAGES = "/constructor/pages",
  CONSTRUCTOR_BASIC_STYLES = "/constructor/basic-styles",
  CONSTRUCTOR_PRESETS = "/constructor/presets",
  CEO = "/ceo",
  LOCALIZATION = "/localization",
  GUIDE = "/guide",
  ADMIN = "/admin",
}

export const navigation: _NavItem[] = [
  {
    path: ROUTES.CONSTRUCTOR,
    title: "constructor",
    element: <Constructor />,
    children: [
      {
        path: ROUTES.CONSTRUCTOR_NAVIGATUION,
        title: "constructor_navigation",
        element: <Pages />,
      },
      {
        path: ROUTES.CONSTRUCTOR_PAGES,
        title: "constructor_pages",
        element: <PageConstructor />,
      },
      {
        path: ROUTES.CONSTRUCTOR_BASIC_STYLES,
        title: "constructor_basic_styles",
        element: <BasicStyles />,
      },
      {
        path: ROUTES.CONSTRUCTOR_PRESETS,
        title: "constructor_presets",
        element: <Presets />,
      },
    ],
  },
  {
    path: ROUTES.CEO,
    title: "ceo",
    element: <Ceo />,
  },
  {
    path: ROUTES.LOCALIZATION,
    title: "localization",
    element: <Localization />,
  },
  {
    path: ROUTES.GUIDE,
    title: "guide",
    element: <Guide />,
  },
];
