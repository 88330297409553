import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import "./index.css";
import { Header } from "./widgets/header";
import Routing from "./app/routing/routing";
import { LS } from "./shared/constants/constants";
import { useConstructorStore } from "./shared/store/constructor-store";
import { _Page } from "./shared/types/constructor";

function App() {
  const [theme, setTheme] = useState<"dark" | "">(
    (localStorage?.getItem(LS.THEME) as "dark" | "") || ""
  );

  const switchTheme = () => {
    if (theme === "dark") {
      localStorage?.setItem(LS.THEME, "");
      setTheme("");
    } else {
      localStorage?.setItem(LS.THEME, "dark");
      setTheme("dark");
    }
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  const MOCK_PAGES: _Page[] = [
    {
      page_title: "Главная",
      page_path: "/",
      page_enabled: true,
    },
    {
      page_title: "Правила",
      page_path: "/rules",
      page_enabled: true,
    },
  ];

  const { setPages } = useConstructorStore();

  useEffect(() => {
    setPages(MOCK_PAGES);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div className={`${theme} flex flex-col h-full min-h-[100vh] text-foreground bg-background`}>
        <Header theme={theme} switchTheme={switchTheme} />
        <main className="h-full flex-1 flex flex-col">
          <Routing />
        </main>
        <Toaster />
      </div>
    </QueryClientProvider>
  );
}

export default App;
