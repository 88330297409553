import { useState } from "react";
import { v4 } from "uuid";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import useText from "src/shared/hooks/use-text";
import { CSSTools } from "src/shared/types/constructor";
import { EnumCSSProp } from "src/shared/constants/css_properties";
import { _Select } from "src/shared/types/common";
import { getRandomColor } from "src/shared/utils/common";

type Props = {
  attr: EnumCSSProp;
  defaultValue: string;
  updateStyleAttr: (attr: EnumCSSProp, value: string) => void;
};

const templates_options: _Select[] = [
  {
    label: "radial",
    value: "radial-gradient({1}, {2})",
  },
  {
    label: "closest-side",
    value: "radial-gradient(closest-side, {1}, {2}, {3})",
  },
  {
    label: "radial-circle",
    value: "radial-gradient(circle at 100%, {1}, {1} 50%, {2} 75%, {1} 75%)",
  },
  {
    label: "vertical ellipses",
    value: `radial-gradient(ellipse at top, {1}, transparent), radial-gradient(ellipse at bottom, {2}, transparent)`,
  },
];

export const RandomGradient = ({ attr, defaultValue, updateStyleAttr }: Props) => {
  const text = useText().consturctor.css_tools[CSSTools.GRADIENT];
  const [template, setTemplate] = useState(templates_options[0]);

  const generateGradient = (template: _Select) => {
    const randomHSLA = () =>
      `hsla(${getRandomColor(v4())},${Math.ceil(Math.random() * 100)}%,${Math.ceil(
        Math.random() * 100
      )}%,${Math.ceil(Math.random() * 100) / 100})`;
    const result = template.value
      .replaceAll("{1}", randomHSLA())
      .replaceAll("{2}", randomHSLA())
      .replaceAll("{3}", randomHSLA());
    updateStyleAttr(attr, result);
  };

  return (
    <div className="flex flex-col gap-2 p-2 rounded-md border">
      {text.random_gradient}
      <ToggleGroup
        type="single"
        variant="outline"
        defaultValue={defaultValue}
        className="grid grid-cols-4 gap-1"
        onValueChange={(e) => {
          const currentTemplate =
            templates_options.find((el) => el.label === e) || templates_options[0];
          setTemplate(currentTemplate);
          generateGradient(currentTemplate);
        }}>
        {templates_options?.map((el) => {
          return (
            <ToggleGroupItem
              key={el.label}
              value={el.label}
              className="data-[state=on]:bg-background text-[10px] p-2 h-fit">
              {el.label}
            </ToggleGroupItem>
          );
        })}
      </ToggleGroup>
      <Button
        onClick={() => {
          generateGradient(template);
        }}>
        {text.generate}
      </Button>
    </div>
  );
};
