import { LoaderCircle } from "lucide-react";

export const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full h-full py-5">
      <div className="animate-[spin_2s_ease-in-out_infinite] text-foreground">
        <LoaderCircle />
      </div>
    </div>
  );
};
