import { create } from "zustand";
import { persist } from "zustand/middleware";
import { NestedObjects } from "../types/common";
import { Breakpoints, _ElementStyle, _Page, _Preset } from "../types/constructor";
import { presets } from "../constants/presets";

interface ConstructorStoreState {
  customStyles: NestedObjects<_ElementStyle>;
  setCustomStyles: (customStyles: NestedObjects<_ElementStyle>) => void;
  selectedPreset: _Preset;
  setSelectedPreset: (selectedPreset: _Preset) => void;
  selectedElementId: string | null;
  setSelectedElementId: (selectedElementId: string | null) => void;
  updateSelectedElementId: (fn: (prev: string | null) => string | null) => void;
  pages: _Page[] | null;
  setPages: (pages: _Page[] | null) => void;
  currentPage: _Page | null;
  setCurrentPage: (currentPage: _Page | null) => void;
  breakpoints: Breakpoints;
  setBreakpoints: (breakpoints: Breakpoints) => void;
}

const initialState = {
  customStyles: {},
  selectedPreset: presets[0],
  selectedElementId: null,
  pages: null,
  currentPage: null,
  breakpoints: Breakpoints.SM,
};

export const useConstructorStore = create<ConstructorStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setCustomStyles: (customStyles) => set({ customStyles }),
      setSelectedPreset: (selectedPreset) => set({ selectedPreset }),
      setSelectedElementId: (selectedElementId) => set({ selectedElementId }),
      updateSelectedElementId: (fn: (prev: string | null) => string | null) => {
        set((state => ({ selectedElementId: fn(state.selectedElementId) })))
      },
      setPages: (pages) => set({ pages }),
      setCurrentPage: (currentPage) => set({ currentPage }),
      setBreakpoints: (breakpoints) => set({ breakpoints }),
    }),
    {
      name: "constructorStore",
    }
  )
);
