import { CSSProperties } from "react";
import { EnumElement } from "./elements";

const { DIV, IMAGE, GRID, CARD } =
  EnumElement;

export interface LayoutBlock {
  type: EnumElement;
  style: Partial<CSSProperties>;
  content?: string;
  className: string;
  children?: LayoutBlock[];
}

export interface Layout {
  title: string;
  blocks: LayoutBlock[];
}


export const layouts: Layout[] = [
  {
    title: "Grid Layout with Image",
    blocks: [
      {
        type: GRID,
        className:
          "grid grid-cols-2 gap-4 p-4 bg-gray-50 w-full h-full",
        style: {
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "16px",
          width: "auto",
        },
        children: [
          {
            type: DIV,
            content: "Заглушка-блок",
            className: "bg-gray-200 rounded-lg p-4 col-span-1",
            style: {
              width: "auto",

            },
          },
          {
            type: IMAGE,
            content: "Заглушка-изображение",
            className:
              "w-full h-full bg-gray-300 rounded-lg shadow-md object-cover col-span-1",
              style: {
              
              },
          },
          {
            type: DIV,
            content: "Заглушка-блок",
            className: "bg-gray-200 rounded-lg p-4 col-span-2",
            style: {
              gridColumn: "span 2 / span 2",
            },
          },
        ]
      },
    ],
  },

  {
    title: "Complex Grid Layout",
    blocks: [
      {
        type: GRID,
        className:
          "grid grid-cols-4 gap-2 p-4 bg-gray-50 w-full h-full",
          style: {
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            gap: "8px",
            width: "auto",
          },
        children: [
          {
            type: CARD,
            content: "Заглушка-карточка 1",
            className: "bg-gray-200 rounded-lg p-2 col-span-2 row-span-2",
            style: {
              gridColumn: "span 2 / span 2",
              gridRow: "span 2 / span 2",
            },
          },
          {
            type: CARD,
            content: "Заглушка-карточка 2",
            className: "bg-gray-200 rounded-lg p-2 col-span-2 row-span-1",
            style: {
              gridColumn: "span 2 / span 2",
            },
          },
          {
            type: CARD,
            content: "Заглушка-карточка 3",
            className: "bg-gray-200 rounded-lg p-2 col-span-1 row-span-1",
            style: {
              
            },
          },
          {
            type: CARD,
            content: "Заглушка-карточка 4",
            className: "bg-gray-200 rounded-lg p-2 col-span-1 row-span-1",
            style: {
              
            },
          },
        ]
      },
    ],
  },

  // {
  //   title: "Flex Layout with Buttons",
  //   blocks: [
  //     {
  //       type: FLEX,
  //       className:
  //         "flex flex-col justify-between p-4 bg-gray-50 w-full h-full",
  //         style: {
              
  //         },
  //       children: [
  //         {
  //           type: H2,
  //           content: "Заглушка-заголовок",
  //           className:
  //             "text-2xl font-semibold text-gray-600 bg-gray-300 rounded h-1/4",
  //             style: {
              
  //             },
  //         },
  //         {
  //           type: P,
  //           content: "Заглушка-текст",
  //           className:
  //             "text-base leading-relaxed text-gray-600 bg-gray-200 rounded h-1/4",
  //             style: {
              
  //             },
  //         },
  //         {
  //           type: BUTTON,
  //           content: "Заглушка-кнопка",
  //           className: "bg-gray-400 text-white py-2 px-4 rounded-lg h-1/4",
  //           style: {
              
  //           },
  //         },
  //       ]
  //     },
  //   ],
  // },

  // {
  //   title: "Flex Layout with Image",
  //   blocks: [
  //     {
  //       type: FLEX,
  //       className:
  //         "flex items-center p-4 bg-gray-50 w-full h-full",
  //         style: {
              
  //         },
  //       children: [
  //         {
  //           type: IMAGE,
  //           content: "Заглушка-изображение",
  //           className: "w-1/3 h-full bg-gray-300 rounded-lg shadow-md object-cover",
  //           style: {
              
  //           },
  //         },
  //         {
  //           type: DIV,
  //           content: "Заглушка-блок",
  //           className: "flex-1 ml-4 bg-gray-200 rounded-lg p-4",
  //           style: {
              
  //           },
  //         },
  //       ]
  //     },
  //   ],
  // },

  // {
  //   title: "Section with Form",
  //   blocks: [
  //     {
  //       type: SECTION,
  //       className:
  //         "bg-white p-6 max-w-full h-full flex flex-col justify-center",
  //       children: [
  //         {
  //           type: H1,
  //           content: "Заглушка-заголовок",
  //           className:
  //             "text-3xl font-bold mb-4 text-center text-gray-800 bg-gray-300 rounded h-12",
  //         },
  //         {
  //           type: INPUT,
  //           content: "Заглушка-инпут",
  //           className: "border p-2 w-full mb-4 rounded-lg bg-gray-100 h-10",
  //         },
  //         {
  //           type: BUTTON,
  //           content: "Заглушка-кнопка",
  //           className:
  //             "bg-gray-400 text-white py-3 px-4 rounded-lg block w-full h-10",
  //         },
  //       ]
  //     },
  //   ],
  // },
];
