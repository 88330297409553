import { useEffect } from "react";
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card";
import { LS } from "src/shared/constants/constants";
import useText from "src/shared/hooks/use-text";
import { LangEnum } from "src/shared/store/common-store";
import { useLocalizationsStore } from "src/shared/store/localizations-store";
import { cn } from "@/lib/utils";
import { Hint } from "@/components/ui/hint";

export const WebsiteLanguages = () => {
  const text = useText().localizations;
  const ls_website_languages = localStorage.getItem(LS.WEBSITE_LANGUAGES);

  const {
    websiteLanguages,
    setWebsiteLanguages,
    selectedLanguage,
    setSelectedLanguage,
    localizations,
  } = useLocalizationsStore();

  const isHaveEmptyLang = !!websiteLanguages?.find((el) => !localizations?.[el]);

  useEffect(() => {
    if (!!ls_website_languages && typeof ls_website_languages === "string") {
      setWebsiteLanguages(ls_website_languages.split(",") as LangEnum[]);
    }
  }, [])

  return (
    <Card
      className={cn(
        "flex flex-col gap-2 duration-300",
        !websiteLanguages?.length ? "bg-secondary" : "bg-background"
      )}>
      {!!websiteLanguages?.length ? (
        <>
          <b className="px-4 pt-3">{text.website_languages}</b>
          <div className="flex flex-wrap gap-1 p-1">
            {websiteLanguages?.map((el) => (
              <Button
                key={el}
                type="button"
                variant={selectedLanguage === el ? "default" : !!localizations?.[el] ? "outline" : "destructive"}
                className="px-3"
                onClick={() => setSelectedLanguage(el)}
              >
                {el}
              </Button>
            ))}
          </div>
          {isHaveEmptyLang && (
            <Hint>{text.defined_empty_localizations}</Hint>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center text-muted-foreground w-full p-4 min-h-40">
          {text.choose_languages}
        </div>
      )}
    </Card>
  )
}