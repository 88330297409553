import { Button } from "@/components/ui/button";
import { ZoomIn, ZoomOut } from "lucide-react";
import { Dispatch, SetStateAction } from "react";

export const Zoom = ({
  zoom,
  setZoom,
}: {
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <div className="flex">
      <Button
        type="button"
        variant="ghost"
        size="icon"
        className="text-foreground p-2.5"
        disabled={zoom <= 0.5}
        onClick={() => setZoom(zoom - 0.25)}>
        <ZoomOut />
      </Button>
      <Button
        type="button"
        variant="ghost"
        size="icon"
        className="text-foreground p-2.5"
        disabled={zoom >= 1}
        onClick={() => setZoom(zoom + 0.25)}>
        <ZoomIn />
      </Button>
    </div>
  );
};
