import { ReactNode } from "react";
import { Breakpoints, breakpoints_options } from "src/shared/types/constructor";
import { Sm } from "src/shared/image/mockup/sm";
import { Lg } from "src/shared/image/mockup/lg";
import { cn } from "@/lib/utils";

export const Mockup = ({
  breakpoints,
  zoom,
  bodyColor,
  children,
}: {
  breakpoints: Breakpoints;
  zoom: number;
  bodyColor: string;
  children: ReactNode;
}) => {
  return (
    <div
      className="relative overflow-hidden mx-auto"
      style={{
        ...breakpoints_options[breakpoints],
        top: `${-(1 - zoom) * (breakpoints_options[breakpoints]?.height / 2)}px`,
        transform: `scale(${zoom})`,
      }}>
      {breakpoints === Breakpoints.SM
        ? <Sm className="hidden sm:block absolute left-2 top-2" />
        : <Lg className="hidden sm:block absolute left-2 top-2" />
      }
      <div
        className={cn(
          "relative",
          breakpoints === Breakpoints.SM && "sm:left-[30px] sm:top-4 mt-2 mb-6 sm:py-[20px] overflow-y-auto sm:rounded-[60px] w-full h-full sm:h-[calc(100%-40px)] sm:w-[calc(100%-60px)]",
          breakpoints === Breakpoints.LG && "sm:left-[88px] sm:-top-2.5 my-6 overflow-y-auto sm:rounded-[20px] w-full h-full sm:h-[calc(100%-40px)] sm:w-[calc(100%-176px)] *>:scale-75",
        )}
        style={{
          backgroundColor: bodyColor?.substring(0, 7),
        }}>
        {children}
      </div>
    </div>
  );
};
