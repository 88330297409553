import { useEffect, useState } from "react";
import { Download } from "lucide-react";
import { v4 } from "uuid";
import { PopoverClose } from "@radix-ui/react-popover";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { useLocalizationsStore } from "src/shared/store/localizations-store";
import { generateCeoDocument } from "src/shared/utils/generate-document";
import { _CEO } from "src/shared/types/ceo";
import { downloadFile } from "src/shared/utils/constructor/download";
import { LS } from "src/shared/constants/constants";
import { usePushCommit } from "src/shared/api/query/use-gitlab/use-gitlab";
import { E_CommitActions, PushCommit } from "src/shared/types/gitlab";
import { docker_compose } from "src/shared/constants/docker-compose";
import { dokerfile } from "src/shared/constants/dokerfile";
import { calculator_submodule_git } from "src/shared/constants/calculator-submodule-git";
import { CALCULATOR_ID, SubmoduleId } from "src/shared/constants/submodules";
import { generateCalculatorCss } from "../../submodules/calculator/generate-calculator-css";
import { NODE_ID } from "src/shared/constants/elements";

export const SaveAsButton = () => {
  const text = useText().consturctor;
  const savedCeo = localStorage.getItem(LS.CEO);
  const parsedSavedCeo = (!!savedCeo ? JSON.parse(savedCeo) : {}) as _CEO;

  const [isSaveButtonAnimation, setIsSaveButtonAnimation] = useState(false);

  const { selectedPreset, currentPage } = useConstructorStore();
  const { websiteLanguages } = useLocalizationsStore();
  const { mutateAsync: pushCommit, isPending } = usePushCommit();

  const createFile = (): File | null => {
    const doc = document.getElementById(NODE_ID.DOC);
    if (!doc) {
      return null;
    }
    const generatedDocument = generateCeoDocument(
      parsedSavedCeo,
      selectedPreset,
      doc.innerHTML,
      websiteLanguages || [],
    );
    const file = new File(
      [generatedDocument],
      v4(),
      { type: "text/html;charset=utf-8" }
    );
    return file;
  }

  const initProject = async () => {
    const payload: PushCommit = {
      branch: "development",
      commit_message: `[+] - init docker ${new Date()}`,
      actions: [
        {
          action: E_CommitActions.CREATE,
          file_path: "docker-compose.yml",
          content: docker_compose,
        },
        {
          action: E_CommitActions.CREATE,
          file_path: "Dockerfile",
          content: dokerfile,
        },
        // если нет калькулятора
        // {
        //   action: E_CommitActions.CREATE,
        //   file_path: "index.html",
        //   content: "",
        // },
        // если есть калькулятор - ждать пайплайн потом доступен pushCommit
        {
          action: E_CommitActions.CREATE,
          file_path: ".gitmodules",
          content: calculator_submodule_git,
        },
      ]
    }
    const data = await pushCommit(payload);
    console.log(data);
    setIsSaveButtonAnimation(true);
  }

  const submitCommit = async () => {
    const doc = document.getElementById(NODE_ID.DOC);
    const old_calc = document.getElementById(SubmoduleId.CALCULATOR);
    if (!doc || !!!old_calc) {
      return null;
    }

    const calc_node = document.createElement("div");
    calc_node.setAttribute("id", CALCULATOR_ID.ROOT);
    old_calc.parentNode?.replaceChild(calc_node, old_calc);
    const generatedDocument = generateCeoDocument(
      parsedSavedCeo,
      selectedPreset,
      doc.innerHTML,
      websiteLanguages || [],
    );
    const generatedCalculatorCss = generateCalculatorCss(selectedPreset);
    const payload: PushCommit = {
      branch: "test",
      commit_message: `[~] - update layout PUSH_ME_TEST!`,
      actions: [
        {
          action: E_CommitActions.DELETE,
          file_path: "index.html",
        },
        {
          action: E_CommitActions.CREATE,
          file_path: "index.html",
          content: generatedDocument,
        },
        {
          action: E_CommitActions.DELETE,
          file_path: "src/calculator/calculator.css",
        },
        {
          action: E_CommitActions.CREATE,
          file_path: "src/calculator/calculator.css",
          content: generatedCalculatorCss,
        },
      ]
    }
    const data = await pushCommit(payload);
    console.log(data);
    const new_calc_node = document.getElementById(CALCULATOR_ID.ROOT);
    if (!!new_calc_node) {
      new_calc_node.parentNode?.replaceChild(old_calc, new_calc_node);
    }
    setIsSaveButtonAnimation(true);
  }

  useEffect(() => {
    if (isSaveButtonAnimation) {
      setTimeout(() => setIsSaveButtonAnimation(false), 1000);
    }
  }, [isSaveButtonAnimation]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          size="icon"
          className={cn("min-w-10 p-2.5", isSaveButtonAnimation ? "submit-button-animation" : "")}>
          <Download />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="text-xs px-0 pt-4 pb-5 w-fit flex flex-col gap-2 relative -left-1 top-1">
        <PopoverClose asChild>
          <Button
            type="button"
            variant="link"
            className="h-fit justify-start py-1"
            onClick={() => {
              const doc = document.getElementById(NODE_ID.DOC);
              if (!!doc && !!currentPage) {
                if (currentPage.page_path === "/") {
                  localStorage.setItem(LS.PROGRESS_HTML, doc.innerHTML?.replaceAll("selected-highlight", ""));
                } else {
                  localStorage.setItem(LS.PROGRESS_HTML_PAGE + currentPage.page_path, doc.innerHTML?.replaceAll("selected-highlight", ""));
                }
                setIsSaveButtonAnimation(true);
              }
            }}>
            {text.page_tools.common.save_locally}
          </Button>
        </PopoverClose>
        <PopoverClose asChild>
          <Button
            type="button"
            variant="link"
            className="h-fit justify-start py-1"
            onClick={() => {
              const file = createFile();
              if (!file) {
                return;
              }
              window.open(URL.createObjectURL(file), "_blank");
              setIsSaveButtonAnimation(true);
            }}>
            {text.page_tools.common.open_blank}
          </Button>
        </PopoverClose>
        <PopoverClose asChild>
          <Button
            type="button"
            variant="link"
            className="h-fit justify-start py-1"
            onClick={() => {
              const file = createFile();
              if (!file) {
                return;
              }
              downloadFile({
                path: URL.createObjectURL(file),
                name: v4(),
              });
              setIsSaveButtonAnimation(true);
            }}>
            {text.page_tools.common.save_file}
          </Button>
        </PopoverClose>
        <PopoverClose asChild>
          <Button
            type="button"
            variant="link"
            disabled={isPending}
            className="h-fit justify-start py-1"
            onClick={() => initProject()}>
            {text.page_tools.common.init_project}
          </Button>
        </PopoverClose>
        <PopoverClose asChild>
          <Button
            type="button"
            variant="link"
            disabled={isPending}
            className="h-fit justify-start py-1"
            onClick={() => submitCommit()}>
            {text.page_tools.common.push_commit}
          </Button>
        </PopoverClose>
      </PopoverContent>
    </Popover>
  )
}