export enum SubmoduleId {
  LOCALIZATION = "submodule-localization",
  CALCULATOR = "submodule-calculator",
}

export enum CALCULATOR_ID {
  ROOT = "submodule-calculator-root",
  CARD = "submodule-calculator-card",
  TITLE = "submodule-calculator-title",
  LABEL = "submodule-calculator-label",
  INPUT = "submodule-calculator-input",
  SELECT = "submodule-calculator-select",
  ERROR = "submodule-calculator-error",
  SUBMIT_BUTTON = "submodule-calculator-button-submit",
  SWOP_BUTTON = "submodule-calculator-button-swop",
}