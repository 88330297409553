import { AxiosResponse } from "axios";
import { _Login, _User } from "src/shared/types/auth";
import { BASE_URL } from "../../constants/constants";
import api from "../api-instance";

export default class authService {
  static async me(): Promise<AxiosResponse<_User>> {
    return api.get(`${BASE_URL}/auth/me`);
  }
  static async login(data: _Login): Promise<AxiosResponse<_User | { is_2fa_enabled: true }>> {
    return api.post(`${BASE_URL}/auth/login`, data);
  }
  static async login_with_otp(data: _Login): Promise<AxiosResponse<_User>> {
    return api.post(`${BASE_URL}/auth/login`, data);
  }
  static async logout() {
    return api.post(`${BASE_URL}/auth/logout`);
  }
}
