import { useEffect, useState } from "react";
import { ArrowBigUp, ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Option, ReplaceAll } from "lucide-react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { Hint } from "@/components/ui/hint";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { defineTag } from "src/shared/utils/constructor/style-utils";
import { NODE_ID } from "src/shared/constants/elements";

export const DocumentNavigation = () => {
  const text = useText().consturctor;
  const { selectedElementId, updateSelectedElementId } = useConstructorStore();
  const [nodeList, setNodeList] = useState<string[]>([]);
  const [pasteBeforeId, setPasteBeforeId] = useState<string | null>(null);
  const doc = document.getElementById(NODE_ID.DOC);

  useEffect(() => {
    const list_elem = doc?.querySelectorAll("[id]");
    if (!doc || !list_elem) {
      return;
    }
    const list = doc.querySelectorAll("[id]");
    const arr = Array.from(list)?.map((el) => el.id) || []
    if (!!arr && JSON.stringify(arr) !== JSON.stringify(nodeList)) {
      setNodeList([NODE_ID.DOC, ...arr]);
    }
  }, [doc?.childNodes?.length]);

  const catchNavCombination = (e: KeyboardEvent) => {
    e.preventDefault();
    if (selectedElementId && e.altKey && nodeList?.length > 1) {
      updateSelectedElementId((prev) => {
        const index = nodeList?.findIndex((el) => el === prev);
        if (e.code === "ArrowLeft" && index > 0) {
          return nodeList[index - 1];
        } else if (e.code === "ArrowRight" && index < nodeList.length) {
          return nodeList[index + 1];
        } else {
          return prev;
        }
      });
    } else if (selectedElementId && e.shiftKey && nodeList?.length > 1) {
      if (e.keyCode === 13 && !!pasteBeforeId) {
        const nextEL = document.getElementById(pasteBeforeId);
        const selected_element = document.getElementById(selectedElementId);
        if (!!nextEL && !!selected_element) {
          nextEL.insertAdjacentElement("beforebegin", selected_element);
        }
      } else {
        setPasteBeforeId((prev) => {
          const index = nodeList?.findIndex((el) => el === prev);
          if (e.code === "ArrowLeft" && index > 0) {
            return nodeList[index - 1];
          } else if (e.code === "ArrowRight" && index < nodeList.length) {
            return nodeList[index + 1];
          } else {
            return prev;
          }
        });
      }
    }
  }

  useEffect(() => {
    setPasteBeforeId(selectedElementId);
    document.addEventListener("keyup", catchNavCombination);
    return () => document.removeEventListener("keyup", catchNavCombination);
  }, [nodeList, selectedElementId])

  return (
    <Card className="py-1 px-2">
      <div className="text-sm text-muted-foreground font-medium">
        {text.page_tools.common.document_navigation}
      </div>
      <Hint>
        <div className="grid grid-cols-2 gap-1">
          <p className="flex items-center">
            <ArrowUp className="p-1 w-5 h-5" />:
            <Option className="p-1 rounded-md bg-background border w-5 h-5 mx-1" /> +
            <ArrowLeft className="p-1 rounded-md bg-background border w-5 h-5 mx-1" />
          </p>
          <p className="flex items-center">
            <ReplaceAll className="p-1 w-5 h-5" />
            <ArrowUp className="p-1 w-5 h-5" />:
            <ArrowBigUp className="p-0.5 rounded-md bg-background border w-5 h-5 mx-1" /> +
            <ArrowLeft className="p-1 rounded-md bg-background border w-5 h-5 mx-1" />
          </p>
          <p className="flex items-center">
            <ArrowDown className="p-1 w-5 h-5" />:
            <Option className="p-1 rounded-md bg-background border w-5 h-5 mx-1" /> +
            <ArrowRight className="p-1 rounded-md bg-background border w-5 h-5 mx-1" />
          </p>
          <p className="flex items-center">
            <ReplaceAll className="p-1 w-5 h-5" />
            <ArrowDown className="p-1 w-5 h-5" />:
            <ArrowBigUp className="p-0.5 rounded-md bg-background border w-5 h-5 mx-1" /> +
            <ArrowRight className="p-1 rounded-md bg-background border w-5 h-5 mx-1" />
          </p>
          <p />
          <p className="flex items-center">
            <ReplaceAll className="p-1 w-5 h-5" />:
            <ArrowBigUp className="p-0.5 rounded-md bg-background border w-5 h-5 mx-1" /> +
            <span className="flex items-center px-1 rounded-md bg-background border h-5 mx-1">Enter</span>
          </p>
        </div>
      </Hint>
      {nodeList?.map((id) => {
        const tag_entities = id?.split("~") || null;
        const element_code = defineTag(id);

        return (
          <DocumentNavigationItem
            key={`doc_nav_${id}`}
            tag_name={text.elements[element_code]}
            tag_id={tag_entities?.[1]}
            id={id}
            selectedElementId={selectedElementId}
            pasteBeforeId={pasteBeforeId}
          />
        )
      })}
    </Card>
  )
}

const DocumentNavigationItem = ({ tag_name, tag_id, id, selectedElementId, pasteBeforeId }: {
  tag_name: string;
  tag_id?: string;
  id: string;
  selectedElementId: string | null;
  pasteBeforeId: string | null;
}) => {
  return (
    <div
      className={cn(
        "duration-500 flex items-center gap-x-2 h-5",
        selectedElementId === id
          ? "text-foreground text-sm"
          : pasteBeforeId === id
            ? "text-green-500 text-xs font-semibold"
            : "text-muted-foreground text-xs font-extralight"
      )}
    >
      <span className="whitespace-pre text-ellipsis overflow-hidden">
        {tag_name}   {!!tag_id && (
          <small>{tag_id}</small>
        )}</span>
    </div>
  )
}