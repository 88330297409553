import { useState } from "react";
import { v4 } from "uuid";
import { Card } from "@/components/ui/card";
import { Dialog } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Layout, LayoutBlock, layouts } from "src/shared/constants/layouts";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { AssetClient } from "src/shared/types/constructor";
import { EnumElement } from "src/shared/constants/elements";
import { ImageModal } from "../image-modal/image-modal";
import { styleReplacer } from "src/shared/utils/constructor/style-utils";

export const LayoutsPresets = () => {
  const text = useText();
  const { selectedPreset, selectedElementId } = useConstructorStore();
  const [isUploadModal, setUploadModal] = useState(false);

  const selected_element = !!selectedElementId ? document.getElementById(selectedElementId) : null;

  const appendBlockChildren = (block: LayoutBlock, parent: HTMLElement | null) => {
    if (!parent) {
      return;
    }
    if (block.type === EnumElement.IMAGE) {
      setUploadModal(true);
      return;
    }
    const is_container = block.type === EnumElement.GRID || block.type === EnumElement.FLEX;
    const tag = is_container ? "div" : block.type;
    const elem = document.createElement(tag);
    elem.setAttribute("id", `${tag}~${v4()}`);
    elem.setAttribute(
      "style",
      styleReplacer(JSON.stringify({
        ...selectedPreset.elements[tag]?.style,
        ...block.style,
      }))
    );
    if (!is_container) {
      elem.innerHTML = text.consturctor.page_tools.common.input_text;
    }
    parent.append(elem);
    if (!!block.children) {
      block.children.forEach((block_child) => appendBlockChildren(block_child, elem));
    }
  }

  const onSelectLayout = (layout: Layout) => {
    layout.blocks.forEach((block) => appendBlockChildren(block, selected_element));
  }

  const insertImage = (image: AssetClient) => {
    if (!selected_element || !image?.path) {
      return
    }
    const child = document.createElement("img");
    child.setAttribute("id", `img~${v4()}`);
    child.setAttribute("src", image.path);
    selected_element.append(child);
    setUploadModal(false);
  }

  return (
    <div className="bg-secondary relative overflow-hidden text-sm text-muted-foreground">
      <ScrollArea className="h-[calc(100vh-138px)] p-2">
        <div className="flex flex-col gap-2">
          {layouts.map((layout, index) => (
            <div
              key={index}
              onClick={() => onSelectLayout(layout)}
              className="cursor-pointer flex flex-col"
            >
              <p>{layout.title}</p>
              <Card className="w-46 h-32 overflow-hidden">
                {layout.blocks.map((block, block_id) => (
                  <div key={`${index}_${block_id}`} className={block.className}>
                    {block.children?.map((child, child_id) => (
                      <div key={`${index}_${block_id}_${child_id}`} className={child.className} />
                    ))}
                  </div>
                ))}
              </Card>
            </div>
          ))}
        </div>
      </ScrollArea>
      {isUploadModal && (
        <Dialog open={isUploadModal} onOpenChange={setUploadModal}>
          <ImageModal insertImage={insertImage} />
        </Dialog>
      )}
    </div>
  )
}