import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card"
import { _Page } from "src/shared/types/constructor";
import { Power, Trash } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Loader } from "@/components/ui/loader";
import { ModalButton } from "@/components/ui/modal-button";
import { DialogClose } from "@/components/ui/dialog";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { ROUTES } from "src/app/routing/routes";
import { SP } from "src/shared/constants/constants";

export const PageCard = ({ item }: {
  item: _Page;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const text = useText().consturctor.pages;
  const enable_delete = true; // проверка на зарезервированность страниц и активность модулей
  const [switchLoading, setSwitchLoading] = useState(false); // мок - удалить потом
  const [enabled, setSwitchEnabled] = useState(item.page_enabled); // мок - удалить потом

  const { 
    setCurrentPage, 
    pages,
    setPages,
  } = useConstructorStore();

  const deletePage = async () => {
    if (!pages) {
      return;
    }
    setPages(pages.filter((el) => el.page_path !== item.page_path));
  };

  const switchPageEnabled = () => {
    setSwitchLoading(true);
  }

  useEffect(() => {
    if (switchLoading) {
      setTimeout(() => {
        setSwitchLoading(false);
        setSwitchEnabled(!enabled);
      }, 1500)
    }
  }, [switchLoading])

  return (
    <Card className="relative w-[280px] p-1 flex flex-col justify-between gap-1 duration-300 hover:scale-105 hover:shadow-lg">
      <div className="flex gap-1 justify-end">
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className={cn(
            "h-7 w-7",
            enabled && "text-green-400 hover:text-green-400"
          )}
          onClick={switchPageEnabled}
        >
          {switchLoading ? (
            <Loader />
          ) : (
            <Power
              className="h-4 w-4"
              strokeWidth={2}
            />
          )}
        </Button>
        {enable_delete && (
          <ModalButton
            triggerButton={
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="h-7 w-7 text-muted-foreground"
              >
                <Trash
                  className="h-4 w-4"
                  strokeWidth={2}
                />
              </Button>
            }>
            <div className="flex flex-col gap-2 text-muted-foreground text-sm pt-4 whitespace-pre-line max-h-[60vh] overflow-y-auto">
              {text.confirm_delete}
            </div>
            <DialogClose asChild>
              <Button
                type="button"
                variant="outline"
                className="justify-self-center"
                onClick={deletePage}
              >
                {text.delete}
              </Button>
            </DialogClose>
          </ModalButton>
        )}
      </div>
      <div className="flex flex-col gap-3">
        <div className="w-full h-28 mx-auto mb-3 rounded-md shadow-inner bg-secondary/50">
          {/* ИЗОБРАЖЕНИЕ СТРАНИЦЫ */}
        </div>
        <p className="text-xl font-semibold text-center">{item.page_title}</p>
        <small className="text-slate-500 pb-3 px-3">{item.page_path}</small>
      </div>
      <Button
        variant="outline"
        className="min-h-[40px]"
        onClick={() => {
          setCurrentPage(item);
          searchParams.set(SP.PAGE, item.page_path.replaceAll("/", "_"))
          navigate(ROUTES.CONSTRUCTOR_PAGES + "?" + searchParams.toString())
        }}>
        {text.edit}
      </Button>
    </Card>
  );
};