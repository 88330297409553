import { useState } from "react";
import { v4 } from "uuid";
import { Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import useText from "src/shared/hooks/use-text";
import { defineTag, styleReplacer } from "src/shared/utils/constructor/style-utils";
import { EnumElement } from "src/shared/constants/elements";
import { Alignment, Display, E_FlexDirection } from "src/shared/types/constructor";
import { BaseGhost } from "../base-ghost";
import { FlexToolValues } from "./flex-tool-values";
import { FlexToolAlignment } from "./flex-tool-alignment";
import { default_flex_style } from "./flex-tool";
import { useConstructorStore } from "src/shared/store/constructor-store";

export type FlexStyle = {
  display: Display;
  flexDirection?: E_FlexDirection;
  justifyContent?: Alignment;
  justifyItems?: Alignment;
  alignContent?: Alignment;
  alignItems?: Alignment;
  minHeight: "20px";
  gap: string;
}

export const FlexGhost = ({
  resetTool,
}: {
  resetTool: () => void;
}) => {
  const text = useText().consturctor.page_tools[Display.FLEX];
  const { selectedElementId } = useConstructorStore();
  const [style, setStyle] = useState<FlexStyle>(default_flex_style);

  const cellsArr = [
    { width: 50, height: 50 },
    { width: 100, height: 100 },
    { width: 100, height: 75 },
  ];

  const createFlex = () => {
    const is_section = !!selectedElementId && defineTag(selectedElementId) === EnumElement.SECTION;
    const section = document.getElementById(is_section ? selectedElementId : EnumElement.SECTION);
    const node = document.createElement(EnumElement.DIV);
    node.setAttribute("id", `${EnumElement.FLEX}~${v4()}`);
    node.setAttribute(
      "style",
      styleReplacer(
        JSON.stringify({
          ...style,
          gap: (style?.gap as string || "0")?.replace(/\s+/g, " ").trim().replaceAll(" ", "px ").trim() + "px",
        })
      )
    );
    section?.append(node);
    resetTool();
  };

  return (
    <BaseGhost
      triggerBlock={
        <div
          className="*:border *:border-green-400"
          style={{
            ...style,
            gap: (style?.gap as string || "0").replace(/\s+/g, " ").trim().replaceAll(" ", "px ").trim() + "px",
          }}>
          {cellsArr?.map((el, index) => (
            <div key={index} className="rounded-md bg-green-400/30" style={el} />
          ))}
        </div>
      }
      toolsBlock={
        <FlexToolValues style={style} setStyle={setStyle} />
      }
      infoBlock={
        <>
          <p>
            <code className="text-foreground rounded-md px-2 bg-secondary -ml-2">FLEX</code>
            {text.info.d}
          </p>
          <p>
            <code className="-ml-2 text-foreground rounded-md px-2 bg-secondary">{text.gap}</code>
            {text.info.gap}
          </p>
        </>
      }
      submitBlock={
        <>
          <FlexToolAlignment style={style} setStyle={setStyle} wide />
          <Button
            type="button"
            size="icon"
            variant="outline"
            className="h-7 w-7 min-w-7 p-1 self-end bg-green-500 text-white"
            onClick={() => createFlex()}>
            <Check />
          </Button>
        </>
      }
      resetTool={resetTool}
    />
  );
};
