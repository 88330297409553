import { EnumElement } from "src/shared/constants/elements";
import { _Preset } from "src/shared/types/constructor";
import { styleReplacer } from "src/shared/utils/constructor/style-utils";

export const generateCalculatorCss = (
  selectedPreset: _Preset,
): string => {
  return (`
.submodule-calculator-card {
  ${styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.CARD].style), true)};
}

.submodule-calculator-title {
  ${styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.H2].style), true)};
  margin: 0 0 20px 0;
}

.submodule-calculator-label {
  ${styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.LABEL].style), true)};
}

.submodule-calculator-input {
  ${styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.INPUT].style), true)};
}

.submodule-calculator-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #aaa;
  padding: 8px 12px;
  font-size: 14px;
}

.submodule-calculator-error {
  color: red;
  font-size: 14px;
}

.submodule-calculator-button-submit {
  ${styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.BUTTON].style), true)};
}

.submodule-calculator-button-swop {
  ${styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.BUTTON].style), true)};
  display: flex;
  justify-content: center;
  align-content: center;
  height: 40px;
  width: 40px;
  margin: 0 auto;
}`
  )
}