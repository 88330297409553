import { CSSProperties, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { Card } from "@/components/ui/card";
import { Dialog } from "@/components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { addStyleMeasures, defineTag, styleReplacer } from "src/shared/utils/constructor/style-utils";
import { AssetClient, Display, E_ElementTool, _CSSProperty, _Element, _ElementTool } from "src/shared/types/constructor";
import { element_tools } from "src/shared/constants/element_tools";
import { EnumElement, EnumElementGroup, NODE_ID, default_elements } from "src/shared/constants/elements";
import { NestedEntries, NestedObjects } from "src/shared/types/common";
import { CssTools } from "src/widgets/css_tools/css_tools";
import { cn } from "@/lib/utils";
import { EnumCSSProp } from "src/shared/constants/css_properties";
import { Button } from "@/components/ui/button";
import { initCalculatorSubmodule } from "../../submodules/calculator/calculator-submodule";
import { FlexStyle } from "./tools/flex/flex-ghost";
import { GridStyle } from "./tools/grid/grid-ghost";
import { default_flex_style } from "./tools/flex/flex-tool";
import { default_grid_style } from "./tools/grid/grid-tool";
import { ImageModal } from "./tools/image-modal/image-modal";

export const ElementTools = ({
  currentTool,
  setCurrentTool,
}: {
  currentTool: _ElementTool | null;
  setCurrentTool: Dispatch<SetStateAction<_ElementTool | null>>;
}) => {
  const { selectedPreset, selectedElementId, setSelectedElementId } = useConstructorStore();
  const elementKey = defineTag(selectedElementId);

  const elements: NestedObjects<_Element> = Object.keys(default_elements).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...default_elements[key],
        style: selectedPreset.elements[key]?.style || default_elements[key].style,
      }
    }
  }, {});

  const element = !!elementKey ? elements[elementKey] : null;
  const text = useText();
  const [textValue, setTextValue] = useState("");
  const [prop, setProp] = useState<NestedEntries<_CSSProperty> | null>(null);
  const [isUploadModal, setUploadModal] = useState(false);

  const selected_element = !!selectedElementId ? document.getElementById(selectedElementId) : null;
  const preview_element = document.getElementById("element_container")?.firstChild as HTMLElement || null || undefined;

  const [displayStyle, setDisplayStyle] = useState<FlexStyle | GridStyle>(
    selected_element?.style?.display === Display.GRID
      ? default_grid_style
      : default_flex_style
  );

  const savedElementStyle = useMemo(() =>
    (!!selected_element ? { ...selected_element.style } : {}) as Partial<CSSProperties>
    , [selected_element?.id]);

  const disabled_element_nesting = element?.group === EnumElementGroup.INLINE
    ? Object.values(default_elements)
      .filter((el) => el.group !== EnumElementGroup.INLINE)
      .map((el) => el.code)
    : [];

  const onSelectElement = (element: EnumElement) => {
    if (!!selected_element) {
      if (element === EnumElement.IMAGE) {
        setUploadModal(true);
        return;
      }
      const tag = (element === EnumElement.GRID || element === EnumElement.FLEX) ? "div" : element;
      const child = document.createElement(tag);
      child.setAttribute("id", `${tag}~${v4()}`);
      child.setAttribute(
        "style",
        styleReplacer(JSON.stringify(selectedPreset.elements[tag]?.style))
      );
      child.innerHTML = text.consturctor.page_tools.common.input_text;
      selected_element.append(child);
    }
  };

  const onSubmitText = (value: string) => {
    if (!!selected_element) {
      selected_element.textContent = value;
      if (!!preview_element) {
        preview_element.textContent = value;
      }
    }
  };

  const onSubmitInsertStyle = (value: string) => {
    if (!selected_element) {
      return;
    }
    const attrs = value
      ?.trim()
      ?.split(";")
      ?.map((el) => el.split(":"))
      ?.map((el) => [
        el[0]
          .replace("\n", "")
          .replace(/-./g, ((x) => x[1].toUpperCase()))
          ?.trim(),
        el[1]
      ])
      ?.filter((el) => {
        return (
          el?.length === 2 &&
          !!el[1]?.length &&
          Object.values(EnumCSSProp).includes(
            el[0] as EnumCSSProp)
        )
      })
    attrs?.forEach((el) => {
      selected_element.style[el[0]] = el[1];
    });
  }

  const dragStartListener = (e: DragEvent) => {
    if (!!e.dataTransfer) {
      e.dataTransfer.effectAllowed = "move";
    }
  };

  const dragOverListener = (e: DragEvent) => {
    e.preventDefault();
    if (!!e.dataTransfer) {
      e.dataTransfer.dropEffect = "move";
    }
  };

  const dragDropListener = (e: DragEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (!!target && "id" in target && !!selectedElementId) {
      const droppable_element = document.getElementById(selectedElementId);

      if (!!droppable_element) {
        target.appendChild(droppable_element);
      }
    }
  };

  const addSection = (e: KeyboardEvent) => {
    const doc = document.getElementById(NODE_ID.DOC);
    if (!doc) {
      return;
    }
    const isCase = e.keyCode === 13;
    if (isCase) {
      const child = document.createElement(EnumElement.SECTION);
      child.setAttribute("id", `${EnumElement.SECTION}~${v4()}`);
      child.setAttribute(
        "style",
        styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.SECTION]?.style))
      );
      const footer = document.getElementById(EnumElement.FOOTER);
      if (!!footer) {
        footer.insertAdjacentElement("beforebegin", child);
      } else {
        doc.append(child);
      }
    }
  };

  const insertImage = (image: AssetClient) => {
    if (!selected_element || !image?.path) {
      return
    }
    const child = document.createElement("img");
    child.setAttribute("id", `img~${v4()}`);
    child.setAttribute("src", image.path);
    selected_element.append(child);
    setUploadModal(false);
  }

  const updateStyleAttr = (attr: EnumCSSProp, value: string) => {
    if (!selected_element) {
      return;
    }
    selected_element.style[attr] = value;
    if (!!preview_element && attr !== EnumCSSProp.POSITION) {
      preview_element.style[attr] = value;
    }
  };

  const updateStyle = (style: Partial<CSSProperties>) => {
    if (!selected_element) {
      return;
    }

    Object.entries(style)?.forEach((el) => {
      selected_element.style[el[0]] = el[1];
      if (!!preview_element) {
        preview_element.style[el[0]] = el[1];
      }
    });
  };

  useEffect(() => {
    const doc = document.getElementById(NODE_ID.DOC);
    if (!doc || !selected_element) {
      return;
    }
    if (!!selected_element.textContent?.length) {
      setTextValue(selected_element.textContent);
    } else {
      setTextValue("");
    }
    if (!!selected_element) {
      setDisplayStyle(
        selected_element?.style?.display === Display.GRID
          ? default_grid_style
          : default_flex_style
      );
    }
    if (currentTool?.code === E_ElementTool.REPLACE) {
      selected_element.setAttribute("draggable", "true");
      selected_element.addEventListener("dragstart", dragStartListener);
      doc.addEventListener("dragover", dragOverListener);
      doc.addEventListener("drop", dragDropListener);
    }
    return () => {
      selected_element.removeEventListener("dragstart", dragStartListener);
      doc.removeEventListener("dragover", dragOverListener);
      doc.removeEventListener("drop", dragDropListener);
    }
  }, [selectedElementId]);

  useEffect(() => {
    const doc = document.getElementById(NODE_ID.DOC);
    if (!selected_element || !doc) {
      setCurrentTool(null);
      return;
    }

    if (currentTool?.code === E_ElementTool.REPLACE) {
      selected_element.setAttribute("draggable", "true");
      selected_element.addEventListener("dragstart", dragStartListener);
      doc.addEventListener("dragover", dragOverListener);
      doc.addEventListener("drop", dragDropListener);
    } else {
      selected_element.setAttribute("draggable", "false");
      selected_element.removeEventListener("dragstart", dragStartListener);
      doc.removeEventListener("dragover", dragOverListener);
      doc.removeEventListener("drop", dragDropListener);
    }

    if (currentTool?.code === E_ElementTool.DELETE) {
      selected_element.remove();
      setSelectedElementId(null);
      setCurrentTool(null);
    }

    if (currentTool?.code === E_ElementTool.SECTION) {
      document.addEventListener("keyup", addSection);
    } else {
      document.removeEventListener("keyup", addSection);
    }
  }, [currentTool]);

  useEffect(() => {
    const styleWithMeasures = addStyleMeasures(displayStyle);
    updateStyle(styleWithMeasures);
  }, [displayStyle]);

  useEffect(() => {
    if (!!preview_element) {
      preview_element.style.position = "static";
    }
  }, [preview_element?.id])

  return (
    <Card className="flex flex-col gap-1 w-full p-1">
      <ToggleGroup
        type="single"
        variant="outline"
        className="flex items-center justify-start gap-1"
        onValueChange={(e) => {
          if (!!e && currentTool?.code !== e) {
            const tool = element_tools.find((el) => el.code === e) || element_tools[0];
            setCurrentTool(tool);
          }
        }}>
        {element_tools
          .filter((el) => !!elementKey && el.availableElements.includes(elementKey))
          ?.map((el) => {
            const Icon = el.icon;
            return (
              <ToggleGroupItem
                key={el.code}
                value={el.code}
                className={cn(
                  "text-[10px] p-1.5 h-fit h-8 w-8",
                  el.code === currentTool?.code
                    ? "bg-foreground text-background data-[state=on]:bg-foreground data-[state=on]:text-background"
                    : "bg-background text-muted-foreground data-[state=on]:bg-background data-[state=on]:text-muted-foreground"
                )}>
                {!!el.content ? (
                  <Popover>
                    <PopoverTrigger asChild>
                      <Icon />
                    </PopoverTrigger>
                    <PopoverContent
                      align="start"
                      className="text-xs p-2 w-fit sm:min-w-[310px] flex flex-col gap-2 relative -left-4 top-1">
                      {el.content({
                        text,
                        value: textValue,
                        setValue: setTextValue,
                        prop,
                        setProp,
                        elementStyle: savedElementStyle,
                        displayStyle,
                        setDisplayStyle,
                        disabled_nesting: disabled_element_nesting,
                        onSelectElement,
                        onSubmitText,
                        onSubmitInsertStyle,
                      })}
                    </PopoverContent>
                  </Popover>
                ) : (
                  <Icon />
                )}
              </ToggleGroupItem>
            );
          })}
      </ToggleGroup>
      {!!selectedElementId && (
        <Button
          type="button"
          onClick={() => initCalculatorSubmodule(selectedElementId, selectedPreset)}
        >
          🐝Подключить сабмодуль
        </Button>
      )}
      {(!!selected_element && currentTool?.code === E_ElementTool.STYLE) && (
        <>
          <p className="py-2 font-semibold">{!!prop ? text.consturctor.css_properties[prop.value.property] : ""}</p>
          <CssTools
            elementKey={elementKey}
            elementStyle={prop
              ? { [prop?.value.property]: selected_element.style[prop?.value.property] }
              : null}
            prop={prop}
            updateStyleAttr={updateStyleAttr}
          />
        </>
      )}
      {isUploadModal && (
        <Dialog open={isUploadModal} onOpenChange={setUploadModal}>
          <ImageModal insertImage={insertImage} />
        </Dialog>
      )}
    </Card>
  );
};
