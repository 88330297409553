import { ReactNode } from "react";
import { NestedObjects } from "../types/common";
import { Alignment, Display, E_FlexDirection, _Element } from "../types/constructor";
import PresetButton from "src/pages/constructor/ui/button";
import PresetCard from "src/pages/constructor/ui/card";

export enum NODE_ID {
  DOC = "new_document",
}

export enum EnumElement {
  BODY = "body",
  SECTION = "section",
  HEADER = "header",
  FOOTER = "footer",
  // inline
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  BUTTON = "button",
  LABEL = "label",
  INPUT = "input",
  SELECT = "select",
  IMAGE = "img",
  // block
  DIV = "div",
  GRID = "grid",
  FLEX = "flex",
  P = "p",
  CARD = "card",
}

export enum EnumElementGroup {
  ROOT = "root",
  INLINE = "inline",
  BLOCK = "block",
}

export const default_elements: NestedObjects<_Element> = {
  [EnumElement.BODY]: {
    code: EnumElement.BODY,
    group: EnumElementGroup.ROOT,
    style: {
      display: Display.FLEX,
      flexDirection: "column",
      gap: "16px",
      margin: 0,
      padding: "0",
      width: "auto",
      minHeight: "100vh",
      whiteSpace: "pre-line",
      backgroundColor: "transparent",
    },
    element: (props) => (
      <PresetButton {...props}>
        {"children" in props ? (props.children as ReactNode) : ""}
      </PresetButton>
    ),
  },
  [EnumElement.SECTION]: {
    code: EnumElement.SECTION,
    group: EnumElementGroup.ROOT,
    style: {
      display: Display.FLEX,
      flexDirection: "column",
      gap: "16px",
      flexGrow: 1,
      minHeight: "20px",
      width: "auto",
      margin: 0,
      padding: "20px 20px",
      fontSize: "1em",
    },
    element: (props) => (
      <section {...props}>{"children" in props ? (props.children as ReactNode) : ""}</section>
    ),
  },
  [EnumElement.HEADER]: {
    code: EnumElement.HEADER,
    group: EnumElementGroup.ROOT,
    style: {
      display: Display.FLEX,
      flexDirection: E_FlexDirection.ROW,
      justifyContent: Alignment.BETWEEN,
      alignItems: Alignment.CENTER,
      gap: "16px",
      minHeight: "20px",
      width: "auto",
      margin: 0,
      padding: "20px 20px",
      fontSize: "1em",
    },
    element: (props) => (
      <header {...props}>{"children" in props ? (props.children as ReactNode) : ""}</header>
    ),
  },
  [EnumElement.FOOTER]: {
    code: EnumElement.FOOTER,
    group: EnumElementGroup.ROOT,
    style: {
      display: Display.FLEX,
      flexDirection: E_FlexDirection.COL,
      gap: "16px",
      minHeight: "20px",
      width: "auto",
      margin: 0,
      padding: "20px 20px",
      fontSize: "1em",
    },
    element: (props) => (
      <footer {...props}>{"children" in props ? (props.children as ReactNode) : ""}</footer>
    ),
  },
  // inline
  [EnumElement.H1]: {
    code: EnumElement.H1,
    group: EnumElementGroup.INLINE,
    style: {
      margin: 0,
      padding: 0,
      fontSize: "2.5em",
      fontWeight: "700",
    },
    element: (props) => (
      <h1 {...props}>{"children" in props ? (props.children as ReactNode) : ""}</h1>
    ),
  },
  [EnumElement.H2]: {
    code: EnumElement.H2,
    group: EnumElementGroup.INLINE,
    style: {
      margin: 0,
      padding: 0,
      fontSize: "2em",
      fontWeight: "700",
    },
    element: (props) => (
      <h2 {...props}>{"children" in props ? (props.children as ReactNode) : ""}</h2>
    ),
  },
  [EnumElement.H3]: {
    code: EnumElement.H3,
    group: EnumElementGroup.INLINE,
    style: {
      margin: 0,
      padding: 0,
      fontSize: "1.5em",
      fontWeight: "700",
    },
    element: (props) => (
      <h3 {...props}>{"children" in props ? (props.children as ReactNode) : ""}</h3>
    ),
  },
  [EnumElement.H4]: {
    code: EnumElement.H4,
    group: EnumElementGroup.INLINE,
    style: {
      margin: 0,
      padding: 0,
      fontSize: "1.3em",
      fontWeight: "600",
    },
    element: (props) => (
      <h4 {...props}>{"children" in props ? (props.children as ReactNode) : ""}</h4>
    ),
  },
  [EnumElement.BUTTON]: {
    code: EnumElement.BUTTON,
    group: EnumElementGroup.INLINE,
    style: {
      minHeight: "20px",
      height: "fit-content",
      width: "fit-content",
      margin: 0,
      padding: "12px 32px",
      borderRadius: "0.375em",
      fontSize: "1em",
    },
    element: (props) => (
      <PresetButton {...props}>
        {"children" in props ? (props.children as ReactNode) : ""}
      </PresetButton>
    ),
  },
  [EnumElement.LABEL]: {
    code: EnumElement.LABEL,
    group: EnumElementGroup.INLINE,
    style: {
      fontSize: "16px",
      fontWeight: "600",
      margin: "4px 0 4px 0",
    },
    element: (props) => (
      <label {...props}>{"children" in props ? (props.children as ReactNode) : ""}</label>
    ),
  },
  [EnumElement.INPUT]: {
    code: EnumElement.INPUT,
    group: EnumElementGroup.INLINE,
    style: {
      display: Display.FLEX,
      height: "40px",
      width: "100%",
      borderRadius: "0.375rem",
      border: "1px solid #aaa",
      padding: "8px 12px",
      fontSize: "14px",
    },
    // eslint-disable-next-line
    element: ({ children, ...props }) => (
      <input {...props} />
    ),
  },
  [EnumElement.IMAGE]: {
    code: EnumElement.IMAGE,
    group: EnumElementGroup.INLINE,
    style: {
      height: "100%",
      width: "100%",
    },
    // eslint-disable-next-line
    element: ({ children, ...props }) => (
      <img {...props} />
    ),
  },
  // block
  [EnumElement.DIV]: {
    code: EnumElement.DIV,
    group: EnumElementGroup.BLOCK,
    style: {
      minHeight: "20px",
      width: "auto",
      margin: 0,
      padding: 0,
      fontSize: "1em",
    },
    element: (props) => (
      <div {...props}>{"children" in props ? (props.children as ReactNode) : ""}</div>
    ),
  },
  [EnumElement.GRID]: {
    code: EnumElement.GRID,
    group: EnumElementGroup.BLOCK,
    style: {
      display: Display.GRID,
      minHeight: "20px",
      height: "fit-content",
      width: "auto",
      margin: 0,
      padding: 0,
      fontSize: "1em",
    },
    element: (props) => (
      <div {...props}>{"children" in props ? (props.children as ReactNode) : ""}</div>
    ),
  },
  [EnumElement.FLEX]: {
    code: EnumElement.FLEX,
    group: EnumElementGroup.BLOCK,
    style: {
      display: Display.FLEX,
      flexDirection: "row",
      gap: "16px",
      minHeight: "20px",
      height: "fit-content",
      width: "auto",
      margin: 0,
      padding: 0,
      fontSize: "1em",
    },
    element: (props) => (
      <div {...props}>{"children" in props ? (props.children as ReactNode) : ""}</div>
    ),
  },
  [EnumElement.P]: {
    code: EnumElement.P,
    group: EnumElementGroup.BLOCK,
    style: {
      minHeight: "20px",
      width: "auto",
      margin: 0,
      padding: 0,
      fontSize: "1em",
    },
    element: (props) => (
      <p {...props}>{"children" in props ? (props.children as ReactNode) : ""}</p>
    ),
  },
  [EnumElement.CARD]: {
    code: EnumElement.CARD,
    group: EnumElementGroup.BLOCK,
    style: {
      display: Display.FLEX,
      flexDirection: "column",
      gap: "24px",
      minHeight: "20px",
      width: "auto",
      margin: 0,
      padding: "16px",
    },
    element: (props) => (
      <PresetCard {...props}>{"children" in props ? (props.children as ReactNode) : ""}</PresetCard>
    ),
  },
};
