import { CSSProperties, useEffect, useState } from "react";
import ColorPicker from "react-best-gradient-color-picker";
import { EnumCSSProp } from "src/shared/constants/css_properties";
import { EnumElement } from "src/shared/constants/elements";
import { NestedEntries } from "src/shared/types/common";
import { CSSTools, _CSSProperty } from "src/shared/types/constructor";
import { CopyTool } from "./components/copy-tool";
import { SingleValueTool } from "./components/single-value-tool";
import { SingleToggleTool } from "./components/single-toggle-tool";
import { RandomGradient } from "./components/random-gradient";
import { FourValueTool } from "./components/four-value-tool";
import { CurrentFourValueTool } from "./components/current-four-value-tool";
import { useConstructorStore } from "src/shared/store/constructor-store";

type Props = {
  elementKey: EnumElement | null;
  elementStyle: Partial<CSSProperties> | null;
  prop: NestedEntries<_CSSProperty> | null;
  defaultValue?: string | null;
  updateStyleAttr: (attr: EnumCSSProp, value: string) => void;
};

export const CssTools = ({
  elementKey,
  elementStyle,
  prop,
  updateStyleAttr,
}: Props) => {
  const [color, setColor] = useState("");

  const { selectedElementId } = useConstructorStore();

  useEffect(() => {
    if (!!prop && !prop.value.tools.includes(CSSTools.GRADIENT)) {
      setColor("rgba(255,255,255,1)");
    }
  }, [prop?.value?.tools]);

  return (
    <div className="flex flex-col gap-2">
      {!!prop && !!elementKey && (
        <>
          {prop.value.tools.includes(CSSTools.COPY) && (
            <CopyTool
              attr={prop.value.property}
              defaultValue={elementStyle?.[prop.value.property]?.toString() || ""}
              updateStyleAttr={updateStyleAttr}
              trigger={selectedElementId}
            />
          )}
          {prop.value.tools.includes(CSSTools.TOGGLER) && (
            <SingleToggleTool
              attr={prop.value.property}
              defaultValue={elementStyle?.[prop.value.property]?.toString() || ""}
              options={prop.value.options || []}
              updateStyleAttr={updateStyleAttr}
            />
          )}
          {prop.value.tools.includes(CSSTools.EM) && (
            <SingleValueTool
              attr={prop.value.property}
              defaultValue={elementStyle?.[prop.value.property]?.toString() || ""}
              measure="em"
              updateStyleAttr={updateStyleAttr}
            />
          )}
          {prop.value.tools.includes(CSSTools.FOUR_EM) && (
            <FourValueTool
              attr={prop.value.property}
              defaultValue={elementStyle?.[prop.value.property]?.toString() || ""}
              measure="em"
              updateStyleAttr={updateStyleAttr}
            />
          )}
          {prop.value.tools.includes(CSSTools.FOUR_PX) && (
            <FourValueTool
              attr={prop.value.property}
              defaultValue={elementStyle?.[prop.value.property]?.toString() || ""}
              measure="px"
              updateStyleAttr={updateStyleAttr}
            />
          )}
          {prop.value.tools.includes(CSSTools.CURRENT_FOUR_PX) && (
            <CurrentFourValueTool
              defaultValues={{
                [EnumCSSProp.TOP]: elementStyle?.[EnumCSSProp.TOP]?.toString() || "",
                [EnumCSSProp.RIGHT]: elementStyle?.[EnumCSSProp.RIGHT]?.toString() || "",
                [EnumCSSProp.BOTTOM]: elementStyle?.[EnumCSSProp.BOTTOM]?.toString() || "",
                [EnumCSSProp.LEFT]: elementStyle?.[EnumCSSProp.LEFT]?.toString() || "",
              }}
              measure="px"
              updateStyleAttr={updateStyleAttr}
            />
          )}
          {prop.value.tools.includes(CSSTools.GRADIENT) && (
            <RandomGradient
              attr={prop.value.property}
              defaultValue={elementStyle?.[prop.value.property]?.toString() || ""}
              updateStyleAttr={updateStyleAttr}
            />
          )}
          {(prop.value.tools.includes(CSSTools.COLOR) ||
            prop.value.tools.includes(CSSTools.GRADIENT)) && (
              <ColorPicker
                value={color}
                hideColorTypeBtns={!prop.value.tools.includes(CSSTools.GRADIENT)}
                hideGradientControls={!prop.value.tools.includes(CSSTools.GRADIENT)}
                onChange={(value) => {
                  setColor(value);
                  updateStyleAttr(prop.value.property, value);
                }}
              />
            )}
        </>
      )}
    </div>
  );
};
