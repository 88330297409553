import { z } from "zod";
import { Text } from "src/shared/hooks/use-text";
import { loginRegex, mailRegex, pathRegex } from "../constants/constants";

type ValidationText = Text["validation"];

export const string_schema = (text: ValidationText) => z.string({ required_error: text.required });

export const req_string_schema = (text: ValidationText) =>
  z
    .string({ required_error: text.required })
    .min(1, { message: text.required });

export const path_schema = (text: ValidationText) =>
  z
    .string({ required_error: text.required })
    .min(1, { message: text.required })
    .regex(pathRegex, { message: text.path_regex });

export const user_username_schema = (text: ValidationText) =>
  z
    .string({ required_error: text.required })
    .min(1, { message: text.required })
    .regex(loginRegex, { message: text.login_regex });

export const user_email_schema = (text: ValidationText) =>
  z
    .string({ required_error: text.required })
    .min(1, { message: text.required })
    .regex(mailRegex, { message: text.login_regex });

export const user_password_schema = (text: ValidationText) =>
  z.string({ required_error: text.required }).min(12, { message: text.password_min });

export const verify_code_schema = (text: ValidationText) =>
  z
    .string({ required_error: text.required })
    .min(6, { message: text.otp_length })
    .max(6, { message: text.otp_length });
