import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Lightbulb, LightbulbOff, Trash2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { Display, E_ConstructorTool, _ConstructorTool } from "src/shared/types/constructor";
import { Zoom } from "src/entities/tools/zoom";
import { LS, SP } from "src/shared/constants/constants";
import { presets } from "src/shared/constants/presets";
import { PageConstructorTools } from "./components/page-constructor-tools";
import { GridGhost } from "./components/tools/grid/grid-ghost";
import { FlexGhost } from "./components/tools/flex/flex-ghost";
import { Mockup } from "./components/mockup";
import { ElementPanel } from "./components/element-panel";
import { SaveAsButton } from "./components/save-as-button";
import { EnumElement, NODE_ID } from "src/shared/constants/elements";
import { LayoutsPresets } from "./components/tools/layout-presets/layouts-presets";

export const PageConstructor = () => {
  const { toast } = useToast();
  const text = useText().consturctor;
  const [searchParams] = useSearchParams();
  const selectedPresetCode = searchParams?.get(SP.PRESET) || localStorage.getItem(LS.SELECTED_PRESET);
  const {
    customStyles,
    setCustomStyles,
    selectedPreset,
    setSelectedPreset,
    setSelectedElementId,
    pages,
    currentPage,
    setCurrentPage,
    breakpoints,
  } = useConstructorStore();
  const [currentTool, setCurrentTool] = useState<_ConstructorTool<E_ConstructorTool> | null>(null);
  const [isHighlight, highlight] = useState(true);
  const [zoom, setZoom] = useState(1);

  const resetTool = () => setCurrentTool(null);

  const new_document = (
    <section style={selectedPreset.elements.body.style}>
      {currentTool?.code === Display.GRID && (
        <GridGhost resetTool={resetTool} />
      )}
      {currentTool?.code === Display.FLEX && (
        <FlexGhost resetTool={resetTool} />
      )}
      <div id={NODE_ID.DOC} className={cn(
        "flex flex-col container grow h-full mx-auto p-0",
        isHighlight && "highlight",
      )}>
        <div
          id={EnumElement.SECTION}
          style={selectedPreset.elements[EnumElement.SECTION].style}
        />
      </div>
    </section>
  );

  useEffect(() => {
    if (!!Object.keys(customStyles)?.length) {
      const preset = presets.find((el) => el.code === selectedPresetCode) || presets[0];
      localStorage.setItem(LS.CUSTOM_STYLES, JSON.stringify(customStyles));
      setSelectedPreset({
        ...preset,
        elements: Object.keys(preset.elements).reduce((acc, key) => {
          return {
            ...acc,
            [key]: {
              ...preset.elements[key],
              style: {
                ...preset.elements[key]?.style,
                ...customStyles[key]?.style,
              }
            }
          }
        }, {}),
      });
    }
  }, [selectedPresetCode, customStyles])

  useEffect(() => {
    const ls_custom_styles = localStorage.getItem(LS.CUSTOM_STYLES);
    if (!!ls_custom_styles) {
      setCustomStyles(JSON.parse(ls_custom_styles));
    }
    const doc = document.getElementById(NODE_ID.DOC);
    if (!doc) {
      return;
    }
    if (!!localStorage.getItem(LS.PROGRESS_HTML) && doc?.childElementCount <= 1) {
      toast({
        title: text.new_website,
        description: text.page_tools.common.progress_uploaded,
      });
    }
    if (!!currentPage) {
      if (currentPage?.page_path === "/" && !!localStorage.getItem(LS.PROGRESS_HTML)) {
        while (doc.firstChild) {
          doc.removeChild(doc.firstChild);
        }
        doc.innerHTML += localStorage.getItem(LS.PROGRESS_HTML);
      } else if (!!localStorage.getItem(LS.PROGRESS_HTML_PAGE + currentPage.page_path)) {
        while (doc.firstChild) {
          doc.removeChild(doc.firstChild);
        }
        doc.innerHTML += localStorage.getItem(LS.PROGRESS_HTML_PAGE + currentPage.page_path);
      } else if (currentPage.page_path !== "/") {
        const childNodes = Array.from(doc.childNodes);
        childNodes.forEach((node) => {
          if (!("id" in node && (node.id === EnumElement.HEADER || node.id === EnumElement.FOOTER))) {
            if ("id" in node && node.id === EnumElement.SECTION) {
              while (node.firstChild) {
                node.removeChild(node.firstChild);
              }
            } else {
              doc.removeChild(node);
            }
          }
        })
      }
    }
    if (!!localStorage.getItem(LS.CUSTOM_STYLES)) {
      toast({
        description: text.page_tools.common.custom_styles_uploaded,
      });
    }
    const listener = (e: MouseEvent) => {
      if (!!e.target && "id" in e.target) {
        setSelectedElementId(e.target.id as string);
      }
    };
    doc.addEventListener("mousedown", listener);
    return () => removeEventListener("mousedown", listener);
  }, [currentPage?.page_path]);

  useEffect(() => {
    if (!!searchParams.get(SP.PAGE) && !!pages) {
      const page = pages.find((el) => el.page_path?.replaceAll("/", "_") === searchParams.get(SP.PAGE));
      !!page && setCurrentPage(page);
    }
  }, [pages])

  return (
    <div>
      <div className="flex gap-2 p-4">
        <Button
          type="button"
          size="icon"
          className="min-w-10"
          onClick={() => highlight(!isHighlight)}>
          {isHighlight ? <Lightbulb /> : <LightbulbOff />}
        </Button>
        <Zoom zoom={zoom} setZoom={setZoom} />
        <PageConstructorTools currentTool={currentTool} setCurrentTool={setCurrentTool} />
        <SaveAsButton />
        <Popover>
          <PopoverTrigger asChild>
            <Button
              type="button"
              size="icon"
              variant="destructive"
              className={cn("min-w-10 p-2.5")}>
              <Trash2 />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            align="start"
            className="p-4 w-fit relative -left-1 top-1 cursor-pointer"
            onClick={() => {
              localStorage.removeItem(LS.PROGRESS_HTML);
              pages?.forEach((page) => {
                localStorage.removeItem(LS.PROGRESS_HTML_PAGE + page.page_path);
              })
              const doc = document.getElementById(NODE_ID.DOC);
              if (!!doc) {
                while (doc.firstChild) {
                  doc.removeChild(doc.firstChild);
                }
              }
            }}
          >
            {text.page_tools.common.erase_all_pages}
          </PopoverContent>
        </Popover>
      </div>
      <div className="h-full grow md:grid grid-cols-[210px_1fr_310px] sm:p-4 sm:pt-0">
        <LayoutsPresets />
        <Mockup
          breakpoints={breakpoints}
          zoom={zoom}
          bodyColor={selectedPreset.elements.body.style.backgroundColor || ""}>
          {new_document}
        </Mockup>
        <ElementPanel />
      </div>
    </div>
  );
};
