import { LangEnum } from "src/shared/store/common-store";
import { CSSProperties } from "react";
import { EnumElement, NODE_ID } from "src/shared/constants/elements";
import { SubmoduleId } from "src/shared/constants/submodules";
import { styleReplacer } from "src/shared/utils/constructor/style-utils";
import { _Localizations } from "src/shared/types/localizations";

export const initLocalizationSubmodule = (
  languages: LangEnum[],
  localizations: _Localizations,
  style: Partial<CSSProperties>,
) => {
  const doc = document.getElementById(NODE_ID.DOC);
  const html = document.getElementsByTagName("html");
  if (!doc || !html) {
    return;
  }
  const node = document.createElement(EnumElement.DIV);
  node.setAttribute("id", SubmoduleId.LOCALIZATION);
  node.setAttribute("style", styleReplacer(JSON.stringify(style)));
  const content = document.createElement(EnumElement.DIV);
  content.setAttribute("id", `${SubmoduleId.LOCALIZATION}_content`);
  content.setAttribute("style", styleReplacer(JSON.stringify({
    width: "1px",
    height: "1px",
    display: "none",
  })));
  content.textContent = JSON.stringify(localizations);
  const button_name = `${EnumElement.BUTTON}~${SubmoduleId.LOCALIZATION}`;
  languages?.forEach((lang) => {
    const child = document.createElement(EnumElement.BUTTON);
    child.setAttribute("id", `${button_name}_${lang}`);
    child.textContent = lang;
    child.name = button_name;
    child.value = lang.toLowerCase();
    node.append(child);
  })

  doc.append(node);
  const script = document.createElement("script");
  script.setAttribute("id", `${SubmoduleId.LOCALIZATION}_script`);
  script.type = "text/javascript";
  script.textContent = `(function(){
    const html = document.getElementsByTagName("html");
    const content = document.getElementById("submodule-localization_content");
    if (!html || !content) {
      return;
    }
    const localizations = JSON.parse(content.textContent);
    
    const lang_buttons = document.getElementsByName("button~submodule-localization");
    lang_buttons.forEach(function (lang_button) {
      lang_button.addEventListener('click', (event) => {
        const lang = event.target.value;
        html[0].lang = lang.toLowerCase();

        localizations[lang.toUpperCase()].forEach((el) => {
          const element = document.getElementById(el.id);
          if (!!element && element.textContent !== el.textContent) {
            element.textContent = el.textContent;
          }
        })

      });
    })
  })()`;
  doc.appendChild(content);
  doc.appendChild(script);
}