import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/app/routing/routes";
import { ChooseWebsite } from "src/entities/choose_website";
import { SP } from "src/shared/constants/constants";
import { new_website_param } from "src/shared/types/constructor";

export const Constructor = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex flex-col gap-8 pb-20 h-full w-full grow items-center justify-center">
      <ChooseWebsite onChoose={() => {
        searchParams.set(SP.WEBSITE, new_website_param)
        setSearchParams(searchParams);
        navigate(ROUTES.CONSTRUCTOR_NAVIGATUION + "?" + searchParams.toString())
      }} />
    </div>
  );
};
