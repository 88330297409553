import { useFormContext } from "react-hook-form";
import { Input, InputProps } from "./input";
import { Label } from "./label";

export function InputWithLabel({
  label,
  name,
  ...props
}: {
  label: string;
  name: string;
} & InputProps) {
  const form = useFormContext();
  const error = form.formState.errors[name];

  return (
    <div className="w-full space-y-1.5">
      <Label htmlFor={name} className={!!error ? "text-red-500" : ""}>
        {label}
      </Label>
      <Input
        id={name}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
        {...props}
        {...form.register(name)}
      />
      {!!error && (
        <p className="text-xs font-medium text-destructive">{error.message?.toString()}</p>
      )}
    </div>
  );
}
