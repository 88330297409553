export const Logo = ({ className = "" }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="5 5 90 90"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      fill="currentColor">
      <polygon points="73.973,18.825 35.117,41.409 35.069,58.26 26.102,53.091 26.151,36.233 65.014,13.649 49.934,4.944 11.078,27.529   10.947,72.471 26.027,81.175 26.047,72.774 26.047,72.754 35.014,77.929 34.986,86.351 50.065,95.056 88.922,72.471 89.053,27.529   " />
    </svg>
  );
};
