import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Download, Trash2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { DialogContent } from "@/components/ui/dialog";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import useText from "src/shared/hooks/use-text";
import { convertBase64ToBlob, getAssetFromFile } from "src/shared/utils/constructor/download";
import { AssetClient } from "src/shared/types/constructor";
import { LS } from "src/shared/constants/constants";

export const ImageModal = ({ insertImage }: {
  insertImage: (image: AssetClient) => void;
}) => {
  const text = useText().consturctor.element_tools;
  const fileRef = useRef(null);
  const [savedImages, setSavedImages] = useState<AssetClient[]>([]);
  const [images, setImages] = useState<AssetClient[]>([]);
  const [previewImage, setPreviewImage] = useState<AssetClient | null>(null);

  const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files) {
      return;
    }
    const assets = await Promise.all(Object.values(files).map(getAssetFromFile));

    if (!assets) {
      return;
    }
    const new_asset = assets[0];
    setImages((prev) => {
      return [
        ...prev.filter((item) => new_asset.name !== item.name),
        new_asset,
      ];
    });

    setPreviewImage(new_asset);
  };

  useEffect(() => {
    const ls_images = localStorage.getItem(LS.SAVED_ASSETS);
    if (!!ls_images) {
      (async () => {
        const assets: AssetClient[] = [];
        await JSON.parse(ls_images).map(async (el: AssetClient) => {
          const blob = convertBase64ToBlob(el.base64?.replace("data:application/octet-stream;base64,", ""), el.content_type);
          const new_item = {
            ...el,
            path: URL.createObjectURL(blob),
          };
          assets.push(new_item);
        });
        setSavedImages(assets);
        setImages(assets);
      })()
    }
  }, [])

  return (
    <DialogContent className={cn(
      "sm:max-w-[80vw] p-0 gap-0",
      !!previewImage ? "bg-secondary/50" : "bg-background",
    )}>
      <div className={cn(
        "relative min-h-[40vh] max-h-[60vh] w-full flex items-center justify-center duration-300",
        !previewImage && "hover:bg-green-500/30"
      )}>
        {!!previewImage
          ? (
            <>
              <div className="absolute bottom-2 right-2 flex gap-1 z-50">
                {!savedImages?.some((el) => el.path === previewImage.path) ? (
                  <>
                    <Button
                      type="button"
                      size="icon"
                      variant="outline"
                      className={cn("min-w-10 p-2.5")}
                      onClick={() => {
                        const updatedImages = savedImages.concat(previewImage);
                        setSavedImages(updatedImages);
                        localStorage.setItem(LS.SAVED_ASSETS, JSON.stringify(updatedImages));
                      }}
                    >
                      <Download />
                    </Button>
                    <Button
                      type="button"
                      size="icon"
                      variant="outline"
                      className={cn("min-w-10 p-2.5")}
                      onClick={() => {
                        setPreviewImage(null);
                        setImages(images.filter((el) => el.path !== previewImage.path));
                      }}
                    >
                      <Trash2 />
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => insertImage(previewImage)}
                  >
                    {text.add_image}
                  </Button>
                )}
              </div>
              <img src={previewImage.path} alt="new_image" className="object-scale-down h-full w-full" />
            </>
          ) : (
            <Download className="opacity-10 w-16 h-16" />
          )}
        <input
          ref={fileRef}
          type="file"
          accept="image/*"
          className="absolute left-0 top-0 opacity-0 w-full h-full"
          onChange={onUpload}
        />
      </div>
      <div className="min-h-[100px] bg-secondary text-muted-foreground rounded-b-lg p-4">
        {!!savedImages?.length
          ? (
            <div className="flex flex-col gap-3 font-medium">
              {text.choose_image}
              <ScrollArea>
                <div className="flex gap-2">
                  {savedImages.map((el, index) => (
                    <button
                      key={index}
                      type="button"
                      className="relative duration-300 hover:opacity-70 rounded-md w-20 h-20 bg-background overflow-hidden"
                      onClick={() => setPreviewImage(el)}
                    >
                      <img src={el.path} alt={el.name} className="object-cover h-full w-full" />
                    </button>
                  ))}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              {text.no_images_found}
            </div>
          )}
      </div>
    </DialogContent>
  )
}