import { Input } from "@/components/ui/input";
import { EnumCSSProp } from "src/shared/constants/css_properties";

type Props = {
  attr: EnumCSSProp;
  defaultValue: string;
  measure: string;
  updateStyleAttr: (attr: EnumCSSProp, value: string) => void;
};

export const SingleValueTool = ({ attr, defaultValue, measure, updateStyleAttr }: Props) => {
  return (
    <div className="flex gap-2 items-baseline">
      <Input
        className="h-7 w-20 text-center"
        defaultValue={defaultValue?.includes(measure) ? defaultValue?.replace(measure, "") : ""}
        onChange={(e) => {
          updateStyleAttr(attr, `${e.target.value}${measure}`);
        }}
      />
      <span className="text-neutral-500 text-xs">{measure}</span>
    </div>
  );
};
