import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Hint } from "@/components/ui/hint";
import { Textarea } from "@/components/ui/textarea";
import useText from "src/shared/hooks/use-text";
import { string_schema } from "src/shared/validation/field-schemas";
import { LS } from "src/shared/constants/constants";
import { ECeoLinkRel, _CEO } from "src/shared/types/ceo";

export const Ceo = () => {
  const text = useText();
  const [favIconError, setFavconError] = useState(false);

  const savedCeo = localStorage.getItem(LS.CEO);
  const parsedSavedCeo = (!!savedCeo ? JSON.parse(savedCeo) : {}) as _CEO;

  const FormSchema = z.object({
    favicon: string_schema(text.validation),
    title: string_schema(text.validation),
    description: string_schema(text.validation),
    links: z.array(z.object({
      name: string_schema(text.validation),
      rel: z.custom<ECeoLinkRel>(() => ECeoLinkRel),
      href: string_schema(text.validation),
    })),
    meta: z.array(z.object({
      name: string_schema(text.validation),
      content: string_schema(text.validation),
    })),
    scripts: z.array(z.object({
      name: string_schema(text.validation),
      script_tag: string_schema(text.validation),
    })),
    noscripts: z.array(z.object({
      name: string_schema(text.validation),
      src: string_schema(text.validation),
    })),
  });

  const defaultValues = {
    favicon: parsedSavedCeo?.head?.favicon || "",
    title: parsedSavedCeo?.head?.title || "",
    description: parsedSavedCeo?.head?.description || "",
    links: parsedSavedCeo?.head?.links || [],
    meta: parsedSavedCeo?.head?.meta || [],
    scripts: parsedSavedCeo?.body?.scripts || [],
    noscripts: parsedSavedCeo?.body?.noscripts || [],
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const disabled = !!Object.keys(form.formState.errors)?.length || favIconError;

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const payload: _CEO = {
      head: {},
      body: {},
    };
    if (!!e.favicon && !!payload.head) {
      payload.head.favicon = e.favicon;
    }
    if (!!e.title && !!payload.head) {
      payload.head.title = e.title;
    }
    if (!!e.description && !!payload.head) {
      payload.head.description = e.description;
    }
    if (!!e.links && !!payload.head) {
      payload.head.links = e.links;
    }
    if (!!e.meta && !!payload.head) {
      payload.head.meta = e.meta;
    }
    if (!!e.scripts && !!payload.body) {
      payload.body.scripts = e.scripts;
    }
    if (!!e.noscripts && !!payload.body) {
      payload.body.noscripts = e.noscripts;
    }
    localStorage.setItem(LS.CEO, JSON.stringify(payload));
  }

  return (
    <div className="flext flex-col gap-4 p-4">
      <Form {...form}>
        <form 
          onSubmit={form.handleSubmit(onSubmit)} 
          className="w-full grid grid-cols-[160px_1fr] text-sm items-baseline gap-x-4 gap-y-1 p-4 bg-secondary"
        >
          <div className="flex justify-between items-center">
            {text.ceo.favicon}
            <img 
              src={form.watch("favicon") || ""} 
              alt="favicon" 
              width={28} 
              height={28} 
              className="bg-background"
              onError={() => setFavconError(true)}
              onLoad={() => setFavconError(false)}
            />
          </div>
          <Input {...form.register("favicon")}/>
          <div>Title</div>
          <Input {...form.register("title")}/>
          <div>Description</div>
          <Textarea {...form.register("description")}/>
          {!!form.watch("meta")?.length && <b className="col-span-2 py-2">Meta</b>}
          {form.watch("meta")?.map((_, index) => {
            return (
              <Fragment key={`meta_${index}`}>
                 <div className="flex justify-between items-center">
                   Name
                   <Button 
                   type="button"
                   size="icon"
                   variant="outline"
                   className="w-7 h-7 min-7 p-2"
                   onClick={() => {
                    form.unregister(`meta.${index}.name`);
                    form.unregister(`meta.${index}.content`);
                    form.setValue(`meta`, form.watch("meta")?.filter((el) => {
                      return !!el;
                    }) || [])
                  }}
                   >
                    <Trash2/>
                   </Button>
                </div>
                <Input {...form.register(`meta.${index}.name`)} />
                <div>Content</div>
                <Textarea {...form.register(`meta.${index}.content`)} />
              </Fragment>
            )
          })}
          <button 
            type="button"
            className="col-span-2 flex items-center gap-2 text-xs p-1 pr-3 bg-background text-left w-fit rounded-md border"
            onClick={() => {
              form.setValue(`meta.${form.watch("meta")?.length || 0}`, {
                name: "",
                content: "",
              });
            }}
          >
            <Plus className="w-4 h-4" /> {text.ceo.add} meta
          </button>
          {!!form.watch("links")?.length && <b className="col-span-2 py-2">Link</b>}
          {form.watch("links")?.map((_, index) => {
            return (
              <Fragment key={`links_${index}`}>
                 <div className="flex justify-between items-center">
                   Name
                   <Button 
                   type="button"
                   size="icon"
                   variant="outline"
                   className="w-7 h-7 min-7 p-2"
                   onClick={() => {
                    form.unregister(`links.${index}.name`);
                    form.unregister(`links.${index}.rel`);
                    form.unregister(`links.${index}.href`);
                    form.setValue(`links`, form.watch("links")?.filter((el) => {
                      return !!el;
                    }) || [])
                  }}
                   >
                    <Trash2/>
                   </Button>
                </div>
                <Input {...form.register(`links.${index}.name`)} />
                <div>Href</div>
                <Textarea {...form.register(`links.${index}.href`)} />
              </Fragment>
            )
          })}
          <button 
            type="button"
            className="col-span-2 flex items-center gap-2 text-xs p-1 pr-3 bg-background text-left w-fit rounded-md border"
            onClick={() => {
              form.setValue(`links.${form.watch("links")?.length || 0}`, {
                name: "",
                rel: ECeoLinkRel.PRELOAD,
                href: "",
              });
            }}
          >
            <Plus className="w-4 h-4" /> {text.ceo.add} link
          </button>
          {!!form.watch("scripts")?.length && <b className="col-span-2 py-2">Script</b>}
          {form.watch("scripts")?.map((_, index) => {
            return (
              <Fragment key={`scripts_${index}`}>
                 <div className="flex justify-between items-center">
                   Name
                   <Button 
                   type="button"
                   size="icon"
                   variant="outline"
                   className="w-7 h-7 min-7 p-2"
                   onClick={() => {
                    form.unregister(`scripts.${index}.name`);
                    form.unregister(`scripts.${index}.script_tag`);
                    form.setValue(`scripts`, form.watch("scripts")?.filter((el) => {
                      return !!el;
                    }) || [])
                  }}
                   >
                    <Trash2/>
                   </Button>
                </div>
                <Input {...form.register(`scripts.${index}.name`)} />
                <div>{text.ceo.content}</div>
                <Textarea {...form.register(`scripts.${index}.script_tag`)} />
              </Fragment>
            )
          })}
          <button 
            type="button"
            className="col-span-2 flex items-center gap-2 text-xs p-1 pr-3 bg-background text-left w-fit rounded-md border"
            onClick={() => {
              form.setValue(`scripts.${form.watch("scripts")?.length || 0}`, {
                name: "",
                script_tag: "",
              });
            }}
          >
            <Plus className="w-4 h-4" /> {text.ceo.add} script
          </button>
          {!!form.watch("noscripts")?.length && <b className="col-span-2 py-2">Noscript</b>}
          {form.watch("noscripts")?.map((_, index) => {
            return (
              <Fragment key={`scripts_${index}`}>
                 <div className="flex justify-between items-center">
                   Name
                   <Button 
                   type="button"
                   size="icon"
                   variant="outline"
                   className="w-7 h-7 min-7 p-2"
                   onClick={() => {
                    form.unregister(`noscripts.${index}.name`);
                    form.unregister(`noscripts.${index}.src`);
                    form.setValue(`noscripts`, form.watch("noscripts")?.filter((el) => {
                      return !!el;
                    }) || [])
                  }}
                   >
                    <Trash2/>
                   </Button>
                </div>
                <Input {...form.register(`noscripts.${index}.name`)} />
                <div>SRC</div>
                <Textarea {...form.register(`noscripts.${index}.src`)} />
              </Fragment>
            )
          })}
          <button 
            type="button"
            className="col-span-2 flex items-center gap-2 text-xs p-1 pr-3 bg-background text-left w-fit rounded-md border"
            onClick={() => {
              form.setValue(`noscripts.${form.watch("noscripts")?.length || 0}`, {
                name: "",
                src: "",
              });
            }}
          >
            <Plus className="w-4 h-4" /> {text.ceo.add} noscript
          </button>
          <Button 
            type="submit"
            className="w-fit mt-3"
            disabled={disabled}
          >
          {text.ceo.save}
        </Button>
        </form>
      </Form>
      <Hint>
        🐝 добавить инструкцию и валидацию на уникальность имен и валидацию / экранирование
      </Hint>
    </div>
  )
}