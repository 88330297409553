import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { useBaseMutationWithoutPayload } from "../base-mutation-without-payload";
import authService from "../../services/auth-service";
import { _Login, _User } from "src/shared/types/auth";

export const useAuth = () => useBaseQuery<null, _User>(["me"], () => authService.me());

export const useLogin = () =>
  useBaseMutation<{ user_email: string; user_password: string }, _User | { is_2fa_enabled: true }>(
    ["login"],
    async (payload: { user_email: string; user_password: string }) => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprint = result.visitorId;

      return await authService.login({
        ...payload,
        fingerprint,
      });
    }
  );

export const useLoginWithOtp = () =>
  useBaseMutation<_Login, _User | { is_2fa_enabled: true }>(
    ["login"],
    async (payload: { user_email: string; user_password: string }) => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprint = result.visitorId;

      return await authService.login_with_otp({
        ...payload,
        fingerprint,
      });
    }
  );

export const useLogout = () =>
  useBaseMutationWithoutPayload<_User>(["logout"], () => authService.logout());
