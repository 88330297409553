export type Commit = {
  author_email: string;
  author_name: string;
  authored_date: string; // 2024-07-08T14:05:01.000+00:00
  committed_date: string; // 2024-07-08T14:05:01.000+00:00
  committer_email: string;
  committer_name: string;
  created_at: string; // 2024-07-08T14:05:01.000+00:00
  id: string;
  message: string;
  parent_ids: string[];
  short_id: string;
  title: string;
  web_url: string;
}

export type PushCommit = {
  branch: string;
  commit_message: string;
  actions: CommitAction[];
}

export type InitGitModule = {
  branch: string;
  commit_message: string;
  commit_sha: string;
}

export type CommitAction = {
  action: E_CommitActions;
  file_path: string;
  content?: string; // create
}

export enum E_CommitActions {
  CREATE = "create",
  DELETE = "delete",
}