import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import gitlabService from "../../services/gitlab-service";
import { Commit, InitGitModule, PushCommit } from "src/shared/types/gitlab";

export const useCommits = () => useBaseQuery<null, Commit[]>(["commits"], () => gitlabService.getCommitsGitlab(3356));

export const usePushCommit = () =>
  useBaseMutation<PushCommit, Commit>(
    ["push-commit"], (payload) => gitlabService.pushGitlab(3356, payload)
  );

export const useInitGitCalculatorSubmodule = () => 
  useBaseMutation<InitGitModule, Commit>(
    ["init-calc"], (payload) => gitlabService.initCalculatorSubmodule(3356, payload)
  );