import axios from "axios";
import { BASE_URL } from "../constants/constants";

const api = axios.create({
  baseURL: BASE_URL,
});

axios.defaults.withCredentials = true;

api.interceptors.request.use((config) => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }
  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    if (err.message === "Network Error") {
      throw new Error("500");
    }
    return err?.response;
  }
);

export default api;
