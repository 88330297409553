import { create } from "zustand";
import { persist } from "zustand/middleware";
import { LangEnum } from "./common-store";
import { _Localizations } from "../types/localizations";

interface LocalizationsStoreState {
  websiteLanguages: LangEnum[];
  setWebsiteLanguages: (websiteLanguages: LangEnum[]) => void;
  selectedLanguage: LangEnum | null;
  setSelectedLanguage: (selectedLanguage: LangEnum | null) => void;
  localizations: _Localizations | null;
  setLocalizations: (localizations: _Localizations | null) => void;
}

const initialState = {
  websiteLanguages: [],
  selectedLanguage: null,
  localizations: null,
};

export const useLocalizationsStore = create<LocalizationsStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setWebsiteLanguages: (websiteLanguages) => set({ websiteLanguages }),
      setSelectedLanguage: (selectedLanguage) => set({ selectedLanguage }),
      setLocalizations: (localizations) => set({ localizations })
    }),
    {
      name: "localizationsStore",
    }
  )
);
