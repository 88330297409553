import { CSSProperties, Dispatch, SetStateAction } from "react";
import { GridToolValues } from "./grid-tool-values";
import { GridStyle } from "./grid-ghost";
import { Display } from "src/shared/types/constructor";

export const default_grid_style: GridStyle = {
  display: Display.GRID,
  gridTemplateColumns: "1 1",
  gridTemplateRows: "1",
  minHeight: "20px",
  gap: "16",
}

export const GridTool = ({ elementStyle, displayStyle, setDisplayStyle }: {
  elementStyle: Partial<CSSProperties>;
  displayStyle: GridStyle;
  setDisplayStyle?: Dispatch<SetStateAction<GridStyle>>;
}) => {
  console.log(elementStyle)
  return (
    <div className="grid grid-cols-[60px_1fr] items-baseline gap-2">
      {!!setDisplayStyle && (
        <GridToolValues
          style={displayStyle}
          setStyle={setDisplayStyle}
        />
      )}
    </div>
  )
}