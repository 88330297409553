import { NODE_ID } from "src/shared/constants/elements";
import { _Localization } from "src/shared/types/localizations";

export const defindeTextContent = (): _Localization[] | null => {
  const doc = document.getElementById(NODE_ID.DOC);
  if (!doc) {
    return null;
  }
  const text_entities = Array.from(doc?.getElementsByTagName('*') || [])
    ?.filter((el) => !!el.textContent && !!el.textContent?.length && !el.children.length)
    ?.map((el) => ({
      id: el.id,
      textContent: el.textContent as string,
    }));
  return text_entities;
}