import { useState } from "react";
import { Button } from "@/components/ui/button";
import useText from "src/shared/hooks/use-text";
import { styleReplacer } from "src/shared/utils/constructor/style-utils";
import { EnumElement, NODE_ID } from "src/shared/constants/elements";
import { PageTool } from "src/shared/types/constructor";
import { BaseGhost } from "./base-ghost";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { Square } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Hint } from "@/components/ui/hint";

export const LayoutTool = ({
  resetTool,
}: {
  resetTool: () => void;
}) => {
  const text = useText().consturctor.page_tools[PageTool.LAYOUT];
  const { selectedPreset } = useConstructorStore();
  const header = document.getElementById(EnumElement.HEADER);
  const footer = document.getElementById(EnumElement.FOOTER);

  const [layoutState, setLayoutState] = useState({
    header: !!header,
    footer: !!footer,
  });

  const createLayout = () => {
    const doc = document.getElementById(NODE_ID.DOC);
    if (!doc) {
      return;
    }
    if (!!header && !layoutState.header) {
      header.remove();
    }
    if (!header && !!layoutState.header) {
      const header_node = document.createElement(EnumElement.HEADER);
      header_node.setAttribute("id", EnumElement.HEADER);
      header_node.setAttribute(
        "style",
        styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.HEADER]?.style))
      );
      doc?.prepend(header_node);
    }
    if (!!footer && !layoutState.footer) {
      footer.remove();
    }
    if (!footer && !!layoutState.footer) {
      const footer_node = document.createElement(EnumElement.FOOTER);
      footer_node.setAttribute("id", EnumElement.FOOTER);
      footer_node.setAttribute(
        "style",
        styleReplacer(JSON.stringify(selectedPreset.elements[EnumElement.FOOTER]?.style))
      );
      doc?.append(footer_node);
    }
    resetTool();
  };

  return (
    <BaseGhost
      triggerBlock={
        <div className="h-10" />
      }
      toolsBlock={
        <div className="flex flex-col gap-1">
          <div className="flex items-center space-x-2">
            <Switch
              id={`${PageTool.LAYOUT}_${EnumElement.HEADER}`}
              checked={!!layoutState.header}
              onCheckedChange={() => {
                setLayoutState({
                  ...layoutState,
                  header: !layoutState.header,
                })
              }}
            />
            <Label htmlFor={`${PageTool.LAYOUT}_${EnumElement.HEADER}`}>
              header
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <Switch
              id={`${PageTool.LAYOUT}_${EnumElement.FOOTER}`}
              checked={!!layoutState.footer}
              onCheckedChange={() => {
                setLayoutState({
                  ...layoutState,
                  footer: !layoutState.footer,
                })
              }}
            />
            <Label htmlFor={`${PageTool.LAYOUT}_${EnumElement.FOOTER}`}>
              footer
            </Label>
          </div>
        </div>
      }
      infoBlock={
        <>
          <div className="text-lg font-semibold">{text.info.h}</div>
          <p>{text.info.d}</p>
          <p>{text.info.base_layout}</p>
          <div style={{
            ...selectedPreset.elements[EnumElement.BODY].style,
            textAlign: "center",
            minHeight: "fit-content",
          }}>
            <div className="p-2 bg-black/10">header</div>
            <div className="mx-4 px-2 py-4 bg-black/10">
              section
            </div>
            <div className="p-2 bg-black/10">footer</div>
          </div>
          <p>
            <code className="text-foreground rounded-md px-2 bg-secondary -ml-2">header</code>
            {text.info.header}
          </p>
          <p>
            <code className="text-foreground rounded-md px-2 bg-secondary -ml-2">footer</code>
            {text.info.footer}
          </p>
          <p>
            <code className="text-foreground rounded-md px-2 bg-secondary -ml-2">section</code>
            {text.info.section}
          </p>
          <div style={{
            ...selectedPreset.elements[EnumElement.BODY].style,
            textAlign: "center",
            minHeight: "fit-content",
          }}>
            <div className="p-2 bg-black/10">header</div>
            <div className="mx-4 px-2 py-4 bg-black/10">
              section
            </div>
            <div className="px-2 py-4 bg-black/10">
              wide block
            </div>
            <div className="mx-4 px-2 py-4 bg-black/10">
              section
            </div>
            <div className="p-2 bg-black/10">footer</div>
          </div>
          <p>{text.info.section_create_1} <Square className="inline relative -top-1" /> {text.info.section_create_2}</p>
        </>
      }
      submitBlock={
        <div className="flex flex-col gap-4">
          <Hint className="max-w-[280px] flex flex-col gap-2 pt-2">
            <p>{text.warning_1}</p>
            <p>{text.warning_2}</p>
          </Hint>
          <Button
            type="button"
            variant="outline"
            className="text-xs h-7 px-3"
            disabled={layoutState.header === !!header && layoutState.footer === !!footer}
            onClick={createLayout}>
            {text.apply}
          </Button>
        </div>
      }
      resetTool={resetTool}
    />
  );
};
