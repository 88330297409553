import { create } from "zustand";
import { persist } from "zustand/middleware";
import { _User } from "../types/auth";

interface AuthStoreState {
  me: _User | null;
  setMe: (me: _User | null) => void;
}

const initialState = {
  me: null,
};

export const useAuthStore = create<AuthStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setMe: (me) => set({ me }),
    }),
    {
      name: "authStore",
    }
  )
);
