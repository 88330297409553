import { Card } from "@/components/ui/card";
import useText from "src/shared/hooks/use-text";
import { EnumElement, default_elements } from "src/shared/constants/elements";

export const ChooseWebsite = ({ onChoose }: {
  onChoose: () => void;
}) => {
  const text = useText().consturctor;

  return (
    <>
      <h2 style={default_elements[EnumElement.H2].style}>{text.choose_website}</h2>
      <Card
        className="px-6 py-4 font-semibold cursor-pointer hover:opacity-80"
        onClick={onChoose}>
        {text.new_website}
      </Card>
    </>
  )
}