import { useEffect, useState } from "react";
import { Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CopyButton } from "@/components/ui/copy-button";
import { Input } from "@/components/ui/input";
import { EnumCSSProp } from "src/shared/constants/css_properties";

type Props = {
  attr: EnumCSSProp;
  defaultValue: string;
  updateStyleAttr: (attr: EnumCSSProp, value: string) => void;
  trigger: string | null;
};

export const CopyTool = ({ attr, defaultValue, updateStyleAttr, trigger }: Props) => {
  const [value, setValue] = useState(defaultValue);
  const disabled = !value?.length || value === defaultValue;

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, trigger])

  return (
    <div className="flex gap-1.5">
      <CopyButton
        value={value}
        className="h-10 w-10 min-w-10 p-2.5 text-muted-foreground hover:bg-green-500 hover:text-white"
      />
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={async (e) => {
          if (e.keyCode === 13 && !!e.currentTarget?.value.trim().length) {
            e.preventDefault();
            !disabled && updateStyleAttr(attr, value);
          }
        }}
      />
      <Button
        type="button"
        size="icon"
        disabled={disabled}
        className="h-10 w-10 min-w-10 p-2.5 bg-green-500 text-white disabled:bg-secondary disabled:text-muted-foreground"
        onClick={() => updateStyleAttr(attr, value)}
      >
        <Check />
      </Button>
    </div>
  );
};