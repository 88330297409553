import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import useText from "src/shared/hooks/use-text";
import { ROUTES, navigation } from "src/app/routing/routes";
import { SP } from "src/shared/constants/constants";

export const Menu = () => {
  const navigate = useNavigate();
  const pathname = useLocation()?.pathname;
  const text = useText().navigation;
  const [searchParams] = useSearchParams();
  const website = searchParams.get(SP.WEBSITE);

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {navigation?.map((el) => {
          const isActive =
            (pathname !== ROUTES.DEFAULT && pathname.includes(el.path)) ||
            (pathname === ROUTES.DEFAULT && el.path.includes(ROUTES.CONSTRUCTOR));
          if (!!el.children && !!website) {
            return (
              <NavigationMenuItem key={el.path}>
                <NavigationMenuTrigger className={cn(isActive ? "bg-secondary" : "")}>
                  {text[el.title]}
                </NavigationMenuTrigger>
                <NavigationMenuContent className="p-4 grid md:grid-cols-2 min-w-[296px] md:min-w-[440px] gap-4 whitespace-pre">
                  {el.children?.map((child_el) => {
                    const isActiveChild = pathname === child_el.path;
                    return (
                      <NavigationMenuLink
                        key={child_el.path}
                        style={{
                          cursor: isActiveChild ? "default" : "pointer",
                          fontWeight: isActiveChild ? 500 : 300,
                        }}
                        className="min-w-[140px]"
                        onClick={() => {
                          if (pathname !== child_el.path) {
                            navigate(child_el.path + "?" + searchParams.toString());
                          }
                        }}>
                        {text[child_el.title]}
                      </NavigationMenuLink>
                    );
                  })}
                </NavigationMenuContent>
              </NavigationMenuItem>
            );
          } else {
            return (
              <NavigationMenuItem
                key={el.path}
                style={{ cursor: isActive ? "default" : "pointer" }}
                className={cn("px-4 py-2 rounded-md", isActive ? "bg-secondary" : "")}
                onClick={() => {
                  if (
                    !(
                      pathname === el.path ||
                      (pathname === ROUTES.DEFAULT && el.path === ROUTES.CONSTRUCTOR)
                    )
                  ) {
                    navigate(el.path + "?" + searchParams.toString());
                  }
                }}>
                <NavigationMenuLink>{text[el.title]}</NavigationMenuLink>
              </NavigationMenuItem>
            );
          }
        })}
      </NavigationMenuList>
    </NavigationMenu>
  );
};
