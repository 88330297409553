import { CSSProperties, Dispatch, SetStateAction } from "react";
import { Alignment, Display, E_FlexDirection } from "src/shared/types/constructor";
import { FlexStyle } from "./flex-ghost";
import { FlexToolValues } from "./flex-tool-values";
import { FlexToolAlignment } from "./flex-tool-alignment";

export const default_flex_style_col: FlexStyle = {
  display: Display.FLEX,
  flexDirection: E_FlexDirection.COL,
  justifyContent: Alignment.FLEX_START,
  justifyItems: Alignment.START,
  alignContent: Alignment.STRETCH,
  alignItems: Alignment.STRETCH,
  minHeight: "20px",
  gap: "16",
}

export const default_flex_style: FlexStyle = {
  display: Display.FLEX,
  minHeight: "20px",
  gap: "16",
}

export const FlexTool = ({ elementStyle, displayStyle, setDisplayStyle }: {
  elementStyle: Partial<CSSProperties>;
  displayStyle: FlexStyle;
  setDisplayStyle?: Dispatch<SetStateAction<FlexStyle>>;
}) => {
  console.log(elementStyle)
  return (
    <div>
      {!!setDisplayStyle && (
        <>
          <FlexToolValues style={displayStyle} setStyle={setDisplayStyle} />
          <FlexToolAlignment style={displayStyle} setStyle={setDisplayStyle} />
        </>
      )}
    </div>
  )
}