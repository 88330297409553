import { EnumCSSProp } from "../constants/css_properties";
import { CSSTools, Display, E_Preset, PageTool } from "../types/constructor";
import { EnumElement } from "../constants/elements";

const ru = {
  navigation: {
    constructor: "Конструктор",
    constructor_navigation: "Страницы",
    constructor_pages: "Редактировать страницу",
    constructor_basic_styles: "Общие стили",
    constructor_presets: "Пресеты",
    ceo: "Настройка CEO",
    localization: "Локализация",
    guide: "Гайд",
  },
  auth: {
    login_field: "Логин",
    email_field: "Email",
    password_field: "Пароль",
    sign_in: "Войти",
  },
  ceo: {
    favicon: "Ссылка на favicon",
    save: "Сохранить",
    add: "Добавить",
    content: "Содержимое",
  },
  consturctor: {
    choose_website: "Выберите сайт",
    new_website: "Новый сайт",
    elements: {
      [EnumElement.BODY]: "Фон",
      [EnumElement.SECTION]: "Раздел",
      [EnumElement.HEADER]: "Header",
      [EnumElement.FOOTER]: "Footer",
      [EnumElement.H1]: "Заголовок h1",
      [EnumElement.H2]: "Заголовок h2",
      [EnumElement.H3]: "Заголовок h3",
      [EnumElement.H4]: "Заголовок h4",
      [EnumElement.BUTTON]: "Кнопка",
      [EnumElement.LABEL]: "Label",
      [EnumElement.INPUT]: "Поле ввода",
      [EnumElement.SELECT]: "Селект",
      [EnumElement.IMAGE]: "Изображение",
      [EnumElement.DIV]: "Несмысловой блок",
      [EnumElement.GRID]: "Grid",
      [EnumElement.FLEX]: "Flex",
      [EnumElement.P]: "Текстовый блок",
      [EnumElement.CARD]: "Карточка",
    },
    css_properties: {
      [EnumCSSProp.BACKGROUND]: "Фон",
      [EnumCSSProp.COLOR]: "Цвет",
      [EnumCSSProp.FONT_SIZE]: "Размер шрифта",
      [EnumCSSProp.FONT_WEIGHT]: "Жирность шрифта",
      [EnumCSSProp.HEIGHT]: "Высота",
      [EnumCSSProp.PADDING]: "Внутренний отступ",
      [EnumCSSProp.POSITION]: "Позиционирование",
      [EnumCSSProp.WIDTH]: "Ширина",
    },
    css_tools: {
      [CSSTools.GRADIENT]: {
        random_gradient: "Рандомный градиент",
        generate: "Сгененрировать",
      },
    },
    page_tools: {
      common: {
        input_text: "Введите текст",
        save_locally: "Сохранить промежуточный прогресс",
        save_file: "Сохранить файл",
        open_blank: "Открыть в новой вкладке",
        init_project: "INIT PROJECT",
        push_commit: "PUSH COMMIT",
        progress_uploaded: "Прогресс импортирован",
        custom_styles_uploaded: "Сохраненные общие стили импортированы",
        close_info: "Понятно",
        default_settings: "Применены базовые настройки:",
        preset: "Пресет",
        custom_styles: "Кастомные общие стили",
        localization_submodule: "Модуль локализации текстов",
        erase_all_pages: "Стереть весь прогресс",
        erase_all_custom_styles: "Сбросить кастомные стили",
        document_navigation: "Навигация по макету",
      },
      [PageTool.LAYOUT]: {
        warning_1: "Убедитесь, что важный прогресс сохранен",
        warning_2: "Нажимая \"Применить\" при выключенном тумблере - элемент удалится. Восстановить его можно будет только откатившись до последних сохраненных изменений",
        apply: "Применить",
        info: {
          h: "Базовая разметка",
          d: "Ваш макет состоит из блоков, которые вы различным образом распределяете друг в друге.\nПримем за самый корневой блок - \"Фон\". Мы не рекомендуем устанавливать внешние или внутренние отступы для Фона, если вы планируете эелементы, выходящие за пределы контейнера, образованного корневым отступом.",
          base_layout: "Если Вы хотите создать классический макет, расположение вложенных в \"Фон\" элементов будет выглядеть как:",
          header: "Если активен - применится семантичный блок в самом верху макета. Чаще всего в нем располагают логотип, навигацию, смену темы и языка",
          footer: "Если активен - применится семантичный блок в самом низу макета. Чаще всего в нем располагают расширенную навигацию и контакты",
          section: "Появится по умолчанию. Если Вы хотите создать макет, внутри которого будут разполагаться разделы, выходящие за пределы контейнера (это бывает полезно для блоков с фоновыми изображениями), например:",
          section_create_1: "просто добавьте через инструмент ",
          section_create_2: "новый блок и уберите для него внутренний отступ. Инструмент доступен только на уровне \"Фон\" ",
        },
      },
      [Display.GRID]: {
        cols: "Колонки",
        rows: "Ряды",
        gap: "Зазор",
        fill: "Заполнить:",
        with_cards: "карточками",
        with_divs: "пустыми блоками",
        info: {
          d1: "-разметка создает сетку из колонок и столбцов и располагает элементы внутри нее. Элементы распределяются слева-направо сверху-вниз.",
          d2: "Каждая колока (столбец) могут занимать равные части контейнера или пропорциональные.",
          values: "Значения ",
          values_1: " - распределят элементы по 3-м равным колонкам (столбцам).",
          values_2:
            " - разделят пространство на 3 части и первая колока (столбец) займет 2 из них, вторая - одну.",
          gap: " - отступ между колоками (столбцами) в пикселях. Хорошей практикой является использовать значения отступов кратными шагу в 4 пикселя (8 / 12 / 16 / 20...). Вы можете задать разные вертикальный и горизонтиальный отступ указав 2 значения через пробел",
        },
      },
      [Display.FLEX]: {
        direction: "Направление",
        content: "Контент",
        items: "Элементы",
        justify: "Горизонтальное выравнивание",
        align: "Вертикальое выравнивание",
        gap: "Зазор",
        info: {
          d: "-разметка наделяет контейнер способностью растягиваться или сжиматься для заполнения собой доступного свободного пространства. Элементы могут располагаться вертикально или горизонтально.",
          gap: " - отступ между элементами в пикселях. Хорошей практикой является использовать значения отступов кратными шагу в 4 пикселя (8 / 12 / 16 / 20...). Вы можете задать разные вертикальный и горизонтиальный отступ указав 2 значения через пробел",
        },
      },
    },
    element_tools: {
      inline_elements: "Строчные элементы",
      block_elements: "Блочные элементы",
      no_images_found: "Не найдено сохраненных изображений",
      choose_image: "Выберите изображение из сохраненных",
      add_image: "Использовать изображение",
    },
    presets: {
      presets: "Пресеты",
      example_p:
        "Это стандартный текстовый блок. Добавьте в него описание преимуществ, побуждение к действию",
      example_button: "Подтвердить",
      preset_title: {
        [E_Preset.BASE]: "Базовый",
        [E_Preset.GLASS_MORPHISM]: "GlassMorphism",
        [E_Preset.FUTURISM]: "Futurism",
      },
    },
    pages: {
      confirm_delete: "Вы уверены, что хотите удалить страницу?",
      create_page: "Новая страница",
      delete: "Удалить",
      edit: "Редактировать",
      page_title: "Назовите страницу",
      page_path: "Путь к странице",
    },
    hints: {
      disabled_nesting_1: "Некоторые элементы недоступны для вставки",
      disabled_nesting_2: "из-за ограничений семантики вложенности",
      example: "Например",
      insert_style: "Вставтье css-код.\nРаспознанные значения применятся",
      line_break: "Перенос строки: CTRL + Enter",
      save: "Сохранить: Enter",
      save_section: "Добавить раздел: Enter",
      try_to_replace: "Доступно перетаскивание выбранного эелемента",
    },
  },
  localizations: {
    choose_languages: "Выберите языки, которые будут представлены на сайте",
    add_languages: "Добавить языки",
    website_languages: "Языки сайта",
    not_found_text_entities: "Текстовый контент не найден.\nДобавьте тексты на страницу в Конструкторе",
    defined_empty_localizations: "Обнаружены языки, для которых не добавлены тексты",
    choose_init_language: "Выберите язык, к которому будут привязаны найденные тексты",
    choose_copy_language: "Выберите язык для импорта шаблона",
    save_as: "Сохранить как",
    save: "Сохранить",
    disclaimer: "Как работает система локализации?\n\n🐝Написать про то, что берутся пацаны без child, как будет работать после инициализации",
  },
  ui: {
    copied: "Скопировано",
    optional: "Опционально",
  },
  validation: {
    required: "Это обязательное поле",
    login_regex: "Только латинские символы и цифры",
    path_regex: "Только латинские символы в нижнем регистре, _ и -",
    password_min: "Пароль от 12 символов",
    otp_length: "OTP - 6 символов",
    reserved: "Название зарезервировано, выберите другое",
    unique: "Элемент с таким названием уже существует, выберите другое",
  },
};

export default ru;
