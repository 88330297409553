import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cn } from "@/lib/utils";
import { Laptop, Smartphone } from "lucide-react";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { Breakpoints, breakpoints_options } from "src/shared/types/constructor";

export const BreakpointsToggle = () => {
  const {
    breakpoints,
    setBreakpoints,
  } = useConstructorStore();

  return (
    <ToggleGroup
      type="single"
      variant="outline"
      className="flex gap-1"
      value={breakpoints}
      onValueChange={(e) => {
        setBreakpoints(e as Breakpoints);
      }}>
      {Object.entries(breakpoints_options)?.map((el) => {
        const isSelected = breakpoints === el[0];
        return (
          <ToggleGroupItem
            key={el[0]}
            value={el[0]}
            className={cn(
              "data-[state=on]:bg-green-400/50 text-sm p-1 h-8 w-8 text-muted-foreground",
              isSelected ? "" : ""
            )}>
            {el[0] === Breakpoints.SM
              ? <Smartphone />
              : <Laptop />
            }
          </ToggleGroupItem>
        );
      })}
    </ToggleGroup>
  )
}