import { useEffect, useState } from "react";
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from "lucide-react";
import { EnumCSSProp } from "src/shared/constants/css_properties";
import { FourValueInput } from "./four-value-tool";

type Props = {
  defaultValues: {
    [EnumCSSProp.TOP]: string;
    [EnumCSSProp.RIGHT]: string;
    [EnumCSSProp.BOTTOM]: string;
    [EnumCSSProp.LEFT]: string;
  };
  measure: string;
  updateStyleAttr: (attr: EnumCSSProp, value: string) => void;
};

export const CurrentFourValueTool = ({ defaultValues, measure, updateStyleAttr }: Props) => {
  const four_values_icons = [
    ChevronUp,
    ChevronRight,
    ChevronDown,
    ChevronLeft,
  ];

  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    Object.entries(values)?.forEach((el) => {
      updateStyleAttr(el[0] as EnumCSSProp, el[1]);
    })
  }, [values])

  return (
    <div className="flex gap-2 items-start rounded-md py-1">
      <div className="flex items-baseline gap-2">
        <div className="flex flex-col gap-1">
          {Object.entries(values)?.map((el, el_index) => (
            <FourValueInput
              key={`${measure}_${el_index}`}
              icon={four_values_icons[el_index]}
              name={el[0]}
              defaultValue={el[1]}
              onChange={(e) => {
                setValues({
                  ...values,
                  [el[0]]: !!e.target.value?.length ? e.target.value + measure : "",
                });
              }}
            />
          ))}
        </div>
        <span className="text-neutral-500 text-xs relative bottom-1">{measure}</span>
      </div>
    </div>
  );
};