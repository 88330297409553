import { CSSProperties, Dispatch, SetStateAction } from "react";
import { LayoutDashboard, Plus, ReplaceAll, SlidersHorizontal, Sparkles, Square, Trash2, Type } from "lucide-react";
import { PopoverClose } from "@radix-ui/react-popover";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { Hint } from "@/components/ui/hint";
import { GridTool } from "src/pages/constructor/page_constructor/components/tools/grid/grid-tool";
import { FlexTool } from "src/pages/constructor/page_constructor/components/tools/flex/flex-tool";
import { NestedEntries } from "../types/common";
import { Display, E_ElementTool, _CSSProperty, _ElementTool } from "../types/constructor";
import { Text } from "../hooks/use-text";
import { EnumElement, EnumElementGroup, default_elements } from "./elements";
import { css_properties } from "./css_properties";
import { FlexStyle } from "src/pages/constructor/page_constructor/components/tools/flex/flex-ghost";
import { GridStyle } from "src/pages/constructor/page_constructor/components/tools/grid/grid-ghost";

export const element_tools: _ElementTool[] = [

  // add

  {
    code: E_ElementTool.ADD,
    icon: Plus,
    availableElements: Object.values(EnumElement),
    content: ({
      text,
      onSelectElement,
      disabled_nesting = [],
    }: {
      text: Text;
      onSelectElement?: (element: EnumElement) => void;
      disabled_nesting?: EnumElement[];
    }) => {
      const inline_elements = Object.values(default_elements)
        .filter((el) => el.group === EnumElementGroup.INLINE)
        .filter((el) => !disabled_nesting?.includes(el.code));

      const block_elements = Object.values(default_elements)
        .filter((el) => el.group === EnumElementGroup.BLOCK)
        .filter((el) => !disabled_nesting?.includes(el.code));

      return (
        <>
          <Tabs defaultValue="inline">
            <TabsList>
              <TabsTrigger value="inline" disabled={!inline_elements.length}>
                {text.consturctor.element_tools.inline_elements}
              </TabsTrigger>
              <TabsTrigger value="block" disabled={!block_elements.length}>
                {text.consturctor.element_tools.block_elements}
              </TabsTrigger>
            </TabsList>
            {(!!disabled_nesting && !!disabled_nesting.length) && (
              <Hint className="pl-2 pt-1">
                <p>{text.consturctor.hints.disabled_nesting_1}</p>
                <p>{text.consturctor.hints.disabled_nesting_2}</p>
              </Hint>
            )}
            <TabsContent value="inline" className="flex flex-col">
              {inline_elements
                ?.map((el) => {
                  return (
                    <PopoverClose key={el.code} asChild>
                      <Button
                        type="button"
                        variant="link"
                        className="justify-start"
                        onClick={() => !!onSelectElement && onSelectElement(el.code)}>
                        {text.consturctor.elements[el.code]}
                      </Button>
                    </PopoverClose>
                  );
                })}
            </TabsContent>
            <TabsContent value="block" className="flex flex-col">
              {block_elements
                ?.map((el) => {
                  return (
                    <PopoverClose key={el.code} asChild>
                      <Button
                        type="button"
                        variant="link"
                        className="justify-start"
                        onClick={() => !!onSelectElement && onSelectElement(el.code)}>
                        {text.consturctor.elements[el.code]}
                      </Button>
                    </PopoverClose>
                  );
                })}
            </TabsContent>
          </Tabs>
        </>
      );
    },
  },

  // section

  {
    code: E_ElementTool.SECTION,
    icon: Square,
    availableElements: [EnumElement.BODY],
    content: ({
      text,
    }: {
      text: Text;
    }) => {
      return (
        <Hint className="min-w-[200px]">{text.consturctor.hints.save_section}</Hint>
      );
    },
  },

  // text

  {
    code: E_ElementTool.TEXT,
    icon: Type,
    availableElements: Object.values(EnumElement).filter((el) => (
      el !== EnumElement.BODY &&
      el !== EnumElement.GRID &&
      el !== EnumElement.FLEX &&
      el !== EnumElement.SECTION
    )),
    content: ({
      text,
      value = "",
      setValue,
      onSubmitText,
    }: {
      text: Text;
      value?: string;
      setValue?: Dispatch<SetStateAction<string>>;
      onSubmitText?: (value: string) => void;
    }) => {
      return (
        <>
          <Textarea
            value={value}
            onChange={(e) => !!setValue && setValue(e.target.value)}
            onKeyDown={async (e) => {
              if (e.keyCode === 13 && !!e.currentTarget?.value.trim().length && !e.ctrlKey) {
                e.preventDefault();
                !!onSubmitText && onSubmitText(value);
              }
              if (e.keyCode === 13 && e.ctrlKey) {
                !!setValue && setValue(
                  value.substring(0, e.currentTarget.selectionStart)
                  + "\n"
                  + value.substring(e.currentTarget.selectionStart)
                );
              }
            }}
          />
          <Hint>
            <p>{text.consturctor.hints.save}</p>
            <p>{text.consturctor.hints.line_break}</p>
          </Hint>
        </>
      );
    },
  },

  // display

  {
    code: E_ElementTool.DISPLAY,
    icon: LayoutDashboard,
    availableElements: Object.values(default_elements)
      .filter((el) => el.group === EnumElementGroup.BLOCK || el.group === EnumElementGroup.ROOT)?.map((el) => el.code),
    content: ({
      elementStyle,
      displayStyle,
      setDisplayStyle,
    }: {
      elementStyle: Partial<CSSProperties>;
      displayStyle?: FlexStyle | GridStyle;
      setDisplayStyle?: Dispatch<SetStateAction<FlexStyle | GridStyle>>;
    }) => {

      return (
        <>
          {!!setDisplayStyle && (
            <Tabs defaultValue={displayStyle?.display || Display.FLEX}>
              <TabsList>
                <TabsTrigger value={Display.GRID}>
                  GRID
                </TabsTrigger>
                <TabsTrigger value={Display.FLEX}>
                  FLEX
                </TabsTrigger>
              </TabsList>
              <TabsContent value={Display.GRID} className="flex flex-col">
                <GridTool
                  elementStyle={elementStyle}
                  displayStyle={displayStyle as GridStyle}
                  setDisplayStyle={setDisplayStyle as Dispatch<SetStateAction<GridStyle>>}
                />
              </TabsContent>
              <TabsContent value={Display.FLEX} className="flex flex-col">
                <FlexTool
                  elementStyle={elementStyle}
                  displayStyle={displayStyle as FlexStyle}
                  setDisplayStyle={setDisplayStyle as Dispatch<SetStateAction<FlexStyle>>}
                />
              </TabsContent>
            </Tabs>
          )}
        </>
      );
    },
  },

  // style

  {
    code: E_ElementTool.STYLE,
    icon: SlidersHorizontal,
    availableElements: Object.values(EnumElement),
    content: ({
      text,
      prop = null,
      setProp,
    }: {
      text: Text;
      prop?: NestedEntries<_CSSProperty> | null;
      setProp?: Dispatch<SetStateAction<NestedEntries<_CSSProperty> | null>>;
    }) => {
      return (
        <>
          <div className="flex flex-col">
            {Object.entries(css_properties).map((property) => ({
              key: property[0],
              value: property[1],
            })).map((property) => (
              <Button
                key={property.key}
                className={cn(
                  "w-fit p-2 h-7 text-xs font-extralight",
                  property.key === prop?.key ? "bg-foreground text-background" : ""
                )}
                variant="link"
                onClick={() => !!setProp && setProp(property)}>
                {text.consturctor.css_properties[property.value.property]}
              </Button>
            ))}
          </div>
        </>
      );
    },
  },

  // insert-style

  {
    code: E_ElementTool.INSERT_STYLE,
    icon: Sparkles,
    availableElements: Object.values(EnumElement),
    content: ({
      text,
      onSubmitInsertStyle,
    }: {
      text: Text;
      onSubmitInsertStyle?: (value: string) => void;
    }) => {
      return (
        <>
          <Textarea
            placeholder={`${text.consturctor.hints.example}:
box-shadow: 0px 1px 2px 0px rgba(0,255,255,0.7),
  1px 2px 4px 0px rgba(0,255,255,0.7),
  2px 4px 8px 0px rgba(0,255,255,0.7),
  2px 4px 16px 0px rgba(0,255,255,0.7);
            `}
            onKeyDown={async (e) => {
              if (e.keyCode === 13 && !!e.currentTarget?.value.trim().length && !e.ctrlKey) {
                e.preventDefault();
                !!onSubmitInsertStyle && onSubmitInsertStyle(e.currentTarget.value);
              }
              if (e.keyCode === 13 && e.ctrlKey) {
                e.currentTarget.value =
                  e.currentTarget.value.substring(0, e.currentTarget.selectionStart)
                  + "\n"
                  + e.currentTarget.value.substring(e.currentTarget.selectionStart);
              }
            }}
          />
          <Hint>
            <p className="pb-4">{text.consturctor.hints.insert_style}</p>
            <p>{text.consturctor.hints.save}</p>
            <p>{text.consturctor.hints.line_break}</p>
          </Hint>
        </>
      );
    },
  },

  // replace

  {
    code: E_ElementTool.REPLACE,
    icon: ReplaceAll,
    availableElements: Object.values(EnumElement).filter((el) => el !== EnumElement.BODY),
    content: ({
      text,
    }: {
      text: Text;
    }) => {
      return (
        <Hint>
          <p>{text.consturctor.hints.try_to_replace}</p>
        </Hint>
      );
    },
  },

  // delete

  {
    code: E_ElementTool.DELETE,
    icon: Trash2,
    availableElements: Object.values(EnumElement).filter((el) => el !== EnumElement.BODY),
  },
];
