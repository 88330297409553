import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { InputWithLabel } from "@/components/ui/input-with-label";
import useText from "src/shared/hooks/use-text";
import { req_string_schema, path_schema } from "src/shared/validation/field-schemas";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { _Page } from "src/shared/types/constructor";
import { useEffect } from "react";

export const PageForm = () => {
  const text = useText();

  const { 
    pages,
    setPages,
  } = useConstructorStore();

  const FormSchema = z.object({
    page_title: req_string_schema(text.validation),
    page_path: path_schema(text.validation),
  });

  const isPending = false; // УБРАТЬ

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const errors = form.formState.errors;

  const reserved_titles = ["логин"]; // toLowerCase
  const reserved_paths = ["login"]; // replaceAll("/", "")

  const page_title = form.watch("page_title");
  const page_path = form.watch("page_path");

  const unique_title_error = !!page_title && !!pages?.find((el) => el.page_title?.toLowerCase() === page_title?.toLowerCase());
  const unique_path_error = !!page_path && !!pages?.find((el) => el.page_path?.replaceAll("/", "")?.toLowerCase() === page_path);
  const reserved_title_error = !!page_title && !!reserved_titles?.includes(page_title?.toLowerCase());
  const reserved_path_error = !!page_path && !!reserved_paths?.includes(page_path);

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    if (
      unique_title_error || 
      unique_path_error || 
      reserved_title_error || 
      reserved_path_error ||
      !pages
    ) {
      return;
    }
    const payload: _Page = {
      ...e, 
      page_enabled: true,
    }
    form.reset();
    setPages(pages?.concat(payload));
  };

  useEffect(() => {
    if (unique_title_error || reserved_title_error) {
      form.setError("page_title", {
        type: unique_title_error ? "unique" : "reserved", 
        message: unique_title_error ? text.validation.unique : text.validation.reserved,
      })
    } else if (errors["page_title"]?.type === "unique" || errors["page_title"]?.type === "reserved") {
      form.clearErrors("page_title");
    }

    if (unique_path_error || reserved_path_error) {
      form.setError("page_path", {
        type: unique_path_error ? "unique" : "reserved", 
        message: unique_path_error ? text.validation.unique : text.validation.reserved,
      })
    } else if (errors["page_path"]?.type === "unique" || errors["page_path"]?.type === "reserved") {
      form.clearErrors("page_path");
    }
  }, [
    unique_title_error, 
    unique_path_error,
    reserved_title_error,
    reserved_path_error,
  ])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[280px] space-y-6">
        <div className="w-full space-y-2">
          <InputWithLabel label={text.consturctor.pages.page_title} name="page_title" />
          <InputWithLabel label={text.consturctor.pages.page_path} name="page_path" />
        </div>
        <Button type="submit" disabled={isPending}>
          {text.consturctor.pages.create_page}
        </Button>
      </form>
    </Form>
  );
};
