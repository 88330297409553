import { Button } from "@/components/ui/button"
import { CloseButton } from "@/components/ui/close-button";
import { useState } from "react";
import { LS } from "src/shared/constants/constants";
import useText from "src/shared/hooks/use-text";
import { LangEnum, useCommonStore } from "src/shared/store/common-store";
import { useLocalizationsStore } from "src/shared/store/localizations-store";

export const LanguageAdder = () => {
  const text = useText().localizations;
  const { languages } = useCommonStore();
  const {
    websiteLanguages,
    setWebsiteLanguages,
  } = useLocalizationsStore();

  const [isAddLanguage, setIsAddLanguage] = useState(false);

  return (
    <div className="flex w-full justify-end">
      {isAddLanguage ? (
        <>
          <div className="flex flex-wrap w-full gap-1">
            {languages?.map((el) => {
              const isSelected = websiteLanguages.includes(el);

              return (
                <Button
                  key={el}
                  type="button"
                  variant={isSelected ? "default" : "secondary"}
                  className="px-3"
                  onClick={() => {
                    let updatedLanguages: LangEnum[] = [];
                    if (isSelected) {
                      updatedLanguages = websiteLanguages.filter((website_el) => website_el !== el);
                    } else {
                      updatedLanguages = websiteLanguages.concat(el);
                    }
                    setWebsiteLanguages(updatedLanguages);
                    localStorage.setItem(LS.WEBSITE_LANGUAGES, updatedLanguages.join(","));
                  }}
                >
                  {el}
                </Button>
              )
            })}
          </div>
          <CloseButton onClick={() => setIsAddLanguage(false)} />
        </>
      ) : (
        <Button
          type="button"
          onClick={() => setIsAddLanguage(true)}
        >
          {text.add_languages}
        </Button>
      )}
    </div>
  )
}