import { tg_script } from "../constants/tg-script";
import { _CEO } from "../types/ceo";
import { _Preset } from "../types/constructor";
import { styleReplacer } from "./constructor/style-utils";

export const generateCeoDocument = (
  ceo: _CEO, 
  selectedPreset: _Preset,
  innerHTML: string,
  websiteLanguages: string[],
): string => {
  return (
    `<!DOCTYPE html>
    <html lang="${websiteLanguages[0]?.toLowerCase() || "ru"}">
      <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        ${!!ceo?.head?.favicon?.length ? `<link rel="icon" href="${ceo.head.favicon}" />` : ""}
        ${!!ceo?.head?.title?.length ? `<title>${ceo.head.title}</title>` : ""}
        ${!!ceo?.head?.description?.length ? `<meta name="description" content="${ceo.head.description}" />` : ""}
        ${!!ceo?.head?.links?.length ? ceo.head.links.map((el) => (
          `<link rel="${el.rel}" href="${el.href}" ${
            (!!el.type && el.type === "font/ttf") ? `as="font" type="font/ttf"` : ""
          }/>`
        )) : ""}
        ${!!ceo?.head?.meta?.length ? ceo.head.meta.map((el) => (
          `<meta name="${el.name}" content="${el.content}" />`
        )) : ""}
      </head>
      <body style="${styleReplacer(
        JSON.stringify(selectedPreset.elements.body.style))};
        font-family:ui-sans-serif, system-ui, sans-serif"
      >
        ${!!ceo?.body?.noscripts?.length ? ceo.body.noscripts.map((el) => (
          `<noscript name="${el.name}">
            <iframe
            src="${el.src}"
            height="0"
            width="0"
            style={{
              display: "none",
              visibility: "hidden",
            }}></iframe>
          </noscript>`
        )) : ""}
        ${innerHTML}
        ${!!ceo?.body?.scripts?.length ? ceo.body.scripts.map((el) => (
          `${el.script_tag}`
        )) : ""}
        ${tg_script}
        <script type="module" src="/main.tsx"></script>
      </body>
    </html>`
  )
} 