import { useState } from "react";
import { v4 } from "uuid";
import { Button } from "@/components/ui/button";
import useText from "src/shared/hooks/use-text";
import { useConstructorStore } from "src/shared/store/constructor-store";
import { defineTag, styleReplacer } from "src/shared/utils/constructor/style-utils";
import { EnumElement } from "src/shared/constants/elements";
import { Display } from "src/shared/types/constructor";
import { BaseGhost } from "../base-ghost";
import { GridToolValues } from "./grid-tool-values";
import { default_grid_style } from "./grid-tool";

export type GridStyle = {
  display: Display;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  minHeight: string;
  gap: string;
}

export const GridGhost = ({
  resetTool,
}: {
  resetTool: () => void;
}) => {
  const text = useText().consturctor.page_tools[Display.GRID];
  const { selectedPreset, selectedElementId } = useConstructorStore();
  const [style, setStyle] = useState<GridStyle>(default_grid_style);

  const cellsArr = Array.from(
    {
      length:
        (style.gridTemplateColumns?.replace(/\s+/g, " ").trim().split(" ")?.length || 1) *
        (style.gridTemplateRows?.replace(/\s+/g, " ").trim().split(" ")?.length || 1),
    },
    (_, k) => k
  );

  const createGrid = (tag: EnumElement) => {
    const is_section = !!selectedElementId && defineTag(selectedElementId) === EnumElement.SECTION;
    const section = document.getElementById(is_section ? selectedElementId : EnumElement.SECTION);
    const node = document.createElement(EnumElement.DIV);
    node.setAttribute("id", `${EnumElement.GRID}~${v4()}`);
    node.setAttribute(
      "style",
      styleReplacer(
        JSON.stringify({
          display: Display.GRID,
          gridTemplateColumns:
            style.gridTemplateColumns?.replace(/\s+/g, " ").trim().replaceAll(" ", "fr ").trim() + "fr" || "1fr",
          gridTemplateRows:
            style.gridTemplateRows?.replace(/\s+/g, " ").trim().replaceAll(" ", "fr ").trim() + "fr" || "1fr",
          gap: style.gap.replace(/\s+/g, " ").trim().replaceAll(" ", "px ").trim() + "px" || "0",
        })
      )
    );
    cellsArr?.forEach(() => {
      const child = document.createElement(tag);
      child.setAttribute("id", `${tag}~${v4()}`);
      child.setAttribute(
        "style",
        styleReplacer(JSON.stringify(selectedPreset.elements[tag]?.style))
      );
      node.append(child);
    });
    section?.append(node);
    resetTool();
  };

  return (
    <BaseGhost
      triggerBlock={
        <div
          className="*:border *:border-green-400"
          style={{
            display: Display.GRID,
            gridTemplateColumns:
              style.gridTemplateColumns?.replace(/\s+/g, " ").trim().replaceAll(" ", "fr ").trim() + "fr" || "1fr",
            gridTemplateRows:
              style.gridTemplateRows?.replace(/\s+/g, " ").trim().replaceAll(" ", "fr ").trim() + "fr" || "1fr",
            gap: style.gap.replace(/\s+/g, " ").trim().replaceAll(" ", "px ").trim() + "px" || "0",
          }}>
          {cellsArr?.map((el) => (
            <div key={el} className="w-full h-full min-h-[100px]" />
          ))}
        </div>
      }
      toolsBlock={
        <div className="grid grid-cols-[50px_1fr_30px_1fr_40px_64px] items-baseline gap-3">
          <GridToolValues
            style={style}
            setStyle={setStyle}
          />
        </div>
      }
      infoBlock={
        <>
          <p>
            <code className="text-foreground rounded-md px-2 bg-secondary -ml-2">Grid</code>
            {text.info.d1}
          </p>
          <p>{text.info.d2}</p>
          <p>
            {text.info.values}
            <code className="text-foreground rounded-md px-2 bg-secondary">1 1 1</code>
            {text.info.values_1}
          </p>
          <p>
            {text.info.values}
            <code className="text-foreground rounded-md px-2 bg-secondary">2 1</code>
            {text.info.values_2}
          </p>
          <p>
            <code className="-ml-2 text-foreground rounded-md px-2 bg-secondary">{text.gap}</code>
            {text.info.gap}
          </p>
        </>
      }
      submitBlock={
        <>
          {text.fill}
          <Button
            type="button"
            variant="outline"
            className="text-xs h-7 px-3"
            onClick={() => createGrid(EnumElement.CARD)}>
            {text.with_cards}
          </Button>
          <Button
            type="button"
            variant="outline"
            className="text-xs h-7 px-3"
            onClick={() => createGrid(EnumElement.DIV)}>
            {text.with_divs}
          </Button>
        </>
      }
      resetTool={resetTool}
    />
  );
};
