import { CSSProperties } from "react";
import { EnumElement } from "src/shared/constants/elements";
import { SubmoduleId } from "src/shared/constants/submodules";

export const styleReplacer = (str: string, is_important = false) => {
  return str
    .replaceAll("{", "")
    .replaceAll("}", "")
    .replaceAll(",\"", is_important ? "!important;" : ";")
    .replaceAll('"', "")
    .replace(/[A-Z]/g, function (g) {
      return "-" + g?.toLowerCase();
    });
};

export const defineRootTag = (id: string | null): EnumElement => {
  if (Object.values(EnumElement).includes(id as unknown as EnumElement)) {
    return id as EnumElement;
  } else {
    return EnumElement.BODY;
  }
}

export const defineSubmoduleTag = (id: string | null, submoduleId: SubmoduleId): EnumElement => {
  if (!id || !id?.includes(submoduleId)) {
    return defineRootTag(id);
  }
  const tag = id.replace(submoduleId + "-", "")?.split("-")?.[0];

  if (Object.values(EnumElement).includes(tag as unknown as EnumElement)) {
    return tag as EnumElement;
  } else {
    return EnumElement.BODY;
  }
}

export const defineTag = (id: string | null): EnumElement => {
  const tag_entities = id?.split("~") || null;
  return !!tag_entities
    ? (tag_entities.length > 1
      ? tag_entities[0] as EnumElement
      : defineSubmoduleTag(id, SubmoduleId.CALCULATOR))
    : defineRootTag(EnumElement.BODY);
}

const measureReplacer = (str: string | number | undefined, measure: string, defaultValue: string): string => {
  if (!str) {
    return "";
  }
  return str
    .toString()
    .replace(/[a-zA-Z]/ig, "")
    .replace(/\s+/g, " ")
    .trim()
    .split(" ")
    ?.map((el) => el + measure)
    ?.join(" ") || defaultValue;
}

export const removeMeasures = (str: string | number | undefined): string => {
  return str?.toString()?.replace(/[a-zA-Z]/ig, "") || "";
}

export const removeStyleMeasures = (style: Partial<CSSProperties>): Partial<CSSProperties> => {
  const styleWithMeasures = structuredClone(style);
  const arr = Object.entries(styleWithMeasures)?.map((el) => [el[0], removeMeasures(el[1])]);
  return Object.fromEntries(arr);
}

export const addStyleMeasures = (style: Partial<CSSProperties>): Partial<CSSProperties> => {
  const styleWithMeasures = structuredClone(style);
  if ("gap" in style) {
    style.gap = measureReplacer(style.gap, "px", "0");
  }
  if ("gridTemplateColumns" in style) {
    style.gridTemplateColumns = measureReplacer(style.gridTemplateColumns, "fr", "1fr");
  }
  if ("gridTemplateRows" in style) {
    style.gridTemplateRows = measureReplacer(style.gridTemplateRows, "fr", "1fr");
  }
  return styleWithMeasures;
}