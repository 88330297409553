import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { LS } from "src/shared/constants/constants";
import useText from "src/shared/hooks/use-text"
import { useLocalizationsStore } from "src/shared/store/localizations-store";
import { _Localization } from "src/shared/types/localizations";
import { defindeTextContent } from "src/shared/utils/localizations/define-text-content";
import { LocalizationRow } from "./localization-row";
import { Button } from "@/components/ui/button";

export const InitLocalization = () => {
  const text = useText().localizations;
  const [textEntities, setTextEntities] = useState<_Localization[]>([]);

  const {
    selectedLanguage,
    setLocalizations,
  } = useLocalizationsStore();

  const submit = () => {
    if (!selectedLanguage || !textEntities) {
      return;
    }
    const initialLocalizations = {
      [selectedLanguage]: textEntities,
    };
    if (!!initialLocalizations) {
      setLocalizations(initialLocalizations);
      localStorage.setItem(LS.LOCALIZATIONS, JSON.stringify(initialLocalizations));
    }
  }

  useEffect(() => {
    const text_entities = defindeTextContent();
    !!text_entities && setTextEntities(text_entities);
  }, [])

  return (
    <Card className={cn(
      "flex flex-col gap-2 duration-300 p-4",
      (!textEntities?.length || !selectedLanguage) ? "bg-secondary" : "bg-background"
    )}>
      {!textEntities?.length ? (
        <div className="flex items-center justify-center text-muted-foreground w-full p-4 min-h-40 whitespace-pre-line text-center">
          {text.not_found_text_entities}
        </div>
      ) : (
        <>
          {!selectedLanguage && (
            <div className="flex items-center justify-center text-muted-foreground w-full p-4 min-h-20 whitespace-pre-line text-center">
              {text.choose_init_language}
            </div>
          )}
          {textEntities?.map((el) => {
            return (
              <LocalizationRow key={`text~${el.id}`} item={el} readOnly />
            )
          })}
          {!!selectedLanguage && (
            <Button
              type="button"
              className="w-fit"
              onClick={submit}
            >
              {text.save_as} {selectedLanguage}
            </Button>
          )}
        </>
      )}
    </Card>
  )
}