import { Input } from "@/components/ui/input";
import { Dispatch, SetStateAction } from "react";
import { flex_directions_options } from "src/shared/constants/page_constructor_tools";
import useText from "src/shared/hooks/use-text";
import { Display, E_FlexDirection } from "src/shared/types/constructor";
import { GhostToggleGroup } from "../ghost-toggle-group";
import { FlexStyle } from "./flex-ghost";

export const FlexToolValues = ({ style, setStyle, wide = false }: {
  style: FlexStyle,
  setStyle: Dispatch<SetStateAction<FlexStyle>>;
  wide?: boolean;
}) => {
  const text = useText().consturctor.page_tools[Display.FLEX];

  return (
    <div className={wide ? "grid sm:grid-cols-[1fr_1fr_110px] gap-2" : "flex flex-col gap-1"}>
      <div className="flex gap-3 items-center">
        {text.direction}
        <GhostToggleGroup
          value={style.flexDirection as E_FlexDirection}
          setValue={(val) => {
            setStyle({
              ...style,
              display: Display.FLEX,
              flexDirection: val as E_FlexDirection,
            })
          }}
          options={flex_directions_options}
        />
      </div>
      <div className="flex gap-3 items-baseline">
        {text.gap}
        <Input
          className="h-7 w-16"
          value={style.gap}
          onChange={(e) => setStyle({
            ...style,
            display: Display.FLEX,
            gap: e.target.value,
          })} />
      </div>
    </div>
  )
}