export const BASE_URL = import.meta.env.VITE_API_ENDPOINT;

export const loginRegex = /[^\s"]*$/;
// eslint-disable-next-line
export const mailRegex = /^[A-Za-z0-9\._+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}$/;
export const passwordRegex = /^\S*$/;
export const pathRegex = /^[a-z_-]+$/;

export enum LS {
  THEME = "theme",
  PROGRESS_HTML = "progress_html",
  PROGRESS_HTML_PAGE = "page",
  CUSTOM_STYLES = "custom_styles",
  SELECTED_PRESET = "selected_preset",
  CEO = "ceo",
  LOCALIZATIONS = "localizations",
  WEBSITE_LANGUAGES = "website_languages",
  SAVED_ASSETS = "saved_assets",
}

export enum SP {
  WEBSITE = "website",
  PAGE = "page",
  PRESET = "preset",
}
