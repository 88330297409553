import { useFormContext } from "react-hook-form";
import { CopyButton } from "@/components/ui/copy-button";
import { Textarea } from "@/components/ui/textarea"
import useText from "src/shared/hooks/use-text";
import { _Localization } from "src/shared/types/localizations"
import { useEffect } from "react";
import { LangEnum } from "src/shared/store/common-store";
import { defineTag } from "src/shared/utils/constructor/style-utils";

export const LocalizationRow = ({ item, readOnly = false, language = null }: {
  item: _Localization;
  readOnly?: boolean;
  language?: LangEnum | null; 
}) => {
  const form = useFormContext();
  const text = useText();
  const tag_entities = item.id?.split("~") || null;
  const element_code = defineTag(item.id);

  useEffect(() => {
    form?.setValue(item.id, item.textContent);
  }, [language])

  return (
    <div className="sm:grid grid-cols-[160px_1fr] py-2 gap-4">
      <div>
        <div className="font-semibold">
          {text.consturctor.elements[element_code]}
        </div>
        {!!tag_entities && tag_entities?.length > 1 && (
          <div className="flex w-full gap-2 text-xs justify-between items-center">
            <div className="whitespace-pre text-ellipsis overflow-hidden">
              {tag_entities[1]}
            </div>
            <CopyButton value={item.id} className="w-7 h-7 min-w-7 p-1.5" />
          </div>
        )}
      </div>
      {readOnly ? (
        <div className="text-sm">{item.textContent}</div>
      ) : (
        <Textarea defaultValue={item.textContent} {...form.register(item.id)} />
      )}
    </div>
  )
}