import { X } from "lucide-react";
import { Button } from "./button";

export const CloseButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Button
      type="button"
      variant="outline"
      size="icon"
      className="p-2.5 min-w-10"
      onClick={onClick}>
      <X />
    </Button>
  );
};
