import { ReactElement } from "react";
import { ROUTES } from "src/app/routing/routes";

export enum UserRole {
  ADMIN = "admin",
}

export type _NavItem = {
  path: ROUTES;
  title: string;
  element: ReactElement;
  permissions?: UserRole[];
  children?: _NavItem[];
};

export type _Select = {
  label: string;
  value: string;
};

export type NestedObjects<T> = {
  [key: string]: T;
};

export type NestedEntries<T> = {
  key: string;
  value: T;
};
