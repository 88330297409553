import { useEffect, useState } from "react";
import { Copy } from "lucide-react";
import useText from "src/shared/hooks/use-text";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";

export const CopyButton = ({ value, className = "", disabled }: { value: string; className?: string; disabled?: boolean }) => {
  const text = useText().ui;
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [isCopied]);

  return (
    <Popover open={isCopied}>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          size="icon"
          className={className}
          disabled={disabled}
          onClick={async () => {
            setCopied(true);
            await navigator.clipboard.writeText(value);
          }}>
          <Copy />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="text-xs p-2 w-fit">
        <p>{text.copied}</p>
      </PopoverContent>
    </Popover>
  );
};
