export const strToHash = (str: string) => {
  return str.split("").reduce((hash, char) => (hash ^ char.charCodeAt(0)) * -5, 5) >>> 2;
};

export const getRandomColor = (str: string): number => {
  const hash = strToHash(str);
  const COLORS_NB = 13;
  return (hash % COLORS_NB) * (360 / COLORS_NB);
};

export const checkStatusCode = (errors: (Error | null)[], statusCode: number): boolean => {
  return errors?.some((el: Error | null) => el?.message?.includes(statusCode?.toString()));
};
