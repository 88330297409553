import { cn } from "@/lib/utils";
import { ReactNode } from "react";

export const Hint = ({ className = "", children }: {
  className?: string;
  children: ReactNode;
}) => {
  return <div className={cn(
    className,
    "text-muted-foreground text-xs flex flex-col opacity-60 whitespace-pre-line",
    )}>
    {children}
  </div>;
};
